import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';
import  enStrings  from "./en-us" ;
import  deStrings  from "./de-de" ;

export interface IStrings extends LocalizedStringsMethods{
    Label_Send_Invitation:string;
}

 const strings: IStrings = new LocalizedStrings({
    en: enStrings,
    de: deStrings,
});

export default strings;


