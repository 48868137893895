import * as React from 'react';
import { useState, useEffect } from 'react';
import Login from './components/Login/Login';
import { ErrorBoundary } from './ErrorBoundary';
import { User } from './interfaces/User.interface';
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { GoogleOAuthProvider } from '@react-oauth/google';
import GlobalContext from './GlobalContext';
import { AppContext } from '../client/components/context';
import SpeakerInfoForm from '../client/components/SpeakerInfoForm/SpeakerInfoForm';

import { Layout } from './components/Layout/Layout';
import { ThemeProvider, useWindow } from "@fluentui/react";
import { getDefaultTheme } from "./theme";
import strings from '../client/loc/strings';
import './App.css';
import { Size, useWindowResize } from './hooks/useWindowResize';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import appStyles from "./scss/app.scss";

// Register icons and pull the fonts from the default SharePoint cdn.
initializeIcons();

const CLIENT_ID: string = process.env.MICROSOFT_APPREG_ClientID!;

// MSAL configuration
const configuration: Configuration = {
	auth: {
		clientId: CLIENT_ID,
		//authority: "https://login.microsoftonline.com/consumers/"
		authority: `https://login.microsoftonline.com/${process.env.TENANT_ID!}/`
	}
};

const pca = new PublicClientApplication(configuration);

interface IUSEventSpeakerWebAppState {
	applicationLables: any;
	user: User | null;
	token: string | null;
}

const App = (props: AppProps) => {
	const initialState: IUSEventSpeakerWebAppState = {
		applicationLables: strings,
		user: null,
		token: null
	}
	//const [user, setUser] = useState<User | null>(null);
	//const [token, setToken] = useState<string | null>(null);
	const [state, setState] = useState(initialState);
	const windowSize: Size = useWindowResize();
	const [customTheme, setCustomTheme] = useState(() => getDefaultTheme(windowSize));

	useEffect(() => {
		setState((prevState: IUSEventSpeakerWebAppState) => ({
			...prevState,
			applicationLables: strings
		}));
	}, []);

	useEffect(() => {
		setCustomTheme(getDefaultTheme(windowSize));
	}, [windowSize.width, windowSize.height])

	const onSignIn = (user: User, jwt: string): void => {
		setState((prevState: IUSEventSpeakerWebAppState) => ({
			...prevState,
			user: user,
			token: jwt
		}));
		//setUser(user);
		//setToken(jwt);
	};

	const onSignOut = (): void => {
		setState((prevState: IUSEventSpeakerWebAppState) => ({
			...prevState,
			user: null,
			token: null
		}));
		//setUser(null);
		//setToken(null);
	};

	return (
		<AppContext.Provider value={{ state, setState, setCustomTheme }}>
			<ThemeProvider applyTo="body" theme={customTheme} className="themeContainer">
				<Layout>
					<ErrorBoundary>
						<MsalProvider instance={pca}>
							<GoogleOAuthProvider clientId={process.env.GOOGLE_API_CLIENTID!} >
								<div className={appStyles.form} >
									<GlobalContext.Provider value={state.user}>
										{/*
											state.user && state.token && <div className="signedUserDetail">
												Signed-in User: {state.user.email}<br />
											</div>
										*/}
										{
											state.user ? <SpeakerInfoForm /> : <Login user={state.user} onSignIn={onSignIn} onSignOut={onSignOut} />
										}
									</GlobalContext.Provider>
								</div>
							</GoogleOAuthProvider>
						</MsalProvider>
					</ErrorBoundary>
				</Layout>
			</ThemeProvider>
		</AppContext.Provider>
	);
};

interface AppProps { }

export default App;
