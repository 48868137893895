import * as React from 'react';
import { IContactInformationState } from './IContactInformationState';
import { useState, useContext, useEffect } from 'react';
import { SpeakerInfoFormContext } from '../../../components/context';
import { TextField, Stack, Dropdown, ChoiceGroup } from '@fluentui/react';
import styles from '../../../scss/EventSpeakerForm.module.scss';
import { ContactInformationService } from './ContactInformationService';
import Instructions from '../FormText/Instructions';
import { CommonService } from '../../../services';
import { forwardRef, useImperativeHandle } from 'react';
import { IDictionary } from '../../../interfaces';

export interface IContactInformationProps {
}

export type ContactInformationRef = {
	getFormData: () => IContactInformationState;
    updateValidationErrors: (errorMessagesForFormFields: IDictionary<string>) => void;
};

const ContactInformation = forwardRef<ContactInformationRef, IContactInformationProps>((props, ref) => {
    const commonService = new CommonService();
    const speakerInfoFormContext: any = useContext(SpeakerInfoFormContext);
    const [contactInformationState, setcontactInformationState] = useState<IContactInformationState>({
        licensedMAOrVT: speakerInfoFormContext.speakerInfoFormState.contactInformationState?.licensedMAOrVT ?? speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.LicensedMAOrVT,
    });
    const contactInformationService = new ContactInformationService(setcontactInformationState);

    useEffect(() => {
        contactInformationService.InitContactInformation();
    }, []);

    const inputChangeHandler = (event: any): void => {
        setcontactInformationState((prevState: IContactInformationState) => ({
            ...prevState,
            [event.target.id]: event.target.value
        }));
    }

    const choiceChangeHandler = (ev: any, option: any): void => {
        setcontactInformationState((prevState: IContactInformationState) => ({
            ...prevState,
            [ev.target.name]: option.key
        }));
    }

    const dropDownChoiceChangeHandler = (ev: any, option: any): void => {
        setcontactInformationState((prevState: IContactInformationState) => ({
            ...prevState,
            [ev.target.id]: option.key
        }));
    }

    // Pass the ref to the useImperativeHandle hook
	useImperativeHandle(ref, () => ({
		getFormData: () => {
			return contactInformationState;
		},
        updateValidationErrors:(errorMessagesForFormFields:IDictionary<string>) =>{
            setcontactInformationState((prevState: IContactInformationState) => ({
                ...prevState,
                errorMessagesForFormFields:errorMessagesForFormFields
            }));  
        }
	}));

    return (<>
        <div className={speakerInfoFormContext.speakerInfoFormState.disableForm ? styles.disableForm : ``}>
            <div className={styles.topInstrunction}>
                <Instructions instructions={speakerInfoFormContext.speakerInfoFormState.applicationText.ContactInformationInstrunctions} />
            </div>
            <div className={styles.flexContainer}>
            <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <TextField
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_AddressLine1}
                                required
                                multiline
                                className={styles.speakerFormfield}
                                value={contactInformationState.addressLine1}
                                id={"addressLine1"}
                                onChange={inputChangeHandler}
                                errorMessage={contactInformationState.errorMessagesForFormFields?.addressLine1}
                            />
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <TextField
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_AddressLine2}
                                multiline
                                className={styles.speakerFormfield}
                                value={contactInformationState.addressLine2}
                                id={"addressLine2"}
                                onChange={inputChangeHandler}
                                errorMessage={contactInformationState.errorMessagesForFormFields?.addressLine2}
                            />
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <TextField
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_City}
                                required
                                className={styles.speakerFormfield}
                                value={contactInformationState.city}
                                id={"city"}
                                onChange={inputChangeHandler}
                                errorMessage={contactInformationState.errorMessagesForFormFields?.city}
                            />
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <TextField
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_USState}
                                required
                                className={styles.speakerFormfield}
                                value={contactInformationState.usState}
                                id={"usState"}
                                onChange={inputChangeHandler}
                                errorMessage={contactInformationState.errorMessagesForFormFields?.usState}
                            />
                        </Stack>
                    </div> 
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <TextField
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_PostalCode}
                                required
                                className={styles.speakerFormfield}
                                value={contactInformationState.postalCode}
                                id={"postalCode"}
                                onChange={inputChangeHandler}
                                errorMessage={contactInformationState.errorMessagesForFormFields?.postalCode}
                            />
                        </Stack>
                    </div>                                                           
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <Dropdown
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_Country}
                                required
                                options={contactInformationState.countryOptions ?? []}
                                className={styles.speakerFormfield}
                                selectedKey={contactInformationState.country}
                                id={"country"}
                                onChange={dropDownChoiceChangeHandler}
                                errorMessage={contactInformationState.errorMessagesForFormFields?.country}
                            />
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <TextField
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_WorkPhone}
                                required
                                className={styles.speakerFormfield}
                                value={contactInformationState.workPhone}
                                id={"workPhone"}
                                onChange={inputChangeHandler}
                                errorMessage={contactInformationState.errorMessagesForFormFields?.workPhone}
                            />
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <TextField
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_CellPhone}
                                required
                                className={styles.speakerFormfield}
                                value={contactInformationState.cellPhone}
                                id={"cellPhone"}
                                onChange={inputChangeHandler}
                                errorMessage={contactInformationState.errorMessagesForFormFields?.cellPhone}
                            />
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <TextField
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_PrimaryStateLicense}
                                required
                                className={styles.speakerFormfield}
                                value={contactInformationState.PrimaryStateLicensure}
                                id={"PrimaryStateLicensure"}
                                onChange={inputChangeHandler}
                                errorMessage={contactInformationState.errorMessagesForFormFields?.PrimaryStateLicensure}
                                description={commonService.getPlainTextFromHTML([speakerInfoFormContext.speakerInfoFormState.applicationText?.PrimaryStateLicensure] ?? [``])}
                            />                        
                        </Stack>                    
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <TextField
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_StateLicense}
                                required
                                className={styles.speakerFormfield}
                                value={contactInformationState.stateLicense}
                                id={"stateLicense"}
                                onChange={inputChangeHandler}
                                errorMessage={contactInformationState.errorMessagesForFormFields?.stateLicense}
                                description={commonService.getPlainTextFromHTML([speakerInfoFormContext.speakerInfoFormState.applicationText?.StateLicense] ?? [``])}
                            />
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <ChoiceGroup
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_LicensedInMAOrVT}
                                required
                                options={contactInformationState.licensedInMAOrVTOptions}
                                className={styles.speakerFormfield}
                                selectedKey={contactInformationState.licensedMAOrVT}
                                name={"licensedMAOrVT"}
                                onChange={choiceChangeHandler}

                            />
                            <span className={styles.errorMessage}>{contactInformationState.errorMessagesForFormFields?.licensedMAOrVT}</span>
                        </Stack>
                    </div>
                    { contactInformationState.licensedMAOrVT && contactInformationState.licensedMAOrVT !== "No" &&
                        <div className={styles.column}>
                            <Stack className={styles.stack}>
                                <TextField
                                    label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_MAorVTLicenseNumber}
                                    required
                                    className={styles.speakerFormfield}
                                    value={contactInformationState.maorVTLicenseNumber}
                                    id={"maorVTLicenseNumber"}
                                    onChange={inputChangeHandler}
                                    errorMessage={contactInformationState.errorMessagesForFormFields?.maorVTLicenseNumber}                            
                                />
                            </Stack>
                        </div>                  
                    }   
                <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <TextField
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_NationalProviderId}
                                required
                                className={styles.speakerFormfield}
                                value={contactInformationState.nationalProviderId}
                                id={"nationalProviderId"}
                                onChange={inputChangeHandler}
                                errorMessage={contactInformationState.errorMessagesForFormFields?.nationalProviderId}
                                description={commonService.getPlainTextFromHTML([speakerInfoFormContext.speakerInfoFormState.applicationText?.NationalProviderID] ?? [``])}
                            />
                        </Stack>
                    </div>
                
            </div>
        </div>
    </>);
});

export default ContactInformation;