import { SpeakerInfoFormContext } from '../components/context';
import { useContext } from 'react';
import { IChoiceEntries, ICheckBoxChoices } from '../interfaces';
import { IChoiceGroupOption } from "@fluentui/react";
const { compile } = require('html-to-text');
export class CommonService {
    private speakerInfoFormContext: any ;

    constructor() {
        this.speakerInfoFormContext = useContext(SpeakerInfoFormContext);        
    }

    public GetSettingsDataByKey(key:string): string {
        return this.speakerInfoFormContext.speakerInfoFormState.appSettings[key];        
    }

    public getChoiceFieldChoicesForAFormAndField (form:string, field:string): any {
         let formChoices = this.speakerInfoFormContext.speakerInfoFormState.allChoices?.filter((item:IChoiceEntries) => {
              return item.fields.Title === form && item.fields.FieldName === field
         });
        let result:any = {};
        formChoices?.forEach((choiceItem:IChoiceEntries) => {
            result[choiceItem.fields.FieldName] = choiceItem.fields.ChoiceValues ? choiceItem.fields.ChoiceValues.split("\n").filter(function (x) { return x.trim() !== ''; }) : [];
        });
        return this.createDropDownOptions(result[field]);       
    }

    public createDropDownOptions(choiceValues:string[]):any[]{
        let options:IChoiceGroupOption[]=[];
        if (choiceValues?.length) {
            options = choiceValues.map(x => ({
                key: x,
                text: x
            }));            
        }
        return options;
    }

    public getCheckBoxFieldChoicesForAFormAndField (form:string, field:string): any {
        let formChoices = this.speakerInfoFormContext.speakerInfoFormState.allChoices?.filter((item:any) => {
            return item.fields.Title === form && item.fields.FieldName === field;
        });
        let result:any = {};
        formChoices.forEach((choiceItem:IChoiceEntries) => {
            result[choiceItem.fields.FieldName] = choiceItem.fields.ChoiceValues ? choiceItem.fields.ChoiceValues.split("\n").filter(function (x) { return x.trim() !== ''; }) : [];
        });
        let options:ICheckBoxChoices[]=[];
        if (result[field]?.length) {
            options = result[field].map((x:ICheckBoxChoices) => ({
                key: x,
                text: x,
                checked:false
            }));            
        }
        return options;      
    }

    public validateEmail = (email: string) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    public validateNumberOnlyField = (val:string) =>{
        return !/^[0-9]+$/.test(val);
    }  

    public getPlainTextFromHTML = (val:any) =>{
        const options = {
            wordwrap: 130,
          };
        const compiledConvert = compile(options);
        const text = val.map(compiledConvert);
        return text;
    }
}