import { IStyleFunctionOrObject, ITextFieldStyleProps, ITextFieldStyles, IDropdownStyleProps, IDropdownStyles, IBasePickerStyleProps, IBasePickerStyles, ISearchBoxStyleProps, ISearchBoxStyles, PartialTheme, IIconStyleProps, IIconStyles, IButtonProps, IButtonStyles, IPivotStyleProps, IPivotStyles, IChoiceGroupOptionStyleProps, IChoiceGroupOptionStyles, ICheckboxStyles, ICheckboxStyleProps, ISpinnerStyleProps, ISpinnerStyles, ILabelStyles, ILabelStyleProps } from "@fluentui/react";
import { DeepPartial } from '@fluentui/merge-styles';
import stylingVariables from "../client/scss/variables.module.scss";
import { Size } from "./hooks/useWindowResize";

export const getDefaultTheme = (windowSize:Size): PartialTheme => {
    const defaultTextFontSize = windowSize.width && windowSize.width <= 768 ? "14px" : "14px";
    const defaultLabelFontSize = windowSize.width && windowSize.width <= 768 ? "16px" :"14px";

    const LabelStyles: IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles> = {
        root: {
            fontSize: defaultLabelFontSize
        }
    }

    const TextFieldStyles: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles> = {
        fieldGroup: {
            borderWidth: '2px',
            borderRadius: 0,
            '&::after': {
                borderWidth: '2px',
                borderRadius: 0,
                inset: '-2px'
            }
        },
        root: {
            fontSize: defaultTextFontSize
        }
    };

    const DropdownStyles: IStyleFunctionOrObject<IDropdownStyleProps, IDropdownStyles> = {
        title: {
            borderWidth: '2px',
            borderRadius: 0
        },
        dropdown: {
            '&:hover': {
                borderColor: stylingVariables.straumannBlueHoverColor
            }
        }
    };

    const NormalPeoplePickerStyles: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles> = {
        text: {
            borderWidth: '2px',
            borderRadius: 0,
        }
    }

    const IconStyles: IStyleFunctionOrObject<IIconStyleProps, IIconStyles> = {
        root: {
            color: stylingVariables.straumannBlueColor
        }
    }

    const CommandBarStyles: IStyleFunctionOrObject<IButtonProps, IButtonStyles> = {
        icon: {
            color: stylingVariables.straumannBlueColor,
        },
        iconHovered: {
            color: stylingVariables.straumannBlueHoverColor
        },
        labelHovered: {
            color: stylingVariables.straumannBlueHoverColor
        }
    }

    const SpinnerStyles: IStyleFunctionOrObject<ISpinnerStyleProps, ISpinnerStyles> = {
        label: {
            color: stylingVariables.straumannBlueColor,
        },
        circle: {
            borderColor: `${stylingVariables.straumannBlueColor} ${stylingVariables.straumannBlueLightestColor} ${stylingVariables.straumannBlueLightestColor}`
        }
    }


    const CheckStyles: IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles> =
        (props: ICheckboxStyleProps): DeepPartial<ICheckboxStyles> => {
            if (props.checked) {
                return {
                    root: {
                        '&::before': {
                            backgroundColor: stylingVariables.straumannBlueColor
                        }
                    },
                    label: {
                        fontSize: defaultLabelFontSize
                    },
                    text: {
                        fontSize: defaultTextFontSize
                    }
                };
            }
            else {
                return {
                    label: {
                        fontSize: defaultLabelFontSize
                    },
                    text: {
                        fontSize: defaultTextFontSize
                    }
                };
            }
        };

    const CheckBoxStyles: IStyleFunctionOrObject<ICheckboxStyleProps, ICheckboxStyles> =
        (props: ICheckboxStyleProps): DeepPartial<ICheckboxStyles> => {
            if (props.checked) {
                return {
                    root: {
                        '&:hover .ms-Checkbox-checkbox': {
                            borderColor: stylingVariables.straumannBlueHoverColor,
                            backgroundColor: stylingVariables.straumannBlueHoverColor
                        },
                        fontSize: '14px'
                    },
                    checkbox: {
                        borderColor: stylingVariables.straumannBlueColor,
                        backgroundColor: stylingVariables.straumannBlueColor,
                        '&:hover': {
                            borderColor: stylingVariables.straumannBlueHoverColor,
                            backgroundColor: stylingVariables.straumannBlueHoverColor
                        }
                    },
                    label: {
                        fontSize: defaultLabelFontSize
                    },
                    text: {
                        fontSize: defaultTextFontSize
                    }
                };
            }
            else {
                return {
                    root: {
                        fontSize: defaultTextFontSize
                    },
                    label: {
                        fontSize: defaultLabelFontSize
                    },
                    text: {
                        fontSize: defaultTextFontSize
                    }
                };
            }
        };

    const PivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
        linkIsSelected: {
            '&::before': {
                backgroundColor: stylingVariables.straumannBlueColor
            }
        }
    }

    const ChoiceGroupOptionStyles: IStyleFunctionOrObject<IChoiceGroupOptionStyleProps, IChoiceGroupOptionStyles> = (props: IChoiceGroupOptionStyleProps): DeepPartial<IChoiceGroupOptionStyles> => {

        if (props.checked) {
            return {
                field: {
                    '&::before': {
                        borderColor: stylingVariables.straumannBlueColor
                    },
                    '&::after': {
                        backgroundColor: stylingVariables.straumannBlueColor,
                        borderColor: stylingVariables.straumannBlueColor
                    }
                },
                choiceFieldWrapper: {
                    paddingRight: '10px'
                }
            };
        }
        else {
            return {
                choiceFieldWrapper: {
                    paddingRight: '10px'
                }
            };
        }
    };

    const SearchBoxStyles: IStyleFunctionOrObject<ISearchBoxStyleProps, ISearchBoxStyles> = {
        root: {
            borderWidth: '2px',
            borderRadius: 0,
        },
        iconContainer: {
            color: stylingVariables.straumannBlueColor
        }
    }

    return {
        defaultFontStyle: { fontFamily: "Webfont" },
        palette: {
            neutralPrimary: stylingVariables.straumannLightBlackColor,
        },
        semanticColors: {
            bodyText: stylingVariables.straumannLightBlackColor,
            primaryButtonBackground: stylingVariables.straumannBlueColor,
            primaryButtonBackgroundHovered: stylingVariables.straumannBlueHoverAltColor,
            primaryButtonBackgroundPressed: stylingVariables.straumannBlueHoverAltColor,
            inputBorder: stylingVariables.straumannLighterGreyColor,
            inputBorderHovered: stylingVariables.straumannInputBorderHovered,
            inputFocusBorderAlt: stylingVariables.straumannInputFocusBorderAlt,
            focusBorder: stylingVariables.straumannFocusBorder,
        },
        components: {
            Label: {
                styles: LabelStyles
            },
            TextField: {
                styles: TextFieldStyles
            },
            Dropdown: {
                styles: DropdownStyles
            },
            NormalPeoplePicker: {
                styles: NormalPeoplePickerStyles
            },
            SearchBox: {
                styles: SearchBoxStyles
            },
            IconButton: {
                styles: IconStyles
            },
            CommandBarButton: {
                styles: CommandBarStyles
            },
            Pivot: {
                styles: PivotStyles
            },
            Check: {
                styles: CheckStyles
            },
            Checkbox: {
                styles: CheckBoxStyles
            },
            ChoiceGroupOption: {
                styles: ChoiceGroupOptionStyles
            },
            ActionButton: {
                styles: CommandBarStyles
            },
            Spinner: {
                styles: SpinnerStyles
            }
        }
    };
}

// export const AppTheme: PartialTheme = {
//     defaultFontStyle: { fontFamily: "Webfont" },
//     palette: {
//         neutralPrimary: stylingVariables.straumannLightBlackColor,
//     },
//     semanticColors: {
//         bodyText: stylingVariables.straumannLightBlackColor,
//         primaryButtonBackground: stylingVariables.straumannBlueColor,
//         primaryButtonBackgroundHovered: stylingVariables.straumannBlueHoverAltColor,
//         primaryButtonBackgroundPressed: stylingVariables.straumannBlueHoverAltColor,
//         inputBorder: stylingVariables.straumannLighterGreyColor,
//         inputBorderHovered: stylingVariables.straumannInputBorderHovered,
//         inputFocusBorderAlt: stylingVariables.straumannInputFocusBorderAlt,
//         focusBorder: stylingVariables.straumannFocusBorder,
//     },
//     components: {
//         Label: {
//             styles: LabelStyles
//         },
//         TextField: {
//             styles: TextFieldStyles
//         },
//         Dropdown: {
//             styles: DropdownStyles
//         },
//         NormalPeoplePicker: {
//             styles: NormalPeoplePickerStyles
//         },
//         SearchBox: {
//             styles: SearchBoxStyles
//         },
//         IconButton: {
//             styles: IconStyles
//         },
//         CommandBarButton: {
//             styles: CommandBarStyles
//         },
//         Pivot: {
//             styles: PivotStyles
//         },
//         Check: {
//             styles: CheckStyles
//         },
//         Checkbox: {
//             styles: CheckBoxStyles
//         },
//         ChoiceGroupOption: {
//             styles: ChoiceGroupOptionStyles
//         },
//         ActionButton: {
//             styles: CommandBarStyles
//         },
//         Spinner: {
//             styles: SpinnerStyles
//         }
//     }
// };