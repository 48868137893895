import axios, { AxiosResponse } from 'axios';
import { User, UserRegistration } from '../interfaces/User.interface';
import { AuthenticationProvider } from '../components/Login/AuthenticationProvider.';
import { IPublicClientApplication } from '@azure/msal-browser';
import { googleLogout } from '@react-oauth/google';
import { ISpeakerInfo } from '../../server/speakerInfo/speakerInfo.interface';
import { IApplicationLog, IEmailQueue, IMessageContent, IDocuments } from '../interfaces';
import { deleteSpeakerDocument } from '../../server/speakerInfo/speakerInfo.service';

export type TranslateTokenResult = {
    newToken: string | null;
    error: string | null;
};

export abstract class APIHelper {
    public static login = (username: string, password: string, stayLoggedIn: boolean, reCaptchaToken: string): Promise<AxiosResponse<any, any>> => {
        return axios.post("/api/users/login", { username, password, stayLoggedIn, reCaptchaToken });
    }

    public static logout = async (token: string, provider: AuthenticationProvider, providerData?: any): Promise<void> => {
        switch (provider) {
            case AuthenticationProvider.Google:
                googleLogout();
                break;
            case AuthenticationProvider.Microsoft:
                await (providerData as IPublicClientApplication)?.logoutPopup();
                break;
        }

        return axios.post("/api/users/logout", {
            jwt: token,
            provider
        });
    };

    public static authCheck = (): Promise<AxiosResponse<any, any>> => {
        return axios.get("/api/users/authcheck", {});
    };

    public static getSpeakerInfo = (): Promise<AxiosResponse<any, any>> => {
        return axios.get("/api/speakerInfo/getspeakerinfo");
    };

    public static getAppSettings = (): Promise<AxiosResponse<any, any>> => {
        return axios.get("/api/speakerInfo/getappsettings");
    };

    public static getApplicationText = (brand: string): Promise<AxiosResponse<any, any>> => {
        return axios.get("/api/speakerInfo/getapplicationtext", { params: { brand: brand } });
    };

    public static getAllChoices = (): Promise<AxiosResponse<any, any>> => {
        return axios.get("/api/speakerInfo/getallchoices");
    };

    public static getEmailTemplates = (): Promise<AxiosResponse<any, any>> => {
        return axios.get("/api/speakerInfo/getemailtemplates");
    };

    public static getSpeakerDocuments = (): Promise<AxiosResponse<any, any>> => {
        return axios.get("/api/speakerInfo/getspeakerdocuments");
    };

    public static updateSpeakerInfo = (itemID: string, speakerInfoItem: ISpeakerInfo): Promise<AxiosResponse<any, any>> => {
        return axios.patch("/api/speakerInfo/updatespeakerinfo", { itemID: itemID, speakerInfoItem: speakerInfoItem });
    };

    public static uploadSpeakerDocuments = (folderName: string, documents: IDocuments[]): Promise<AxiosResponse<any, any>> => {
        return axios.post("/api/speakerInfo/uploadspeakerdocuments", { folderName: folderName, documents: documents });
    };

    public static getSpeakerDocumentDownloadLink = async (id: string): Promise<string> => {
        const response = await axios.get<string>(`/api/speakerInfo/getspeakerdocumentlink/${id}`);
        return response.data;
    };

    public static deleteSpeakerDocument = (docId: string): Promise<AxiosResponse<any, any>> => {
        return axios.post("/api/speakerInfo/deletespeakerdocument", { docId: docId });
    };

    public static sendEmail = (status: string, spItem: ISpeakerInfo): Promise<AxiosResponse<any, any>> => {
        return axios.post("/api/speakerInfo/sendemail", { status: status, spItem: spItem });
    };

    public static createApplicationLog = (applicationLog: IApplicationLog): Promise<AxiosResponse<any, any>> => {
        return axios.post("/api/speakerInfo/createapplicationlog", { applicationLog: applicationLog });
    };

    public static translateToken = (token: string, provider: AuthenticationProvider, invCode: string, reCaptchaToken: string, email: string = ""): Promise<TranslateTokenResult> => {
        console.log(`Translating token...`);
        return axios.post("/api/users/translateToken", { jwt: token, provider, invCode, reCaptchaToken, email }).then((result) => {
            console.log("translated token", result);
            return {
                newToken: result?.data as string,
                error: null
            } as TranslateTokenResult;
        }).catch(err => {
            console.log("Error tyring to translate the Provider token", err);
            return {
                newToken: null,
                error: err?.response?.data
            } as TranslateTokenResult;
        });
    };

    public static registerUser = (regData: UserRegistration): Promise<boolean> => {
        return axios.post("/api/users/register", regData).then(result => {
            return result.status === 201;
        }).catch(err => {
            return false;
        });
    };

    public static verifyEmailCode = (code: string): Promise<boolean> => {
        return axios.post("/api/users/verify", { code }).then(result => {
            return result.status === 200;
        }).catch(err => {
            return false;
        });
    };

    public static demoCall = (): Promise<void> => {
        return axios.post("/api/users/form", {}).then(result => {
            alert(`Result: ${result.statusText}`);
        }).catch(err => {
            if (err?.response?.status == 401) {
                alert(`Error: User must be signed in to call this API function.`);
            }
        });
    };
}