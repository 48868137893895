import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { APIHelper } from './helpers/APIHelper';
import Spinner from 'react-bootstrap/Spinner';

const Verification = (): React.JSX.Element => {
    const { code } = useParams();
    const [result, setResult] = useState<boolean | null>(null);

    useEffect(() => {
        if (code) {
            const checkCode = async () => {
                return APIHelper.verifyEmailCode(code);
            };
            checkCode().then((valid:boolean)=>{
                setResult(valid);
            });
        }
    }, []);

    return (
        <div>
            <h2>Email Verification</h2>
            {result === null && <div><Spinner/> Verifying email code...</div>}
            {result !== null ? Boolean(result) ? <div>Email Address was successfully verified.</div> : <div>There was a problem verifying your email code. Please click here to resend the verification email.</div> : null}
        </div>
    );
};

export default Verification;