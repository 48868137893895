import { IFormFields } from '../interfaces';
import { DocumentCategories } from './DocumentCategories';

export const PersonalInformationFields:IFormFields[] = [
    { field:"firstName",isMandatory:true, errorMessage:"", isValid:true },
    { field:"lastName",isMandatory:true, errorMessage:"", isValid:true },
    { field:"gender",isMandatory:true, errorMessage:"", isValid:true },
    { field:"membershipType",isMandatory:true, errorMessage:"", isValid:true },
    { field:"territoryManager",isMandatory:true, errorMessage:"", isValid:true },
    { field:"labName",isMandatory:true, errorMessage:"", isValid:true },
    { field:"customerAccount",isMandatory:true, errorMessage:"", isValid:true },
    { field:"profession",isMandatory:true, errorMessage:"", isValid:true },
];

export const StraumannContactInformationFields:IFormFields[] = [
    { field:"country",isMandatory:true, errorMessage:"", isValid:true },
    { field:"licensedMAOrVT",isMandatory:true, errorMessage:"", isValid:true },
    { field:"addressLine1",isMandatory:true, errorMessage:"", isValid:true },
    { field:"city",isMandatory:true, errorMessage:"", isValid:true },
    { field:"postalCode",isMandatory:true, errorMessage:"", isValid:true },
    { field:"workPhone",isMandatory:true, errorMessage:"", isValid:true },
    { field:"cellPhone",isMandatory:true, errorMessage:"", isValid:true },
    { field:"PrimaryStateLicensure",isMandatory:true, errorMessage:"", isValid:true },
    { field:"stateLicense",isMandatory:true, errorMessage:"", isValid:true },
    { field:"nationalProviderId",isMandatory:true, errorMessage:"", isValid:true },
    { field:"usState",isMandatory:true, errorMessage:"", isValid:true },
    { field:"maorVTLicenseNumber",isMandatory:false, errorMessage:"", isValid:true }
];

export const OtherBrandContactInformationFields:IFormFields[] = [
    ...StraumannContactInformationFields, 
];    

export const ClearCorrectSpeakerLevelBIOTopicsFields:IFormFields[] = [
    { field:"speakerBIO",isMandatory:true, errorMessage:"", isValid:true }    
];

export const NeodentSpeakerLevelBIOTopicsFields:IFormFields[] = [
    { field:"foundationImplantDentistry",isMandatory:true, errorMessage:"", isValid:true },
    { field:"interestedInITIStudyClubs",isMandatory:true, errorMessage:"", isValid:true },
    { field:"speakerBIO",isMandatory:true, errorMessage:"", isValid:true },
    { field:"treatmentPlanning",isMandatory:true, errorMessage:"", isValid:true },
    { field:"surgicalProcsImplantDentistry",isMandatory:true, errorMessage:"", isValid:true },
    { field:"prostheticProcsImplantDentistry",isMandatory:true, errorMessage:"", isValid:true }, 
    { field:"laboratoryProcedures",isMandatory:true, errorMessage:"", isValid:true }, 
    { field:"digitalDentistry",isMandatory:true, errorMessage:"", isValid:true }, 
    { field:"regenerative",isMandatory:true, errorMessage:"", isValid:true }, 
    { field:"practiceManagement",isMandatory:true, errorMessage:"", isValid:true },
    { field:"productPortfolio",isMandatory:true, errorMessage:"", isValid:true },   
    { field:"levelForumSpeaking",isMandatory:true, errorMessage:"", isValid:true },    
];
    
export const StraumannSpeakerLevelBIOTopicsFields:IFormFields[] = [
    ...NeodentSpeakerLevelBIOTopicsFields,
    { field:"hygiene",isMandatory:true, errorMessage:"", isValid:true },
    { field:"itiStudyClubInfoAgreement",isMandatory:true, errorMessage:"", isValid:true }, 
    { field:"surgicalProductPortfolio",isMandatory:false, errorMessage:"", isValid:true },
    { field:"restorativeProductPortfolio",isMandatory:false, errorMessage:"", isValid:true },
    { field:"biomaterialsProductPortfolio",isMandatory:false, errorMessage:"", isValid:true },
    { field:"digitalWorkflowPortfolio",isMandatory:false, errorMessage:"", isValid:true }     
];

    
export const ClearCorrectQuestionsAndRequiredDocumentsFields:IFormFields[] = [
    { field:"speakerNetworkAcknowledgment",isMandatory:true, errorMessage:"", isValid:true },  
    { field:"permissionsToRecordWebinar",isMandatory:true, errorMessage:"", isValid:true }, 
    { field:"conductsWebinarPresentations",isMandatory:true, errorMessage:"", isValid:true }, 
    { field:"photo",isMandatory:true, errorMessage:"", isValid:true, dataValidation:true, dataType:"document", isDocument:true, category: DocumentCategories.Headshot  },
    { field:"cv",isMandatory:true, errorMessage:"", isValid:true, dataValidation:true, dataType:"document", isDocument:true, category: DocumentCategories.CV },   
    { field:"vendorForm",isMandatory:true, errorMessage:"", isValid:true, dataValidation:true, dataType:"document", isDocument:true, category: DocumentCategories.VendorForm },     
];

export const StraumannQuestionsAndRequiredDocumentsFields:IFormFields[] = [
    { field:"speakerNetworkAcknowledgment",isMandatory:true, errorMessage:"", isValid:true },  
    { field:"permissionsToRecordWebinar",isMandatory:true, errorMessage:"", isValid:true }, 
    { field:"conductsWebinarPresentations",isMandatory:true, errorMessage:"", isValid:true }, 
    { field:"tcAgreed",isMandatory:true, errorMessage:"", isValid:true, isBoolean:true },
    { field:"w9",isMandatory:true, errorMessage:"", isValid:true, isDocument:true, category: DocumentCategories.W9TaxFile  },
    { field:"photo",isMandatory:true, errorMessage:"", isValid:true, isDocument:true, category: DocumentCategories.Headshot  },
    { field:"cv",isMandatory:true, errorMessage:"", isValid:true, isDocument:true, category: DocumentCategories.CV  }, 
    { field:"vendorForm",isMandatory:true, errorMessage:"", isValid:true, dataValidation:true, dataType:"document", isDocument:true, category: DocumentCategories.VendorForm },     
];

export const NeodentQuestionsAndRequiredDocumentsFields:IFormFields[] = [
    ...StraumannQuestionsAndRequiredDocumentsFields 
];

export const SnedBackFields:IFormFields[]=[
    { field:"comments",isMandatory:false, errorMessage:"", isValid:true },     
];

