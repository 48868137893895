import { AuditLogActionCommands, RequestStatus } from '../../constant';
import { IApplicationLog, IDictionary, IFormFields, ISteps } from '../../interfaces';
import { CommonService } from '../../services';
import { ISpeakerInfoFormState } from './ISpeakerInfoFormState';
import { APIHelper } from '../../helpers/APIHelper';
import { IPersonalInformationState } from './PersonalInformation/IPersonalInformationState';
import { IContactInformationState } from './ContactInformation/IContactInformationState';
import { ISpeakerLevelBIOTopicsState } from './SpeakerLevelBIOTopics/ISpeakerLevelBIOTopicsState';
import { IQuestionsAndRequiredDocumentsState } from './QuestionsAndRequiredDocuments/IQuestionsAndRequiredDocumentsState';
import { ISendBackState } from './SendBack/SendBack';
import { ISpeakerInfo } from '../../../server/speakerInfo/speakerInfo.interface';
import { AppContext } from '../context';
import { useContext } from 'react';
import { IAuditLog } from '../../interfaces/IAuditLog';

export class SpeakerInfoFormService {
    private speakerInfoFormState: any;
    private setSpeakerInfoFormState: any;
    private commonService: CommonService;
    private appContext: any;
    constructor(speakerInfoFormState: any, setSpeakerInfoFormState: any) {
        this.appContext = useContext(AppContext);
        this.commonService = new CommonService();
        this.speakerInfoFormState = speakerInfoFormState;
        this.setSpeakerInfoFormState = setSpeakerInfoFormState;
    }

    public InitSpeakerInfoFrom(): void {
        try {
            this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
                ...prevState,
                spinner: {
                    show: true,
                    text: this.speakerInfoFormState.applicationLables.Label_Loading
                },
            }));
            const appSettings = APIHelper.getAppSettings();
            const allChoices = APIHelper.getAllChoices();
            const emailTemplates = APIHelper.getEmailTemplates();
            const speakerInfo = APIHelper.getSpeakerInfo();
            Promise.all([appSettings, allChoices, speakerInfo, emailTemplates]).then((promiseAllData: any) => {
                const applicationText = APIHelper.getApplicationText(promiseAllData[2].data.Brand);
                Promise.all([applicationText]).then((applicationTextData: any) => {
                    this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
                        ...prevState,
                        appSettings: promiseAllData[0].data,
                        allChoices: promiseAllData[1].data,
                        speakerInfoItem: promiseAllData[2].data,
                        applicationText: applicationTextData[0].data,
                        emailTemplates: promiseAllData[3].data,
                        spinner: {
                            show: false,
                            text: ""
                        },
                        brand: promiseAllData[2].data.Brand
                    }));
                }).catch((error) => {
                    let applicationLog: IApplicationLog = {
                        Title: "InitSpeakerInfoFrom",
                        LogType: "Error",
                        Location: "SPFx | InitSpeakerInfoFrom | SpeakerInfoFromService",
                        LogDetails: error.error
                    }
                    APIHelper.createApplicationLog(applicationLog);
                });
            }).catch((error) => {
                let applicationLog: IApplicationLog = {
                    Title: "InitSpeakerInfoFrom",
                    LogType: "Error",
                    Location: "SPFx | InitSpeakerInfoFrom | SpeakerInfoFromService",
                    LogDetails: error.error
                }
                APIHelper.createApplicationLog(applicationLog);
            });
        }
        catch (error: any) {
            let applicationLog: IApplicationLog = {
                Title: "InitSpeakerInfoFrom",
                LogType: "Error",
                Location: "SPFx | InitSpeakerInfoFrom | SpeakerInfoFromService",
                LogDetails: error.error
            }
            APIHelper.createApplicationLog(applicationLog);
        }
    }

    public HandleNext(instance: any, ref: any, formState: IPersonalInformationState | IContactInformationState | ISpeakerLevelBIOTopicsState | IQuestionsAndRequiredDocumentsState | undefined, formFields: IFormFields[] | null, speakerInfoFormStateKey: string, appsettings: IDictionary<string>[], isWelcomeStep: boolean = false): void {
        let errorMessagesForFormFields: IDictionary<string> = {};
        if (!isWelcomeStep) {
            errorMessagesForFormFields = this.ValidateCurrentStepFields(formState, formFields);
        }

        if (errorMessagesForFormFields.isValid === "Yes" || isWelcomeStep) {
            if (this.speakerInfoFormState.activeStep.key !== "finalStep") {
                instance.nextStep();
                const index = this.speakerInfoFormState.steps.findIndex(
                    (x: any) => {
                        return x.key === this.speakerInfoFormState.activeStep.key
                    });
                const updatedSteps: ISteps[] = [];
                this.speakerInfoFormState.steps.forEach((step: any) => {
                    if (step.key === this.speakerInfoFormState.activeStep.key) step.isDone = true;
                    updatedSteps.push(step);
                });
                if (formState)
                    formState.errorMessagesForFormFields = errorMessagesForFormFields;
                this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
                    ...prevState,
                    steps: updatedSteps,
                    activeStep: this.speakerInfoFormState.steps[index + 1],
                    [speakerInfoFormStateKey]: formState,
                    spinner: {
                        show: false,
                        text: ''
                    }
                }));
            }
            if (this.speakerInfoFormState.activeStep.key === "finalStep") {
                this.UpdateSpeakerInfo(formState, appsettings);
            }
        }
        else if (errorMessagesForFormFields.isValid === "No") {
            if (formState)
                formState.errorMessagesForFormFields = errorMessagesForFormFields;

            ref.current?.updateValidationErrors(errorMessagesForFormFields);
            this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
                ...prevState,
                isRequestSubmitted: false,
                spinner: {
                    show: false,
                    text: ''
                },
                [speakerInfoFormStateKey]: formState
            }));
        }
    }

    public HandleBack(instance: any, formState: IPersonalInformationState | IContactInformationState | ISpeakerLevelBIOTopicsState | IQuestionsAndRequiredDocumentsState | undefined, speakerInfoFormStateKey: string): void {
        instance.previousStep();
        const index = this.speakerInfoFormState.steps.findIndex((x: any) => {

            return x.key === this.speakerInfoFormState.activeStep.key
        });
        if (index === 0) return;

        const updatedSteps: ISteps[] = [];
        this.speakerInfoFormState.steps.forEach((step: ISteps) => {
            if (step.key === this.speakerInfoFormState.activeStep.key) step.isDone = false;
            updatedSteps.push(step);
        });
        this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
            ...prevState,
            steps: updatedSteps,
            activeStep: this.speakerInfoFormState.steps[index - 1],
            [speakerInfoFormStateKey]: formState
        }));

    }

    public SubmitMoreInformation(ref: any, formState: ISendBackState | undefined, formFields: IFormFields[], speakerInfoFormStateKey: string): void {
        let errorMessagesForFormFields: IDictionary<string> = this.ValidateCurrentStepFields(formState, formFields);
        if (errorMessagesForFormFields.isValid === "Yes") {
            this.UpdateFeedbackInfo(formState);
        }
        else if (errorMessagesForFormFields.isValid === "No") {
            ref.current?.updateValidationErrors(errorMessagesForFormFields);
            this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
                ...prevState,
                isRequestSubmitted: false,
                spinner: {
                    show: false,
                    text: ''
                }
            }));
        }
    }

    public ValidateCurrentStepFields(formState: IPersonalInformationState | IContactInformationState | ISpeakerLevelBIOTopicsState | IQuestionsAndRequiredDocumentsState | ISendBackState | undefined | null | any, formFields: IFormFields[] | null): IDictionary<string> {
        let errorMessagesForFormFields: IDictionary<string> = {};
        let isValid: string = "Yes";
        let isFieldDataValid = true;
        let errorMsg: string = this.speakerInfoFormState.applicationLables.ErrorMsg_Required;
        formFields?.forEach((field: IFormFields) => {
            if (field.isMandatory && formState) {
                if (field?.isDocument) {
                    if (formState.documents.length && !formState.documents.some(x => x.category === field.category)) {
                        isFieldDataValid = false;
                        isValid = "No";
                        errorMsg = this.speakerInfoFormState.applicationLables.ErrorMsg_Required;
                    }
                }
                else if (field?.isBoolean && !formState[field.field]) {
                    isFieldDataValid = false;
                    isValid = "No";
                    errorMsg = this.speakerInfoFormState.applicationLables.ErrorMsg_Required;
                }
                else if (!formState[field.field]) {
                    isFieldDataValid = false;
                    isValid = "No";
                    errorMsg = this.speakerInfoFormState.applicationLables.ErrorMsg_Required;
                }
                else {
                    const fieldValue: any = formState ? formState[field.field] : "";
                    const dataType: string | undefined = field.dataType;
                    switch (dataType) {
                        case "eMail":
                            if (!this.commonService.validateEmail(fieldValue)) {
                                isFieldDataValid = false;
                                isValid = "No";
                                errorMsg = this.speakerInfoFormState.applicationLables.ErrorMsg_ValidEmail;
                            }
                            break;
                        default:
                            isFieldDataValid = true;
                            errorMsg = "";
                            break;
                    }
                }
                field.isValid = isFieldDataValid;
                field.errorMessage = errorMsg;
                errorMessagesForFormFields[field.field] = errorMsg;
                isValid = isValid;
            }
        });
        errorMessagesForFormFields["isValid"] = isValid;
        return errorMessagesForFormFields;
    }

    public UpdateSpeakerInfo(formState: IPersonalInformationState | IContactInformationState | ISpeakerLevelBIOTopicsState | IQuestionsAndRequiredDocumentsState | undefined, appsettings: IDictionary<string>[]) {
        let auditLog: string = this.GetAuditLog(AuditLogActionCommands.ProfileSubmitted.Action, AuditLogActionCommands.ProfileSubmitted.ActionType);
        const questionsAndRequiredDocumentsFormState: any = formState;
        let lastNameValue: string = this.RemoveDotAsALastChar(this.speakerInfoFormState.personalInformationState?.lastName);
        const speakerInfoItem: ISpeakerInfo = {
            FirstName: this.speakerInfoFormState.personalInformationState?.firstName.trim(),
            LastName: lastNameValue?.trim(),
            Gender: this.speakerInfoFormState.personalInformationState?.gender,
            MembershipType: this.speakerInfoFormState.personalInformationState?.membershipType,
            CustomerAccount: this.speakerInfoFormState.personalInformationState?.customerAccount,
            Profession: this.speakerInfoFormState.personalInformationState?.profession,
            LabName: this.speakerInfoFormState.personalInformationState?.labName,
            TerritoryManager: this.speakerInfoFormState.personalInformationState?.territoryManager,
            Country: this.speakerInfoFormState.contactInformationState?.country,
            AddressLine1: this.speakerInfoFormState.contactInformationState?.addressLine1,
            AddressLine2: this.speakerInfoFormState.contactInformationState?.addressLine2,
            City: this.speakerInfoFormState.contactInformationState?.city,
            ZIPCode: this.speakerInfoFormState.contactInformationState?.postalCode,
            WorkPhone: this.speakerInfoFormState.contactInformationState?.workPhone,
            CellPhone: this.speakerInfoFormState.contactInformationState?.cellPhone,
            PrimaryStateLicensure: this.speakerInfoFormState.contactInformationState?.PrimaryStateLicensure,
            StateLicense: this.speakerInfoFormState.contactInformationState?.stateLicense,
            LicensedMAOrVT: this.speakerInfoFormState.contactInformationState?.licensedMAOrVT,
            MAorVTLicenseNumber: this.speakerInfoFormState.contactInformationState?.maorVTLicenseNumber,
            NationalProviderId: this.speakerInfoFormState.contactInformationState?.nationalProviderId,
            State: this.speakerInfoFormState.contactInformationState?.usState,
            LevelForumSpeaking: this.speakerInfoFormState.speakerLevelBIOTopicsState?.levelForumSpeaking,
            InterestedInITIStudyClubs: this.speakerInfoFormState.speakerLevelBIOTopicsState?.interestedInITIStudyClubs,
            SpeakerBIO: this.speakerInfoFormState.speakerLevelBIOTopicsState?.speakerBIO,
            TreatmentPlanning: this.speakerInfoFormState.speakerLevelBIOTopicsState?.treatmentPlanning,
            FoundationImplantDentistry: this.speakerInfoFormState.speakerLevelBIOTopicsState?.foundationImplantDentistry,
            SurgicalProcsImplantDentistry: this.speakerInfoFormState.speakerLevelBIOTopicsState?.surgicalProcsImplantDentistry,
            ProstheticProcsImplantDentistry: this.speakerInfoFormState.speakerLevelBIOTopicsState?.prostheticProcsImplantDentistry,
            LaboratoryProcedures: this.speakerInfoFormState.speakerLevelBIOTopicsState?.laboratoryProcedures,
            DigitalDentistry: this.speakerInfoFormState.speakerLevelBIOTopicsState?.digitalDentistry,
            Regenerative: this.speakerInfoFormState.speakerLevelBIOTopicsState?.regenerative,
            PracticeManagement: this.speakerInfoFormState.speakerLevelBIOTopicsState?.practiceManagement,
            Hygiene: this.speakerInfoFormState.speakerLevelBIOTopicsState?.hygiene,
            ProductPortfolio: this.speakerInfoFormState.speakerLevelBIOTopicsState?.productPortfolio,
            ITIStudyClubInfoAgreement: this.speakerInfoFormState.speakerLevelBIOTopicsState?.itiStudyClubInfoAgreement,
            SurgicalProductPortfolio: this.speakerInfoFormState.speakerLevelBIOTopicsState?.surgicalProductPortfolio,
            RestorativeProductPortfolio: this.speakerInfoFormState.speakerLevelBIOTopicsState?.restorativeProductPortfolio,
            BiomaterialsProductPortfolio: this.speakerInfoFormState.speakerLevelBIOTopicsState?.biomaterialsProductPortfolio,
            DigitalWorkflowPortfolio: this.speakerInfoFormState.speakerLevelBIOTopicsState?.digitalWorkflowPortfolio,
            SpeakerNetworkAcknowledgment: questionsAndRequiredDocumentsFormState?.speakerNetworkAcknowledgment,
            ConductsWebinarPresentations: questionsAndRequiredDocumentsFormState?.conductsWebinarPresentations,
            PermissionsToRecordWebinar: questionsAndRequiredDocumentsFormState?.permissionsToRecordWebinar,
            TCAgreed: questionsAndRequiredDocumentsFormState?.tcAgreed,

            AuditLog: auditLog
        }

        if (this.speakerInfoFormState.speakerInfoItem.IsRecertificationInProgress) {
            speakerInfoItem.IsNonResponsiveReminderSent = false;
            speakerInfoItem.IsOneMonthPriorReminderSent = false;
            speakerInfoItem.IsOneWeekPriorReminderSent = false;
            speakerInfoItem.IsOverdueReminderSent = false;
            speakerInfoItem.IsRecertificationInProgress = false;

            let renewalDaysToAdd = 365;
            if (appsettings["ProfileRenewalInDays"]) {
                renewalDaysToAdd = Number(appsettings["ProfileRenewalInDays"]);
            }
            const newRenewalDate = new Date();
            newRenewalDate.setDate(newRenewalDate.getDate() + renewalDaysToAdd);
            speakerInfoItem.RenewalDate = newRenewalDate.toJSON();
        }
        else {
            speakerInfoItem.Status = RequestStatus.AwaitingApproval;
            speakerInfoItem.ProfileSubmittedTime = new Date().toJSON();
        }

        const updateSpeakerInfo = APIHelper.updateSpeakerInfo(this.speakerInfoFormState.speakerInfoItem.ID, speakerInfoItem);
        Promise.all([updateSpeakerInfo]).then((updateSpeakerInfoData: any) => {
            if (updateSpeakerInfoData[0]) {
                const documentsToUpload = questionsAndRequiredDocumentsFormState.documents.filter(x => !x.docId && x.base64);

                if (documentsToUpload?.length) {
                    const folderName: string = `${this.speakerInfoFormState.speakerInfoItem.ID}-${speakerInfoItem.FirstName}${speakerInfoItem.LastName}`;
                    const uploadDocuments = APIHelper.uploadSpeakerDocuments(folderName, documentsToUpload);
                    Promise.all([uploadDocuments]).then((uploadSpeakerDocumentsData: any) => {

                        //Send email
                        const sendEmail = APIHelper.sendEmail(RequestStatus.CustomerProfileSubmitted, this.speakerInfoFormState.speakerInfoItem);
                        Promise.all([sendEmail]).then((sendEmailData: any) => {
                            this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
                                ...prevState,
                                isRequestSubmitted: true,
                                spinner: {
                                    show: false,
                                    text: ''
                                }
                            }));
                        }).catch((error) => {
                            let applicationLog: IApplicationLog = {
                                Title: "UpdateSpeakerInfo",
                                LogType: "Error",
                                Location: "SPFx | UpdateSpeakerInfo | SpeakerInfoFromService",
                                LogDetails: error.error
                            }
                            APIHelper.createApplicationLog(applicationLog);
                        });
                    }).catch((error) => {
                        let applicationLog: IApplicationLog = {
                            Title: "UpdateSpeakerInfo",
                            LogType: "Error",
                            Location: "SPFx | UpdateSpeakerInfo | SpeakerInfoFromService",
                            LogDetails: error.error
                        }
                        APIHelper.createApplicationLog(applicationLog);
                    });
                }
                else {
                    //Send email
                    const sendEmail = APIHelper.sendEmail(RequestStatus.CustomerProfileSubmitted, this.speakerInfoFormState.speakerInfoItem);
                    Promise.all([sendEmail]).then((sendEmailData: any) => {
                        this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
                            ...prevState,
                            isRequestSubmitted: true,
                            spinner: {
                                show: false,
                                text: ''
                            }
                        }));
                    }).catch((error) => {
                        let applicationLog: IApplicationLog = {
                            Title: "UpdateSpeakerInfo",
                            LogType: "Error",
                            Location: "SPFx | UpdateSpeakerInfo | SpeakerInfoFromService",
                            LogDetails: error.error
                        }
                        APIHelper.createApplicationLog(applicationLog);
                    });
                }
            }
            else {
                this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
                    ...prevState,
                    isRequestSubmitted: false,
                    spinner: {
                        show: false,
                        text: ''
                    }
                }));
            }
        }).catch((error) => {
            let applicationLog: IApplicationLog = {
                Title: "UpdateSpeakerInfo",
                LogType: "Error",
                Location: "SPFx | UpdateSpeakerInfo | SpeakerInfoFromService",
                LogDetails: error.error
            }
            APIHelper.createApplicationLog(applicationLog);
        });
    }

    public RemoveDotAsALastChar(str: string): string {
        // Last Dot Char removed from last name
        let lastNameValue: string = str;
        if (lastNameValue) {
            if (lastNameValue.lastIndexOf(".") !== -1) {
                lastNameValue = lastNameValue.replace(/\.+$/, "");
            }
        }

        return lastNameValue;
    }

    public SaveSpeakerInformation(personalInformationState: IPersonalInformationState | undefined, contactInformationState: IContactInformationState | undefined, speakerLevelBIOTopicsState: ISpeakerLevelBIOTopicsState | undefined, questionsAndRequiredDocumentsFormState: IQuestionsAndRequiredDocumentsState | undefined) {
        let lastNameValue: string = this.RemoveDotAsALastChar(personalInformationState?.lastName ?? "");
        const speakerInfoItem: ISpeakerInfo = {
            FirstName: personalInformationState?.firstName?.trim(),
            LastName: lastNameValue.trim(),
            Gender: personalInformationState?.gender,
            MembershipType: personalInformationState?.membershipType,
            CustomerAccount: personalInformationState?.customerAccount,
            Profession: personalInformationState?.profession,
            LabName: personalInformationState?.labName,
            TerritoryManager: personalInformationState?.territoryManager,
            Country: contactInformationState?.country,
            AddressLine1: contactInformationState?.addressLine1,
            AddressLine2: contactInformationState?.addressLine2,
            City: contactInformationState?.city,
            ZIPCode: contactInformationState?.postalCode,
            WorkPhone: contactInformationState?.workPhone ?? undefined,
            CellPhone: contactInformationState?.cellPhone ?? undefined,
            PrimaryStateLicensure: contactInformationState?.PrimaryStateLicensure,
            StateLicense: contactInformationState?.stateLicense,
            LicensedMAOrVT: contactInformationState?.licensedMAOrVT,
            MAorVTLicenseNumber: contactInformationState?.maorVTLicenseNumber,
            NationalProviderId: contactInformationState?.nationalProviderId,
            State: contactInformationState?.usState,
            LevelForumSpeaking: speakerLevelBIOTopicsState?.levelForumSpeaking,
            InterestedInITIStudyClubs: speakerLevelBIOTopicsState?.interestedInITIStudyClubs,
            SpeakerBIO: speakerLevelBIOTopicsState?.speakerBIO,
            TreatmentPlanning: speakerLevelBIOTopicsState?.treatmentPlanning,
            FoundationImplantDentistry: speakerLevelBIOTopicsState?.foundationImplantDentistry,
            SurgicalProcsImplantDentistry: speakerLevelBIOTopicsState?.surgicalProcsImplantDentistry,
            ProstheticProcsImplantDentistry: speakerLevelBIOTopicsState?.prostheticProcsImplantDentistry,
            LaboratoryProcedures: speakerLevelBIOTopicsState?.laboratoryProcedures,
            DigitalDentistry: speakerLevelBIOTopicsState?.digitalDentistry,
            Regenerative: speakerLevelBIOTopicsState?.regenerative,
            PracticeManagement: speakerLevelBIOTopicsState?.practiceManagement,
            Hygiene: speakerLevelBIOTopicsState?.hygiene,
            ProductPortfolio: speakerLevelBIOTopicsState?.productPortfolio,
            ITIStudyClubInfoAgreement: speakerLevelBIOTopicsState?.itiStudyClubInfoAgreement,
            SurgicalProductPortfolio: speakerLevelBIOTopicsState?.surgicalProductPortfolio,
            RestorativeProductPortfolio: speakerLevelBIOTopicsState?.restorativeProductPortfolio,
            BiomaterialsProductPortfolio: speakerLevelBIOTopicsState?.biomaterialsProductPortfolio,
            DigitalWorkflowPortfolio: speakerLevelBIOTopicsState?.digitalWorkflowPortfolio,
            SpeakerNetworkAcknowledgment: questionsAndRequiredDocumentsFormState?.speakerNetworkAcknowledgment,
            ConductsWebinarPresentations: questionsAndRequiredDocumentsFormState?.conductsWebinarPresentations,
            PermissionsToRecordWebinar: questionsAndRequiredDocumentsFormState?.permissionsToRecordWebinar,
            TCAgreed: questionsAndRequiredDocumentsFormState?.tcAgreed
        }

        const updateSpeakerInfo = APIHelper.updateSpeakerInfo(this.speakerInfoFormState.speakerInfoItem.ID, speakerInfoItem);

        Promise.all([updateSpeakerInfo]).then((updateSpeakerInfoData: any) => {
            if (updateSpeakerInfoData[0]) {
                const documentsToUpload = questionsAndRequiredDocumentsFormState?.documents?.filter(x => !x.docId && x.base64);

                if (documentsToUpload?.length) {
                    const folderName: string = `${this.speakerInfoFormState.speakerInfoItem.ID}-${speakerInfoItem.FirstName}${speakerInfoItem.LastName}`;
                    const uploadDocuments = APIHelper.uploadSpeakerDocuments(folderName, documentsToUpload);
                    Promise.all([uploadDocuments]).then((uploadSpeakerDocumentsData: any) => {
                        this.GetSpeakerInfo();
                    }).catch((error) => {
                        let applicationLog: IApplicationLog = {
                            Title: "uploadSpeakerDocuments",
                            LogType: "Error",
                            Location: "SPFx | SaveSpeakerInformation | SpeakerInfoFromService",
                            LogDetails: error.error
                        }
                        APIHelper.createApplicationLog(applicationLog);
                    });
                }
                else {
                    this.GetSpeakerInfo();
                }
            }
            else {
                this.GetSpeakerInfo();
            }
        }).catch((error) => {
            let applicationLog: IApplicationLog = {
                Title: "SaveSpeakerInformation",
                LogType: "Error",
                Location: "SPFx | SaveSpeakerInformation | SpeakerInfoFromService",
                LogDetails: error.error
            }
            APIHelper.createApplicationLog(applicationLog);
        });
    }

    public GetSpeakerInfo() {
        const speakerInfo = APIHelper.getSpeakerInfo();
        Promise.all([speakerInfo]).then((promiseAllData: any) => {
            this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
                ...prevState,
                speakerInfoItem: promiseAllData[0].data,
                spinner: {
                    show: false,
                    text: ""
                },
                showMessageDialog: false
            }));
        }).catch((error) => {
            let applicationLog: IApplicationLog = {
                Title: "GetSpeakerInfo",
                LogType: "Error",
                Location: "SPFx | GetSpeakerInfo | SpeakerInfoFromService",
                LogDetails: error.error
            }
            APIHelper.createApplicationLog(applicationLog);
        });
    }

    public UpdateFeedbackInfo(formState: ISendBackState | undefined) {
        const educationTeamApprovalDetails = JSON.parse(this.speakerInfoFormState.speakerInfoItem.EducationTeamApprovalDetails);
        delete educationTeamApprovalDetails.ApprovalResult;
        let auditLog: string = this.GetAuditLog(AuditLogActionCommands.Feedback.Action, AuditLogActionCommands.Feedback.ActionType);
        const speakerInfoItem: ISpeakerInfo = {
            Status: RequestStatus.AwaitingApproval,
            SpeakerFeedbackComment: formState?.comments,
            FeedbackRecievedTime: new Date().toJSON(),
            EducationTeamApprovalDetails: JSON.stringify(educationTeamApprovalDetails),
            AuditLog: auditLog
        }
        const updateSpeakerInfo = APIHelper.updateSpeakerInfo(this.speakerInfoFormState.speakerInfoItem.ID, speakerInfoItem);
        Promise.all([updateSpeakerInfo]).then((updateSpeakerInfoData: any) => {
            if (updateSpeakerInfoData[0]) {

                const documentToUpload = formState?.documents?.filter(x => !x.docId && x.base64);

                if (documentToUpload?.length) {
                    const folderName: string = `${this.speakerInfoFormState.speakerInfoItem.ID}-${this.speakerInfoFormState.speakerInfoItem.FirstName}${this.speakerInfoFormState.speakerInfoItem.LastName}`;
                    const uploadDocuments = APIHelper.uploadSpeakerDocuments(folderName, documentToUpload);
                    Promise.all([uploadDocuments]).then((uploadSpeakerDocumentsData: any) => {
                        const sendEmail = APIHelper.sendEmail(RequestStatus.CustomerFeedbackReceived, this.speakerInfoFormState.speakerInfoItem);
                        Promise.all([sendEmail]).then((sendEmailData: any) => {
                            this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
                                ...prevState,
                                isRequestSubmitted: true,
                                spinner: {
                                    show: false,
                                    text: ''
                                }
                            }));
                        }).catch((error) => {
                            let applicationLog: IApplicationLog = {
                                Title: "UpdateFeedbackInfo",
                                LogType: "Error",
                                Location: "SPFx | UpdateFeedbackInfo | SpeakerInfoFromService",
                                LogDetails: error.error
                            }
                            APIHelper.createApplicationLog(applicationLog);
                        });
                    }).catch((error) => {
                        let applicationLog: IApplicationLog = {
                            Title: "UpdateFeedbackInfo",
                            LogType: "Error",
                            Location: "SPFx | UpdateFeedbackInfo | SpeakerInfoFromService",
                            LogDetails: error.error
                        }
                        APIHelper.createApplicationLog(applicationLog);
                    });
                }
                else {
                    const sendEmail = APIHelper.sendEmail(RequestStatus.CustomerFeedbackReceived, this.speakerInfoFormState.speakerInfoItem);
                    Promise.all([sendEmail]).then((sendEmailData: any) => {
                        this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
                            ...prevState,
                            isRequestSubmitted: true,
                            spinner: {
                                show: false,
                                text: ''
                            }
                        }));
                    });
                }
            }
            else {
                this.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
                    ...prevState,
                    isRequestSubmitted: false,
                    spinner: {
                        show: false,
                        text: ''
                    }
                }));
            }
        }).catch((error) => {
            let applicationLog: IApplicationLog = {
                Title: "UpdateFeedbackInfo",
                LogType: "Error",
                Location: "SPFx | UpdateFeedbackInfo | SpeakerInfoFromService",
                LogDetails: error.error
            }
            APIHelper.createApplicationLog(applicationLog);
        });
    }

    public GetAuditLog(action: string, actionType: string): string {
        let auditLogArr: IAuditLog[] = [];
        let auditLog: IAuditLog = {
            Action: action,
            ActionType: actionType,
            TimeStamp: new Date().toJSON(),
            UserName: this.appContext.state.user.username,
            UserEmail: this.appContext.state.user.email,
            Comment: ""
        }
        let pastAuditLogs: string = this.speakerInfoFormState?.speakerInfoItem?.AuditLog;
        if (pastAuditLogs) {
            auditLogArr = JSON.parse(pastAuditLogs);
        }
        auditLogArr.push(auditLog);

        return JSON.stringify(auditLogArr);
    }

    public async DownloadFile(id: string): Promise<string> {
        try {
            const downloadLink: string = await APIHelper.getSpeakerDocumentDownloadLink(id);
            return downloadLink;
        } catch (error) {
            let applicationLog: IApplicationLog = {
                Title: "DownloadFile",
                LogType: "Error",
                Location: "Client App | DownloadFile | SpeakerInfoFromService",
                LogDetails: error.error
            }
            APIHelper.createApplicationLog(applicationLog);

            return "";
        }
    }
}