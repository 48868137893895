import { Dialog, DialogType, Spinner, SpinnerSize } from "@fluentui/react";
import Style from "./Spinner.module.scss";
import * as React from "react";

export interface ISpinnerProps {
	show: boolean;
	text: string;
}

const SpinnerComponent = (props: ISpinnerProps) => {
	return (
		<Dialog
			hidden={!props.show}
			dialogContentProps={{
				type: DialogType.normal,
				showCloseButton: false,
			}}
			modalProps={{
				isBlocking: true,
				className: Style.wait,
			}}
		>
			<Spinner size={SpinnerSize.large} label={props.text} ariaLive="assertive" />
		</Dialog>
	);
};

export default React.memo(SpinnerComponent);
