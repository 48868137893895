import { CommonService } from '../../../services';
import { ISpeakerLevelBIOTopicsState } from "./ISpeakerLevelBIOTopicsState";
import { IChoiceGroupOption } from '@fluentui/react';
import { IApplicationLog, ICheckBoxChoices, IDictionary } from '../../../interfaces';
import { Brand } from '../../../constant';
import { SpeakerInfoFormContext } from '../../../components/context';
import { useContext } from 'react';
import { APIHelper } from '../../../helpers/APIHelper';

export class SpeakerLevelBIOTopicsService {
    private commonService: any;
    private setSpeakerLevelBIOTopicsState: any;
    private speakerLevelBIOTopicsState: any;
    private speakerInfoFormContext: any;
    constructor(speakerLevelBIOTopicsState:any,setSpeakerLevelBIOTopicsState: any) {
        this.speakerInfoFormContext = useContext(SpeakerInfoFormContext);
        this.commonService = new CommonService();
        this.speakerLevelBIOTopicsState = speakerLevelBIOTopicsState;
        this.setSpeakerLevelBIOTopicsState = setSpeakerLevelBIOTopicsState;
    }

    public InitSpeakerLevelBIOTopics(): void {
        try {
            let foundationImplantDentistry:string = this.speakerLevelBIOTopicsState.foundationImplantDentistry ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.FoundationImplantDentistry;

            let interestedInITIStudyClubs:string = this.speakerLevelBIOTopicsState.interestedInITIStudyClubs ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.InterestedInITIStudyClubs;

            let itiStudyClubInfoAgreement:string = this.speakerLevelBIOTopicsState.itiStudyClubInfoAgreement ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.ITIStudyClubInfoAgreement;

            let speakerBIO:string = this.speakerLevelBIOTopicsState.speakerBIO ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.SpeakerBIO;

            let treatmentPlanning:string = this.speakerLevelBIOTopicsState.treatmentPlanning ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.TreatmentPlanning;

            let surgicalProcsImplantDentistry:string = this.speakerLevelBIOTopicsState.surgicalProcsImplantDentistry ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.SurgicalProcsImplantDentistry;

            let prostheticProcsImplantDentistry:string = this.speakerLevelBIOTopicsState.prostheticProcsImplantDentistry ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.ProstheticProcsImplantDentistry;

            let laboratoryProcedures:string = this.speakerLevelBIOTopicsState.laboratoryProcedures ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.LaboratoryProcedures;

            let digitalDentistry:string = this.speakerLevelBIOTopicsState.digitalDentistry ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.DigitalDentistry;

            let regenerative:string = this.speakerLevelBIOTopicsState.regenerative ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.Regenerative;

            let practiceManagement:string = this.speakerLevelBIOTopicsState.practiceManagement ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.PracticeManagement;

            let hygiene:string = this.speakerLevelBIOTopicsState.hygiene ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.Hygiene;

            let productPortfolio:string = this.speakerLevelBIOTopicsState.productPortfolio ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.ProductPortfolio;

            let surgicalProductPortfolio:string = this.speakerLevelBIOTopicsState.surgicalProductPortfolio ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.SurgicalProductPortfolio;

            let restorativeProductPortfolio:string = this.speakerLevelBIOTopicsState.restorativeProductPortfolio ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.RestorativeProductPortfolio;

            let biomaterialsProductPortfolio:string = this.speakerLevelBIOTopicsState.biomaterialsProductPortfolio ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.BiomaterialsProductPortfolio;

            let digitalWorkflowPortfolio:string = this.speakerLevelBIOTopicsState.digitalWorkflowPortfolio ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.DigitalWorkflowPortfolio;

            let levelForumSpeaking:string = this.speakerLevelBIOTopicsState.levelForumSpeaking ?? this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.LevelForumSpeaking;
    
            let errorMessagesForFormFields : IDictionary<string> = {};

            if(this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState){
                errorMessagesForFormFields = this.speakerInfoFormContext.speakerInfoFormState?.errorMessagesForFormFields;

                foundationImplantDentistry = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.foundationImplantDentistry;

                interestedInITIStudyClubs = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.interestedInITIStudyClubs;

                itiStudyClubInfoAgreement = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.itiStudyClubInfoAgreement;

                speakerBIO = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.speakerBIO;

                treatmentPlanning = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.treatmentPlanning;

                surgicalProcsImplantDentistry = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.surgicalProcsImplantDentistry;

                prostheticProcsImplantDentistry = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.prostheticProcsImplantDentistry;

                laboratoryProcedures = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.laboratoryProcedures;

                digitalDentistry = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.digitalDentistry;

                regenerative = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.regenerative;

                practiceManagement = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.practiceManagement;

                hygiene = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.hygiene;

                productPortfolio = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.productPortfolio;

                surgicalProductPortfolio = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.surgicalProductPortfolio;

                restorativeProductPortfolio = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.restorativeProductPortfolio;

                biomaterialsProductPortfolio = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.biomaterialsProductPortfolio;

                digitalWorkflowPortfolio = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.digitalWorkflowPortfolio;

                levelForumSpeaking = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState.levelForumSpeaking;

                errorMessagesForFormFields = this.speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState?.errorMessagesForFormFields
            }

            let levelForumSpeakingOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", "LevelForumSpeaking");
            levelForumSpeakingOptions = this.getCheckBoxChoiceField(levelForumSpeakingOptions, levelForumSpeaking);

            let foundationImplantDentistryOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", "FoundationImplantDentistry");
            foundationImplantDentistryOptions = this.getCheckBoxChoiceField(foundationImplantDentistryOptions, foundationImplantDentistry);

            let interestedInITIStudyClubsOptions: IChoiceGroupOption[] = this.commonService.getChoiceFieldChoicesForAFormAndField("SpeakerInfoForm", "InterestedInITIStudyClubs");

            let itiStudyClubInfoAgreementOptions: IChoiceGroupOption[] = this.commonService.getChoiceFieldChoicesForAFormAndField("SpeakerInfoForm", "ITIStudyClubInfoAgreement");

            let treatmentPlanningOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", "TreatmentPlanning");
            treatmentPlanningOptions = this.getCheckBoxChoiceField(treatmentPlanningOptions, treatmentPlanning);

            let surgicalProcsImplantDentistryOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", "SurgicalProcsImplantDentistry");
            surgicalProcsImplantDentistryOptions = this.getCheckBoxChoiceField(surgicalProcsImplantDentistryOptions, surgicalProcsImplantDentistry);

            let prostheticProcsImplantDentistryOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", "ProstheticProcsImplantDentistry");
            prostheticProcsImplantDentistryOptions = this.getCheckBoxChoiceField(prostheticProcsImplantDentistryOptions, prostheticProcsImplantDentistry);

            let laboratoryProceduresOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem.Brand === Brand.Straumann ? "StraumannLaboratoryProcedures" : "NeodentLaboratoryProcedures");
            laboratoryProceduresOptions = this.getCheckBoxChoiceField(laboratoryProceduresOptions, laboratoryProcedures);

            let digitalDentistryOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem.Brand === Brand.Straumann ? "StraumannDigitalDentistry" : "NeodentDigitalDentistry");
            digitalDentistryOptions = this.getCheckBoxChoiceField(digitalDentistryOptions, digitalDentistry);

            let practiceManagementOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem.Brand === Brand.Straumann ? "StraumannPracticeManagement" : "NeodentPracticeManagement");
            practiceManagementOptions = this.getCheckBoxChoiceField(practiceManagementOptions, practiceManagement);

            let productPortfolioOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem.Brand === Brand.Straumann ? "StraumannProductPortfolio" : "NeodentProductPortfolio");
            productPortfolioOptions = this.getCheckBoxChoiceField(productPortfolioOptions, productPortfolio);

            let surgicalProductPortfolioOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", "SurgicalProductPortfolio");
            surgicalProductPortfolioOptions = this.getCheckBoxChoiceField(surgicalProductPortfolioOptions, surgicalProductPortfolio);

            let restorativeProductPortfolioOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", "RestorativeProductPortfolio");
            restorativeProductPortfolioOptions = this.getCheckBoxChoiceField(restorativeProductPortfolioOptions, restorativeProductPortfolio);

            let biomaterialsProductPortfolioOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", "BiomaterialsProductPortfolio");
            biomaterialsProductPortfolioOptions = this.getCheckBoxChoiceField(biomaterialsProductPortfolioOptions, biomaterialsProductPortfolio);

            let digitalWorkflowPortfolioOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", "DigitalWorkflowPortfolio");
            digitalWorkflowPortfolioOptions = this.getCheckBoxChoiceField(digitalWorkflowPortfolioOptions, digitalWorkflowPortfolio);

            let regenerativeOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", "Regenerative");
            regenerativeOptions = this.getCheckBoxChoiceField(regenerativeOptions, regenerative);

            let hygieneOptions: ICheckBoxChoices[] = this.commonService.getCheckBoxFieldChoicesForAFormAndField("SpeakerInfoForm", "Hygiene");
            hygieneOptions = this.getCheckBoxChoiceField(hygieneOptions, hygiene);

            this.setSpeakerLevelBIOTopicsState((prevState: ISpeakerLevelBIOTopicsState) => ({
                ...prevState,
                levelForumSpeakingOptions: levelForumSpeakingOptions,
                levelForumSpeaking: levelForumSpeaking,
                foundationImplantDentistryOptions: foundationImplantDentistryOptions,
                foundationImplantDentistry: foundationImplantDentistry,
                interestedInITIStudyClubsOptions: interestedInITIStudyClubsOptions,
                interestedInITIStudyClubs: interestedInITIStudyClubs,
                treatmentPlanningOptions: treatmentPlanningOptions,
                treatmentPlanning: treatmentPlanning,
                surgicalProcsImplantDentistryOptions: surgicalProcsImplantDentistryOptions,
                surgicalProcsImplantDentistry: surgicalProcsImplantDentistry,
                prostheticProcsImplantDentistryOptions: prostheticProcsImplantDentistryOptions,
                prostheticProcsImplantDentistry: prostheticProcsImplantDentistry,
                laboratoryProceduresOptions: laboratoryProceduresOptions,
                laboratoryProcedures: laboratoryProcedures,
                digitalDentistryOptions: digitalDentistryOptions,
                digitalDentistry: digitalDentistry,
                regenerativeOptions: regenerativeOptions,
                regenerative: regenerative,
                practiceManagementOptions: practiceManagementOptions,
                practiceManagement: practiceManagement,
                hygieneOptions: hygieneOptions,
                hygiene:hygiene,
                productPortfolioOptions: productPortfolioOptions,
                productPortfolio: productPortfolio,
                speakerBIO: speakerBIO,
                itiStudyClubInfoAgreementOptions: itiStudyClubInfoAgreementOptions,
                itiStudyClubInfoAgreement: itiStudyClubInfoAgreement,
                surgicalProductPortfolioOptions : surgicalProductPortfolioOptions,
                surgicalProductPortfolio : surgicalProductPortfolio,
                restorativeProductPortfolioOptions : restorativeProductPortfolioOptions,
                restorativeProductPortfolio : restorativeProductPortfolio,
                biomaterialsProductPortfolioOptions : biomaterialsProductPortfolioOptions,
                biomaterialsProductPortfolio : biomaterialsProductPortfolio,
                digitalWorkflowPortfolioOptions : digitalWorkflowPortfolioOptions,
                digitalWorkflowPortfolio : digitalWorkflowPortfolio,
                errorMessagesForFormFields : errorMessagesForFormFields                    
            }));            
        }
        catch (error: any) {
            let applicationLog: IApplicationLog = {
                Title: "InitSpeakerLevelBIOTopics",
                LogType: "Error",
                Location: "SPFx | InitSpeakerLevelBIOTopics | SpeakerLevelBIOTopicsService",
                LogDetails: error.error
            }
            APIHelper.createApplicationLog(applicationLog);
        }
    }

    public getCheckBoxChoiceField(options: ICheckBoxChoices[], value: string): ICheckBoxChoices[] {
        if (value) {
            let choiceValues: any = value.split("\n");
            choiceValues.forEach((choice: string) => {
                options.forEach((choiceItem: ICheckBoxChoices) => {
                    if (choiceItem.text === choice)
                        choiceItem.checked = true;
                });
            });
        }
        return options;
    }

}