import { ISteps } from "../interfaces";

export enum ActionCommands {
	Approve = "Approve",
	Reject = "Reject",
	NeedMoreInformation = "Need More Information",	
}

export const Steps:ISteps[] = [
	{ key: 'welcome', label: 'Welcome', isDone: true },
	{ key: 'firstStep', label: 'Personal Information', isDone: false },
	{ key: 'secondStep', label: 'Contact Information', isDone: false },
	{ key: 'thirdStep', label: 'Speaker Level BIO Topics', isDone: false },
	{ key: 'finalStep', label: 'Questions And Required Documents', isDone: false },
]

export const AuditLogActionCommands = {
	Feedback : {
		Action : `AuditAction_FeedbackSubmitted`,
        ActionType : `SubmitFeedback`,
	},
	ProfileSubmitted : {
		Action : `AuditAction_ProfileSubmitted`,
        ActionType : `SubmitProfile`,
	},		
}