import { UserRole } from "./UserRole";

export enum Groups{
    Admin = "Admin",
    USEducationEventSpeakerOwners = "US Education Event Speaker Owners",
    EducationTeam = "Education Team",
    EducationTeamVisitor = "Education Team Visitor",
}

export const GroupsValues:any = [
    {key:UserRole.Admin, groupname:Groups.Admin},
    {key:UserRole.EducationTeam, groupname:Groups.EducationTeam},
    {key:UserRole.EducationTeamVisitor, groupname:Groups.EducationTeamVisitor}
];