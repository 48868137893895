// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EmailAddressLoginComponent__emailField__NGiBi{padding-top:20px}.EmailAddressLoginComponent__loginBtn__MPDNN{width:100%;padding-bottom:20px;margin-top:20px}.EmailAddressLoginComponent__lbl__EyK6s{margin-top:50px;margin-bottom:10px}.EmailAddressLoginComponent__row__PZn76{width:100%}@media screen and (max-width: 768px){.EmailAddressLoginComponent__row__PZn76{display:flex;flex-direction:column}.EmailAddressLoginComponent__column__hlR0G{display:flex;width:100%;flex-direction:column}.EmailAddressLoginComponent__divComponent__mBJlc{width:100%}}`, "",{"version":3,"sources":["webpack://./src/client/components/Login/EmailAddressLoginComponent.scss"],"names":[],"mappings":"AAAA,+CACE,gBAAA,CAGF,6CACE,UAAA,CACA,mBAAA,CACA,eAAA,CAGF,wCACE,eAAA,CACA,kBAAA,CAGF,wCACE,UAAA,CAGF,qCACE,wCACE,YAAA,CACA,qBAAA,CAGF,2CACE,YAAA,CACA,UAAA,CACA,qBAAA,CAGF,iDACE,UAAA,CAAA","sourcesContent":[".emailField {\r\n  padding-top: 20px;\r\n}\r\n\r\n.loginBtn {\r\n  width: 100%;\r\n  padding-bottom: 20px;\r\n  margin-top: 20px;\r\n}\r\n\r\n.lbl {\r\n  margin-top: 50px;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.row {\r\n  width: 100%;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .row {\r\n    display: flex;\r\n    flex-direction: column;\r\n  }\r\n\r\n  .column {\r\n    display: flex;\r\n    width: 100%;\r\n    flex-direction: column;\r\n  }\r\n\r\n  .divComponent {\r\n    width: 100%\r\n  }\r\n}\r\n\r\n// @media screen and (max-width: 480px) {\r\n//   .row {\r\n//     display: flex;\r\n//     flex-direction: column;\r\n//   }\r\n\r\n//   .column {\r\n//     display: flex;\r\n//     width: 100%;\r\n//     flex-direction: column;\r\n//   }\r\n\r\n//   .divComponent {\r\n//     width: 100%\r\n//   }\r\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emailField": `EmailAddressLoginComponent__emailField__NGiBi`,
	"loginBtn": `EmailAddressLoginComponent__loginBtn__MPDNN`,
	"lbl": `EmailAddressLoginComponent__lbl__EyK6s`,
	"row": `EmailAddressLoginComponent__row__PZn76`,
	"column": `EmailAddressLoginComponent__column__hlR0G`,
	"divComponent": `EmailAddressLoginComponent__divComponent__mBJlc`
};
export default ___CSS_LOADER_EXPORT___;
