import * as React from 'react';
import { User } from '../../interfaces/User.interface';
import { useCallback, useEffect, useRef, useState, useContext } from 'react';
import { AuthenticationProvider } from './AuthenticationProvider.';
import { jwtDecode } from 'jwt-decode';
import { APIHelper } from '../../helpers/APIHelper';
import GoogleLoginComponent from './GoogleLoginComponent';
import MicrosoftLoginComponent from './MicrosoftLoginComponent';
import EmailAddressLoginComponent from './EmailAddressLoginComponent';
import ReCAPTCHA from 'react-google-recaptcha';
import { Stack, Label, Dialog, DialogType, MessageBar, MessageBarType, } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useLocation } from 'react-router-dom';
import SpinnerComponent from "../../components/Spinner/Spinner";
import { AppContext } from '../../components/context';
import EmailAddressLoginComponentStyles from './EmailAddressLoginComponent.scss';
import LoginStyles from './Login.scss';

export interface LoginProps {
    user: User | null;
    onSignIn: (user: User, jwt: string) => void;
    onSignOut: () => void;
}

const Login = (props: LoginProps): React.JSX.Element => {
    const appContext: any = useContext(AppContext);
    const [open, setOpen] = useState<boolean>(false);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [provider, setProvider] = useState<string | null>(null);
    const [providerData, setProviderData] = useState<any>(null);
    const [jwt, setJwt] = useState<string | null>(null);
    const [logout, setLogout] = useState<boolean>(false);
    const [inOverlay, setInOverlay] = useState<boolean>(false);
    const [reCaptchaToken, setReCaptchaToken] = useState<string>("");
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [invCode, setInvCode] = useState<string>("");
    const [signInError, setSignInError] = useState<boolean>(false);
    const [signInErrorMessage, setSignInErrorMessage] = useState<string | null>(null);
    const currentLocation = useLocation();
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(true);

    const refPersonButton: React.RefObject<HTMLButtonElement> = React.createRef<HTMLButtonElement>();
    const refReCaptcha: React.RefObject<ReCAPTCHA> = useRef<ReCAPTCHA>(null);

    let userDetails: User = {
        id: "",
        username: "",
        fullName: "",
        email: "",
        groups: []
    };

    const processAuthAnswer = (authToken: string, provider: AuthenticationProvider, providerData: any) => {
        try {
            dismissPanel();
            console.log(appContext.state.applicationLables.Label_DecodingCookie);
            const decoded = jwtDecode(authToken) as any;

            const user: User = {
                id: decoded.userId,
                username: decoded.username,
                fullName: decoded.fullName,
                email: decoded.email,
                groups: decoded.groups
            };
            console.log("JWT Cookie data", decoded);
            console.log("User from Cookie", user);

            props.onSignIn(user, authToken);
            setOpen(false);

            setTimeout(() => {
                setLoggedIn(true);
                setJwt(authToken);
                setProvider(provider);
                setProviderData(providerData);
                setIsLoggingIn(false);
            }, 300);

            sessionStorage.setItem("at", authToken);

            userDetails = user;

        } catch (decodeError) {
            console.error(appContext.state.applicationLables.Label_AuthTokenCouldnotBeProcessed);
        }
    };

    const onSuccessfulLogin = (provider: AuthenticationProvider, token: string, providerData?: any): void => {
        processAuthAnswer(token, provider, providerData);
    };

    const onLogout = async (): Promise<void> => {
        APIHelper.logout(jwt as string, provider as AuthenticationProvider, providerData);
        sessionStorage.removeItem("at");
        props.onSignOut();
        //setUser(null);
        setOpen(false);
        openPanel();
        setTimeout(() => {
            setLoggedIn(false);
        }, 300);

    };

    const onHideLoginOverlay = (ev: Event): void => {
        const targetId: string = (ev.target as HTMLElement).id;

        if (targetId === "username" || targetId === "password" || inOverlay) {
            ev.preventDefault();
            ev.stopPropagation();
            return;
        }

        setOpen(false);
    };

    const onReCaptchaChange = useCallback((token: string | null) => {
        setReCaptchaToken(token ?? "");
    }, [setReCaptchaToken]);

    const showSpinner = useCallback((value: boolean) => {
        setIsLoggingIn(value);
    }, [setIsLoggingIn]);

    const showSignInError = useCallback((msg: string) => {
        setSignInErrorMessage(msg);
        setSignInError(true);
    }, []);

    /**
         * Check if the user is still signed in with a auth cookie
         */
    useEffect(() => {
        const authCheck = async () => {
            try {
                const signedInResponse = await APIHelper.authCheck().catch((ex) => { });
                if (signedInResponse) {
                    processAuthAnswer(signedInResponse.data, AuthenticationProvider.Email, null);
                }
            } catch (err) {
                // user is not authenticated yet
            }
        };
        authCheck();

        refReCaptcha.current?.execute();

    }, []);

    useEffect(() => {
        const params = new URLSearchParams(currentLocation.search);
        const code: string | null = params.get("invCode");
        setInvCode(code ? code : "");
    }, [currentLocation]);

    const toggleHideDialog = () =>{
        setSignInError(false);
    }

    return (
        <div>
            <div className={LoginStyles.loginComponent}>
                <Stack>
                    <Dialog
                        hidden={!isOpen}
                        minWidth={window.innerWidth <= 480 ? "95%": '450px'}
                        maxWidth={'95%'}
                        dialogContentProps={{
                            type: DialogType.largeHeader,
                            title: 'Login',
                            showCloseButton: false,
                        }}
                        modalProps={{
                            isBlocking: true
                        }}
                    >
                        <div className={EmailAddressLoginComponentStyles.divComponent}>
                            <div id="divAuthComponents" style={{ display: loggedIn ? 'none' : 'block' }}>
                                { signInErrorMessage && <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                dismissButtonAriaLabel="Close"
                                >{signInErrorMessage}</MessageBar>  
                                }   
                                <EmailAddressLoginComponent onSuccessfulLogin={onSuccessfulLogin} showSpinner={showSpinner} invCode={invCode} reCaptchaToken={reCaptchaToken} onError={showSignInError} />
                                <Stack className={EmailAddressLoginComponentStyles.lbl}>
                                    <Label styles={{ root: { fontSize: '16px' } }}>{appContext.state.applicationLables.Label_SignInWithYourSocialAccount}</Label>
                                </Stack>
                                <GoogleLoginComponent onSuccessfulLogin={onSuccessfulLogin} showSpinner={showSpinner} invCode={invCode} reCaptchaToken={reCaptchaToken} onError={showSignInError} />
                                <MicrosoftLoginComponent onSuccessfulLogin={onSuccessfulLogin} showSpinner={showSpinner} invCode={invCode} reCaptchaToken={reCaptchaToken} onError={showSignInError} />
                            </div>
                        </div>
                    </Dialog>                    
                </Stack>
                <SpinnerComponent show={isLoggingIn} text={`Signing in...`} />                            
            </div>
            <ReCAPTCHA
                style={{ position: 'absolute' }}
                ref={refReCaptcha}
                sitekey={process.env.RECAPTCHA_SITE_KEY!}
                size="invisible"
                onChange={onReCaptchaChange}
            />
        </div>
    );
};

export default Login;