// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"straumannBlueColor": `#195afa`,
	"straumannBlueHoverAltColor": `#537af3`,
	"straumannBlueHoverColor": `#537af3`,
	"straumannBlueLightColor": `#a8bbf8`,
	"straumannBlueLighterColor": `#d3defa`,
	"straumannBlueLightestColor": `#e9eefe`,
	"straumannBlackColor": `#000`,
	"straumannLightBlackColor": `#36393a`,
	"straumannLightGreyColor": `#afb1b1`,
	"straumannLighterGreyColor": `#d7d7d8`,
	"straumannLightestGreyColor": `#ebebeb`,
	"straumannDarkGreyColor": `#858889`,
	"straumannErrorColor": `#a4262c`,
	"straumannInputBorderHovered": `#323130`,
	"straumannInputFocusBorderAlt": `#323130`,
	"straumannFocusBorder": `#323130`,
	"straumannGreenColor": `#46b98c`,
	"straumannSandColor": `#b98c3c`,
	"straumannSandHoverAltColor": `#c2995e`
};
export default ___CSS_LOADER_EXPORT___;
