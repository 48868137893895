import { CommonService } from '../../../services';
import { IPersonalInformationState } from "./IPersonalInformationState";
import { IChoiceGroupOption, IDropdownOption } from '@fluentui/react';
import { SpeakerInfoFormContext } from '../../../components/context';
import { useContext } from 'react';
import { IApplicationLog } from '../../../interfaces';
import { APIHelper } from '../../../helpers/APIHelper';

export class PersonalInformationService {
    private commonService: any;
    private setPersonalInformationState: any;
    private speakerInfoFormContext: any;
    constructor(personalInformationState:any,setPersonalInformationState: any) {
        this.speakerInfoFormContext = useContext(SpeakerInfoFormContext);
        this.commonService = new CommonService();
        this.setPersonalInformationState = setPersonalInformationState;
    }

    public InitPersonalInformation(): void {
        try {
            let genderOptions: IDropdownOption[] = this.commonService.getChoiceFieldChoicesForAFormAndField("SpeakerInfoForm", "Gender");
            let membershipTypeOptions: IChoiceGroupOption[] = this.commonService.getChoiceFieldChoicesForAFormAndField("SpeakerInfoForm", "MembershipType");
            let professionOptions: IDropdownOption[] = this.commonService.getChoiceFieldChoicesForAFormAndField("SpeakerInfoForm", "Profession");
                
            if(!this.speakerInfoFormContext.speakerInfoFormState.personalInformationState){     
                
                this.setPersonalInformationState((prevState: IPersonalInformationState) => ({
                    ...prevState,
                    genderOptions: genderOptions,
                    membershipTypeOptions: membershipTypeOptions,
                    professionOptions: professionOptions,
                    firstName: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.FirstName,
                    lastName: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.LastName,
                    eMail: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.Email,
                    brand: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.Brand,
                    gender: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.Gender,
                    territoryManager: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.TerritoryManager,
                    labName: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.LabName,
                    customerAccount: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.CustomerAccount,
                    profession: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.Profession,
                    membershipType: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.MembershipType,
                }));        
            } 
            else{
                this.setPersonalInformationState((prevState: IPersonalInformationState) => ({
                    ...prevState,
                    genderOptions: genderOptions,
                    membershipTypeOptions: membershipTypeOptions,
                    professionOptions: professionOptions,
                    firstName: this.speakerInfoFormContext.speakerInfoFormState.personalInformationState?.firstName,
                    lastName: this.speakerInfoFormContext.speakerInfoFormState.personalInformationState?.lastName,
                    eMail: this.speakerInfoFormContext.speakerInfoFormState.personalInformationState?.eMail,
                    brand: this.speakerInfoFormContext.speakerInfoFormState.personalInformationState?.brand,
                    gender: this.speakerInfoFormContext.speakerInfoFormState.personalInformationState?.gender,
                    territoryManager: this.speakerInfoFormContext.speakerInfoFormState.personalInformationState?.territoryManager,
                    labName: this.speakerInfoFormContext.speakerInfoFormState.personalInformationState?.labName,
                    customerAccount: this.speakerInfoFormContext.speakerInfoFormState.personalInformationState?.customerAccount,
                    profession: this.speakerInfoFormContext.speakerInfoFormState.personalInformationState?.profession,
                    membershipType: this.speakerInfoFormContext.speakerInfoFormState.personalInformationState?.membershipType,
                    errorMessagesForFormFields : this.speakerInfoFormContext.speakerInfoFormState.personalInformationState?.errorMessagesForFormFields
                }));    
            }  
        }
        catch (error: any) {
            let applicationLog: IApplicationLog = {
                Title: "InitPersonalInformation",
                LogType: "Error",
                Location: "SPFx | InitPersonalInformation | PersonalInformationService",
                LogDetails: error.error
            }
            APIHelper.createApplicationLog(applicationLog);
        }
    }    

}