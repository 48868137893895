import * as React from 'react';
import { SpeakerInfoFormContext } from '../../context';
import { useContext } from 'react';
import styles from '../../../scss/EventSpeakerForm.module.scss';
import { Stack } from '@fluentui/react';
export interface IHeaderProps {
}

export interface IHeaderState {
}

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
    const speakerInfoFormContext: any = useContext(SpeakerInfoFormContext);
    return (
        <>
        <div className={styles.topInstrunction}>
            <Stack className={`${styles.stack}`}>
                <div dangerouslySetInnerHTML={{ __html: speakerInfoFormContext.speakerInfoFormState.applicationText.Header }} />
            </Stack>
        </div>
        </>
    );
}

export default Header;