export enum RequestStatus {
    SentToDoctor = "Sent to Doctor",
    AwaitingApproval = "Awaiting Approval",
    Approved = "Approved",
    Rejected = "Rejected",
    InActive = "Inactive",
    MoreInformationRequested = "More Information Requested",
    CustomerProfileSubmitted = "Customer Profile Submitted",
    CustomerFeedbackReceived = "Customer Feedback Received",  
    Delegated = "Delegated"  
}