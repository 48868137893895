import msLogo from './ms_logo.png';
import padlock from './padlock.png';
import resizeHandle from "./handle-corner.svg";
import bannerlogo from './bannerlogo.png';

export abstract class AssetManager{
    public static get padlock():string{
        return padlock;
    }    

    public static get msLogo():string{
        return msLogo;
    }    

    public static get resizeHandle():string{
        return resizeHandle;
    } 

    public static get bannerlogo():string{
        return bannerlogo;
    }  
}