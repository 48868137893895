import * as React from 'react';
import SpinnerComponent from "../../components/Spinner/Spinner";
import { ISpeakerInfoFormState } from "./ISpeakerInfoFormState";
import { ISpeakerInfoFormProps } from "./ISpeakerInfoFormProps";
import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { SpeakerInfoFormContext, SpeakerInfoFormContextType } from '../../components/context';
import { SpeakerInfoFormService } from './SpeakerInfoFormService';
import PersonalInformation, { PersonalInformationRef } from './PersonalInformation/PersonalInformation';
import ContactInformation, { ContactInformationRef } from './ContactInformation/ContactInformation';
import SpeakerLevelBIOTopics, { SpeakerLevelBIOTopicsRef } from './SpeakerLevelBIOTopics/SpeakerLevelBIOTopics';
import QuestionsAndRequiredDocuments, { QuestionsAndRequiredDocumentsRef } from './QuestionsAndRequiredDocuments/QuestionsAndRequiredDocuments';
import SendBack, { SendBackRef } from './SendBack/SendBack';
import { Message } from '../Message/Message';
import strings from '../../loc/strings';
import StepWizard from 'react-step-wizard';
import stepWizardStyles from '../../scss/stepwizard.module.scss';
import { Stack, PrimaryButton, css } from '@fluentui/react';
import { Brand, ClearCorrectSpeakerLevelBIOTopicsFields, NeodentSpeakerLevelBIOTopicsFields, OtherBrandContactInformationFields, PersonalInformationFields, Steps, StraumannContactInformationFields, StraumannSpeakerLevelBIOTopicsFields, NeodentQuestionsAndRequiredDocumentsFields, StraumannQuestionsAndRequiredDocumentsFields, ClearCorrectQuestionsAndRequiredDocumentsFields, RequestStatus, SnedBackFields, StraumannProductPortfolio, MembershipType } from '../../constant';
import Header from './FormText/Header';
import { ICheckBoxChoices, IFormFields } from '../../interfaces';
import GlobalContext from '../../GlobalContext';
import { MessageDialog } from '../MessageDialog/MessageDialog';

const SpeakerInfoForm: React.FC<ISpeakerInfoFormProps> = (props: ISpeakerInfoFormProps) => {
    const globalContext = useContext(GlobalContext);
    const [speakerInfoFormState, setSpeakerInfoFormState] = useState<ISpeakerInfoFormState>({
        spinner: {
            show: false,
            text: "",
        },
        brand: "",
        errorMessagesForFormFields: {},
        applicationLables: strings,
        steps: Steps,
        activeStep: Steps[0],
        isRequestSubmitted: false,
        speakerEmail: globalContext?.email,
        isRequestSaved: false,
        showMessageDialog: true
    });
    const speakerInfoFormService = new SpeakerInfoFormService(speakerInfoFormState, setSpeakerInfoFormState);
    const [instance, updateInstance] = useState();
    const setInstance = (SW: any) => {
        updateInstance(SW);
    }

    // Create the ref
    const personalInformationRef = useRef<PersonalInformationRef>(null);
    const contactInformationRef = useRef<ContactInformationRef>(null);
    const speakerLevelBIOTopicsRef = useRef<SpeakerLevelBIOTopicsRef>(null);
    const questionsAndRequiredDocumentsRef = useRef<QuestionsAndRequiredDocumentsRef>(null);
    const sendBackRef = useRef<SendBackRef>(null);

    useEffect(() => {
        speakerInfoFormService.InitSpeakerInfoFrom();
    }, []);

    // const FormStep = (props) => <div className='ffff' {...props}>{props.children}</div>
    const FormStep = useCallback((props: any) => <div className={stepWizardStyles.formStep}>{props.children}</div>, [stepWizardStyles.formStep])

    const StepsHTML = ({ instance }: any) => (
        <Stack className={`${stepWizardStyles.box}`}>
            <div className={`${stepWizardStyles.btnstepwizard}`}>
                <div className={stepWizardStyles.steps}>
                    <ul>
                        {speakerInfoFormState.steps.map((step, i) => {
                            return <li key={i} className={`${speakerInfoFormState.activeStep?.key === step?.key ? stepWizardStyles.active : step?.isDone ? stepWizardStyles?.done : ''}`}>
                                <div className={stepWizardStyles.wizardTimeline}>
                                    {
                                        step?.key === `welcome` ?
                                            <span>{step?.label}</span>
                                            : <>
                                                <span>Step {i} </span>
                                                <span>{step?.label}</span>
                                            </>
                                    }
                                </div>
                            </li>
                        })}
                    </ul>
                </div>
            </div>
        </Stack>
    );

    const handleNext = (instance: any) => {
        setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
            ...prevState,
            spinner: {
                show: true,
                text: speakerInfoFormState.applicationLables.Label_Loading
            }
        }));

        switch (speakerInfoFormState.activeStep.key) {
            case "welcome":
                speakerInfoFormService.HandleNext(instance, null, undefined, null, "", speakerInfoFormState.appSettings!, true);
                break;
            case "firstStep":
                speakerInfoFormService.HandleNext(instance, personalInformationRef, personalInformationRef.current?.getFormData(), PersonalInformationFields, "personalInformationState", speakerInfoFormState.appSettings!);
                break;
            case "secondStep":
                {
                    const contactInformationState = contactInformationRef.current?.getFormData();
                    let contactInformationFields: IFormFields[] = [];
                    if (speakerInfoFormState.speakerInfoItem?.Brand !== Brand.Straumann) {
                        contactInformationFields = OtherBrandContactInformationFields;
                    }
                    else {
                        contactInformationFields = StraumannContactInformationFields;
                    }
                    if (contactInformationState) {
                        contactInformationFields = contactInformationFields.filter(field => {
                            if (field.field === "maorVTLicenseNumber") {
                                if (contactInformationState?.licensedMAOrVT !== "No") {
                                    field.isMandatory = true;
                                }
                                else {
                                    contactInformationState.maorVTLicenseNumber = "";
                                    field.isMandatory = false;
                                }
                            }
                            return field;
                        });
                    }
                    speakerInfoFormService.HandleNext(instance, contactInformationRef, contactInformationState, contactInformationFields, "contactInformationState", speakerInfoFormState.appSettings!);
                    break;
                }
            case "thirdStep":
                {
                    const speakerLevelBIOTopicsState = speakerLevelBIOTopicsRef.current?.getFormData();
                    let speakerLevelBIOTopicsFields: IFormFields[] = [];
                    if (speakerInfoFormState.speakerInfoItem?.Brand == Brand.ClearCorrect) {
                        speakerLevelBIOTopicsFields = ClearCorrectSpeakerLevelBIOTopicsFields;
                    }
                    else if (speakerInfoFormState.speakerInfoItem?.Brand == Brand.Neodent) {
                        speakerLevelBIOTopicsFields = NeodentSpeakerLevelBIOTopicsFields;
                    }
                    else {
                        speakerLevelBIOTopicsFields = StraumannSpeakerLevelBIOTopicsFields;
                    }
                    if (speakerLevelBIOTopicsState?.productPortfolio && speakerInfoFormState.brand === Brand.Straumann) {
                        speakerLevelBIOTopicsFields = speakerLevelBIOTopicsFields.filter(field => {
                            let productPortfolioValue: string | undefined = speakerLevelBIOTopicsState.productPortfolio ?? "";
                            if (field.field === "surgicalProductPortfolio") {
                                if (productPortfolioValue?.indexOf(StraumannProductPortfolio.Surgical) > -1) {
                                    field.isMandatory = true;
                                }
                                else {
                                    speakerLevelBIOTopicsState.surgicalProductPortfolio = "";
                                    field.isMandatory = false;
                                }
                            }
                            if (field.field === "restorativeProductPortfolio") {
                                if (productPortfolioValue?.indexOf(StraumannProductPortfolio.Restorative) > -1) {
                                    field.isMandatory = true;
                                }
                                else {
                                    speakerLevelBIOTopicsState.restorativeProductPortfolio = "";
                                    field.isMandatory = false;
                                }
                            }
                            if (field.field === "biomaterialsProductPortfolio") {
                                if (productPortfolioValue?.indexOf(StraumannProductPortfolio.Regenerative) > -1) {
                                    field.isMandatory = true;
                                }
                                else {
                                    speakerLevelBIOTopicsState.biomaterialsProductPortfolio = "";
                                    field.isMandatory = false;
                                }
                            }
                            if (field.field === "digitalWorkflowPortfolio") {
                                if (productPortfolioValue?.indexOf(StraumannProductPortfolio.CARES) > -1) {
                                    field.isMandatory = true;
                                }
                                else {
                                    speakerLevelBIOTopicsState.digitalWorkflowPortfolio = "";
                                    field.isMandatory = false;
                                }
                            }
                            return field;
                        });
                    }
                    speakerInfoFormService.HandleNext(instance, speakerLevelBIOTopicsRef, speakerLevelBIOTopicsState, speakerLevelBIOTopicsFields, "speakerLevelBIOTopicsState", speakerInfoFormState.appSettings!);
                    break;
                }
            case "finalStep":
                {
                    const questionsAndRequiredDocumentsState = questionsAndRequiredDocumentsRef.current?.getFormData();
                    let questionsAndRequiredDocumentsStateFields: IFormFields[] = [];
                    if (speakerInfoFormState.speakerInfoItem?.Brand == Brand.ClearCorrect) {
                        questionsAndRequiredDocumentsStateFields = ClearCorrectQuestionsAndRequiredDocumentsFields.map(x => ({ ...x }));
                    }
                    else if (speakerInfoFormState.speakerInfoItem?.Brand == Brand.Neodent) {
                        questionsAndRequiredDocumentsStateFields = NeodentQuestionsAndRequiredDocumentsFields.map(x => ({ ...x }));
                    }
                    else {
                        questionsAndRequiredDocumentsStateFields = StraumannQuestionsAndRequiredDocumentsFields.map(x => ({ ...x }));
                    }

                    questionsAndRequiredDocumentsStateFields = questionsAndRequiredDocumentsStateFields.filter(field => {
                        if (field.field === "vendorForm") {
                            if (questionsAndRequiredDocumentsState?.isVendorFormSubmitted) {
                                field.isMandatory = false;
                            }
                            else {
                                field.isMandatory = true;
                            }
                        }
                        if (field.field === "w9") {
                            if (speakerInfoFormState.personalInformationState?.membershipType === MembershipType.USSpeaker) {
                                field.isMandatory = true;
                            }
                            else {
                                field.isMandatory = false;
                            }
                        }

                        return field;
                    });

                    speakerInfoFormService.HandleNext(instance, questionsAndRequiredDocumentsRef, questionsAndRequiredDocumentsState, questionsAndRequiredDocumentsStateFields, "questionsAndRequiredDocumentsState", speakerInfoFormState.appSettings!);
                    break;
                }
        }

    }

    const saveSpeakerInfo = () => {
        setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
            ...prevState,
            spinner: {
                show: true,
                text: speakerInfoFormState.applicationLables.Label_Loading
            }
        }));

        const personalInformationState = personalInformationRef.current?.getFormData() ?? speakerInfoFormState.personalInformationState;
        const contactInformationState = contactInformationRef.current?.getFormData() ?? speakerInfoFormState.contactInformationState;
        const speakerLevelBIOTopicsState = speakerLevelBIOTopicsRef.current?.getFormData() ?? speakerInfoFormState.speakerLevelBIOTopicsState;
        const questionsAndRequiredDocumentsState = questionsAndRequiredDocumentsRef.current?.getFormData() ?? speakerInfoFormState.questionsAndRequiredDocumentsState;

        speakerInfoFormService.SaveSpeakerInformation(personalInformationState, contactInformationState, speakerLevelBIOTopicsState, questionsAndRequiredDocumentsState);
    }

    const handleBack = (instance: any) => {
        switch (speakerInfoFormState.activeStep.key) {
            case "firstStep":
                speakerInfoFormService.HandleBack(instance, personalInformationRef.current?.getFormData(), "personalInformationState");
                break;
            case "secondStep":
                speakerInfoFormService.HandleBack(instance, contactInformationRef.current?.getFormData(), "contactInformationState");
                break;
            case "thirdStep":
                speakerInfoFormService.HandleBack(instance, speakerLevelBIOTopicsRef.current?.getFormData(), "speakerLevelBIOTopicsState");
                break;
            case "finalStep":
                speakerInfoFormService.HandleBack(instance, questionsAndRequiredDocumentsRef.current?.getFormData(), "questionsAndRequiredDocumentsState");
                break;
        }
    }

    const submitMoreInformation = () => {
        setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
            ...prevState,
            spinner: {
                show: true,
                text: speakerInfoFormState.applicationLables.Label_Loading
            }
        }));
        const sendBackState = sendBackRef.current?.getFormData();
        let sendBackStateFields: IFormFields[] = SnedBackFields;
        speakerInfoFormService.SubmitMoreInformation(sendBackRef, sendBackState, sendBackStateFields, "sendBackState");
    }

    const customAnimationReactStep = {
        enterRight: 'animated fadeInRight',
        enterLeft: 'animated fadeInLeft',
        exitRight: 'animated fadeOutRight',
        exitLeft: 'animated fadeOutLeft',
        intro: 'animated intro'
    }

    const closeModel = () => {
        setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
            ...prevState,
            showMessageDialog: true
        }));
    }

    const downloadFile = React.useCallback(async (id: string): Promise<void> => {
        const link = await speakerInfoFormService.DownloadFile(id);
        if (link)
            window.open(link, '_blank');
    }, [])

    const speakerInfoContext: SpeakerInfoFormContextType = React.useMemo(() => {
        return {
            speakerInfoFormState,
            downloadFile,
            setSpeakerInfoFormState
        };
    }, [speakerInfoFormState, setSpeakerInfoFormState])

    return (
        <SpeakerInfoFormContext.Provider value={speakerInfoContext}>
            <SpinnerComponent show={speakerInfoFormState.spinner.show} text={speakerInfoFormState.spinner.text} />
            {(speakerInfoFormState.speakerInfoItem?.Status === RequestStatus.SentToDoctor ||
                speakerInfoFormState.speakerInfoItem?.IsRecertificationInProgress) && !speakerInfoFormState.isRequestSubmitted && !speakerInfoFormState.isRequestSaved &&
                <>
                    {
                        instance &&
                        <StepsHTML instance={instance} />
                    }
                    {
                        speakerInfoFormState.appSettings &&

                        <StepWizard transitions={customAnimationReactStep} className={stepWizardStyles.component} isLazyMount={true} instance={setInstance}>
                            <FormStep key={'step1'} stepName={'step1'}>
                                <Header key={'Header'} />
                            </FormStep>
                            <FormStep key={'step2'} stepName={'step2'}>
                                <PersonalInformation key={'PersonalInformation'} ref={personalInformationRef} />
                            </FormStep>
                            <FormStep key={'step3'} stepName={'step3'}>
                                <ContactInformation key={'ContactInformation'} ref={contactInformationRef} />
                            </FormStep>
                            <FormStep key={'step4'} stepName={'step4'}>
                                <SpeakerLevelBIOTopics key={'SpeakerLevelBIOTopics'} ref={speakerLevelBIOTopicsRef} />
                            </FormStep>
                            <FormStep key={'step5'} stepName={'step5'}>
                                <QuestionsAndRequiredDocuments key={'QuestionsAndRequiredDocuments'} ref={questionsAndRequiredDocumentsRef} />
                            </FormStep>
                        </StepWizard>
                    }
                    {
                        speakerInfoFormState.appSettings &&
                        <Stack className={css(stepWizardStyles.stackclass, stepWizardStyles.navigationButtonContainer)}>
                            {
                                speakerInfoFormState?.steps[0]?.key !== speakerInfoFormState.activeStep?.key &&
                                <PrimaryButton
                                    className={css(stepWizardStyles.btn, stepWizardStyles.previousBtn)}
                                    disabled={speakerInfoFormState.steps[0]?.key === speakerInfoFormState.activeStep?.key}
                                    onClick={() => handleBack(instance)}
                                >{speakerInfoFormState.applicationLables.Label_PreviousStep}</PrimaryButton>
                            }
                            {speakerInfoFormState?.steps[0]?.key !== speakerInfoFormState.activeStep?.key &&
                                <PrimaryButton
                                    className={css(stepWizardStyles.btn, stepWizardStyles.saveBtn)}
                                    onClick={() => saveSpeakerInfo()}
                                >{speakerInfoFormState.applicationLables.Label_Save}</PrimaryButton>
                            }
                            <PrimaryButton
                                className={css(stepWizardStyles.btn, stepWizardStyles.nextBtn)}
                                onClick={() => handleNext(instance)}
                            >{speakerInfoFormState.steps[speakerInfoFormState.steps.length - 1].key !== speakerInfoFormState.activeStep.key ? speakerInfoFormState.applicationLables.Label_Next : speakerInfoFormState.applicationLables.Label_Submit}</PrimaryButton>
                        </Stack>
                    }
                </>
            }
            {
                (speakerInfoFormState.speakerInfoItem?.Status === RequestStatus.MoreInformationRequested && !speakerInfoFormState.isRequestSubmitted) && <>
                    <SendBack
                        ref={sendBackRef}
                    />
                    <Stack className={css(stepWizardStyles.stackclass, stepWizardStyles.navigationButtonContainer)}>
                        <PrimaryButton className={stepWizardStyles.btn} onClick={submitMoreInformation}>{speakerInfoFormState.applicationLables.Label_Submit}</PrimaryButton>
                    </Stack>
                </>
            }
            {
                ((speakerInfoFormState.speakerInfoItem?.Status === RequestStatus.AwaitingApproval && !speakerInfoFormState.speakerInfoItem?.IsRecertificationInProgress) || speakerInfoFormState.isRequestSubmitted) &&
                <Message
                    message={speakerInfoFormState.isRequestSubmitted ? speakerInfoFormState.applicationLables.Message_SpeakereInfoSubmitted : speakerInfoFormState.applicationLables.Message_SpeakereInfoAlreadySubmitted}
                />
            }
            {
                speakerInfoFormState.isRequestSaved &&
                <Message
                    message={speakerInfoFormState.applicationLables.Message_SpeakereInfoSaved}
                />
            }
            {
                ((speakerInfoFormState.speakerInfoItem?.Status === RequestStatus.Approved && !speakerInfoFormState.speakerInfoItem?.IsRecertificationInProgress) || speakerInfoFormState.speakerInfoItem?.Status === RequestStatus.Rejected) &&
                <Message
                    message={speakerInfoFormState.speakerInfoItem?.Status === RequestStatus.Approved ? speakerInfoFormState.applicationLables.Message_SpeakereInfoApproved : speakerInfoFormState.applicationLables.Message_SpeakereInfoRejected}
                />
            }
            <MessageDialog
                subtext={speakerInfoFormState.applicationLables.Message_SpeakereInfoSaved}
                open={speakerInfoFormState.showMessageDialog}
                closeModal={closeModel}
            />
        </SpeakerInfoFormContext.Provider>
    );
}

export default SpeakerInfoForm;