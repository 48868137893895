
export enum ApplicationLogsListColumns {
    Title = "Title",
    Location = "Location",
    LogDetails = "LogDetails",
    LogType = "LogType"
}

export enum SettingsListColumns {
    Key = "Key",
    Value = "Value",    
}

export enum ChoiceEntriesListColumns {
    Title = "Title",
    ChoiceValues = "ChoiceValues",
    FieldName = "FieldName",
    Description = "Description"
}

export enum SpeakerInfoListColumns {
    Id = "Id",
    Status = "Status",
    FirstName = "FirstName",
    LastName = "LastName",
    Gender = "Gender",
    Email = "Email",
    MembershipType = "MembershipType",
    CustomerAccount = "CustomerAccount",
    Profession = "Profession",
    LabName = "LabName",
    TerritoryManager = "TerritoryManager",
    Country = "Country",
    AddressLine1 = "AddressLine1",
    AddressLine2 = "AddressLine2",
    City = "City",
    ZIPCode = "ZIPCode",
    WorkPhone = "WorkPhone",
    CellPhone = "CellPhone",
    PrimaryStateLicensure = "PrimaryStateLicensure",
    StateLicense = "StateLicense",
    LicensedMAOrVT = "LicensedMAOrVT",
    NationalProviderId = "NationalProviderId",
    LevelForumSpeaking = "LevelForumSpeaking",
    InterestedInITIStudyClubs = "InterestedInITIStudyClubs",
    SpeakerBIO = "SpeakerBIO",
    TreatmentPlanning = "TreatmentPlanning",
    FoundationImplantDentistry = "FoundationImplantDentistry",
    SurgicalProcsImplantDentistry = "SurgicalProcsImplantDentistry",
    ProstheticProcsImplantDentistry = "ProstheticProcsImplantDentistry",
    LaboratoryProcedures = "LaboratoryProcedures",
    DigitalDentistry = "DigitalDentistry",
    Regenerative = "Regenerative",
    PracticeManagement = "PracticeManagement",
    Hygiene = "Hygiene",
    ProductPortfolio = "ProductPortfolio",
    SpeakerNetworkAcknowledgment = "SpeakerNetworkAcknowledgment",
    ConductsWebinarPresentations = "ConductsWebinarPresentations",
    PermissionsToRecordWebinar = "PermissionsToRecordWebinar",
    TCAgreed = "TCAgreed",
    State = "State",
    Created = "Created"
}

export const SelectSpeakerInfoListColumns = [
    SpeakerInfoListColumns.Id ,
    SpeakerInfoListColumns.Status ,
    SpeakerInfoListColumns.FirstName ,
    SpeakerInfoListColumns.LastName ,
    SpeakerInfoListColumns.Gender ,
    SpeakerInfoListColumns.Email ,
    SpeakerInfoListColumns.MembershipType ,
    SpeakerInfoListColumns.CustomerAccount ,
    SpeakerInfoListColumns.Profession ,
    SpeakerInfoListColumns.LabName ,
    SpeakerInfoListColumns.TerritoryManager ,
    SpeakerInfoListColumns.Country ,
    SpeakerInfoListColumns.AddressLine1 ,
    SpeakerInfoListColumns.AddressLine2 ,
    SpeakerInfoListColumns.City ,
    SpeakerInfoListColumns.ZIPCode ,
    SpeakerInfoListColumns.WorkPhone ,
    SpeakerInfoListColumns.CellPhone ,
    SpeakerInfoListColumns.PrimaryStateLicensure ,
    SpeakerInfoListColumns.StateLicense ,
    SpeakerInfoListColumns.LicensedMAOrVT ,
    SpeakerInfoListColumns.NationalProviderId ,
    SpeakerInfoListColumns.LevelForumSpeaking ,
    SpeakerInfoListColumns.InterestedInITIStudyClubs ,
    SpeakerInfoListColumns.SpeakerBIO ,
    SpeakerInfoListColumns.TreatmentPlanning ,
    SpeakerInfoListColumns.FoundationImplantDentistry ,
    SpeakerInfoListColumns.SurgicalProcsImplantDentistry ,
    SpeakerInfoListColumns.ProstheticProcsImplantDentistry ,
    SpeakerInfoListColumns.LaboratoryProcedures ,
    SpeakerInfoListColumns.DigitalDentistry ,
    SpeakerInfoListColumns.Regenerative ,
    SpeakerInfoListColumns.PracticeManagement ,
    SpeakerInfoListColumns.Hygiene ,
    SpeakerInfoListColumns.ProductPortfolio ,
    SpeakerInfoListColumns.SpeakerNetworkAcknowledgment ,
    SpeakerInfoListColumns.ConductsWebinarPresentations ,
    SpeakerInfoListColumns.PermissionsToRecordWebinar ,
    SpeakerInfoListColumns.TCAgreed ,
    SpeakerInfoListColumns.State
]; 

