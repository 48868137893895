import * as React from 'react';
import { IPersonalInformationState } from './IPersonalInformationState';
import { useState, useContext, useEffect } from 'react';
import { SpeakerInfoFormContext } from '../../../components/context';
import { TextField, Stack, Dropdown, ChoiceGroup } from '@fluentui/react';
import styles from '../../../scss/EventSpeakerForm.module.scss';
import { PersonalInformationService } from './PersonalInformationService';
import Instructions from '../FormText/Instructions';
import { forwardRef, useImperativeHandle } from 'react';
import { IDictionary } from '../../../interfaces';

export interface IPersonalInformationProps {
}

export type PersonalInformationRef = {
    getFormData: () => IPersonalInformationState;
    updateValidationErrors: (errorMessagesForFormFields: IDictionary<string>) => void;
};

const PersonalInformation = forwardRef<PersonalInformationRef, IPersonalInformationProps>((props, ref) => {
    const speakerInfoFormContext: any = useContext(SpeakerInfoFormContext);

    const [personalInformationState, setPersonalInformationState] = useState<IPersonalInformationState>({
        membershipType: speakerInfoFormContext.speakerInfoFormState.personalInformationState?.membershipType ?? speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.MembershipType,
    });
    let personalInformationService = new PersonalInformationService(personalInformationState, setPersonalInformationState);

    useEffect(() => {
        personalInformationService.InitPersonalInformation();
    }, []);

    // Pass the ref to the useImperativeHandle hook
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            return personalInformationState;
        },
        updateValidationErrors: (errorMessagesForFormFields: IDictionary<string>) => {
            console.log(errorMessagesForFormFields);
            setPersonalInformationState((prevState: IPersonalInformationState) => ({
                ...prevState,
                errorMessagesForFormFields: errorMessagesForFormFields
            }));
        }
    }));

    const inputChangeHandler = (event: any): void => {
        setPersonalInformationState((prevState: IPersonalInformationState) => ({
            ...prevState,
            [event.target.id]: event.target.value
        }));
    }

    const choiceChangeHandler = (ev: any, option: any): void => {
        setPersonalInformationState((prevState: IPersonalInformationState) => ({
            ...prevState,
            [ev.target.name]: option.key
        }));
    }

    const dropDownChoiceChangeHandler = (ev: any, option: any): void => {
        setPersonalInformationState((prevState: IPersonalInformationState) => ({
            ...prevState,
            [ev.target.id]: option.key
        }));
    }

    return (<>
        <div className={speakerInfoFormContext.speakerInfoFormState.disableForm ? styles.disableForm : ``}>
            <div className={styles.topInstrunction}>
                <Instructions instructions={speakerInfoFormContext.speakerInfoFormState.applicationText.PersonalInformationInstrunctions} />
            </div>
            <div className={styles.flexContainer}>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <TextField
                            label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_FirstName}
                            required
                            className={styles.speakerFormfield}
                            value={personalInformationState.firstName}
                            id={"firstName"}
                            onChange={inputChangeHandler}
                            errorMessage={personalInformationState?.errorMessagesForFormFields?.firstName}
                        />
                    </Stack>
                </div>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <TextField
                            label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_LastName}
                            required
                            className={styles.speakerFormfield}
                            value={personalInformationState.lastName}
                            id={"lastName"}
                            onChange={inputChangeHandler}
                            errorMessage={personalInformationState?.errorMessagesForFormFields?.lastName}
                        />
                    </Stack>
                </div>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <TextField
                            label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_Email}
                            required
                            className={styles.speakerFormfield}
                            value={personalInformationState.eMail}
                            id={"eMail"}
                            onChange={inputChangeHandler}
                            errorMessage={personalInformationState?.errorMessagesForFormFields?.eMail}
                            disabled={true}
                        />
                    </Stack>
                </div>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <Dropdown
                            label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_Gender}
                            required
                            options={personalInformationState.genderOptions ?? []}
                            className={styles.speakerFormfield}
                            selectedKey={personalInformationState.gender}
                            id={"gender"}
                            onChange={dropDownChoiceChangeHandler}
                            errorMessage={personalInformationState?.errorMessagesForFormFields?.gender}
                        />
                    </Stack>
                </div>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <ChoiceGroup
                            label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_MembershipType}
                            required
                            options={personalInformationState.membershipTypeOptions}
                            className={styles.speakerFormfield}
                            selectedKey={personalInformationState.membershipType}
                            name={"membershipType"}
                            onChange={choiceChangeHandler}
                            styles={{ flexContainer: { display: "flex" }}}
                        />
                        <span className={styles.errorMessage}>{personalInformationState?.errorMessagesForFormFields?.membershipType}</span>
                    </Stack>
                </div>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <TextField
                            label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_CustomerAccount}
                            required
                            className={styles.speakerFormfield}
                            value={personalInformationState.customerAccount}
                            id={"customerAccount"}
                            onChange={inputChangeHandler}
                            errorMessage={personalInformationState?.errorMessagesForFormFields?.customerAccount}
                        />
                    </Stack>
                </div>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <Dropdown
                            label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_Profession}
                            required
                            options={personalInformationState.professionOptions ?? []}
                            className={styles.speakerFormfield}
                            selectedKey={personalInformationState.profession}
                            id={"profession"}
                            onChange={dropDownChoiceChangeHandler}
                            errorMessage={personalInformationState?.errorMessagesForFormFields?.profession}
                        />
                    </Stack>
                </div>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <TextField
                            label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_LabName}
                            required
                            className={styles.speakerFormfield}
                            value={personalInformationState.labName}
                            id={"labName"}
                            onChange={inputChangeHandler}
                            errorMessage={personalInformationState?.errorMessagesForFormFields?.labName}
                        />
                    </Stack>
                </div>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <TextField
                            label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_TerritoryManager}
                            required
                            className={styles.speakerFormfield}
                            value={personalInformationState.territoryManager}
                            id={"territoryManager"}
                            onChange={inputChangeHandler}
                            errorMessage={personalInformationState?.errorMessagesForFormFields?.territoryManager}
                        />
                    </Stack>
                </div>
            </div>
        </div>
    </>);
});

export default PersonalInformation;