// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Message-module__message__ibY9L{height:440px;display:flex;justify-content:center;align-items:center;width:100%;margin:auto 0}`, "",{"version":3,"sources":["webpack://./src/client/components/Message/Message.module.scss"],"names":[],"mappings":"AAAA,gCACI,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,aAAA","sourcesContent":[".message{\r\n    height:440px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 100%;\r\n    margin: auto 0; \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `Message-module__message__ibY9L`
};
export default ___CSS_LOADER_EXPORT___;
