import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";
import * as React from 'react';
import { SpeakerInfoFormContext } from '../context';
import { useContext } from 'react';

type IConfirmBoxProps = {
  title: string;
  subtext: string;
  hidden: boolean;
  yesConfirmation: () => void;
  noConfirmation: () => void;
};

export const ConfirmBox : React.FC<IConfirmBoxProps> = (props: IConfirmBoxProps) => {
  const dialogContentProps = {
    type: DialogType.normal,
    title: props.title,
    closeButtonAriaLabel: "Close",
    subText: props.subtext,
  };
  const speakerInfoFormContext:any = useContext(SpeakerInfoFormContext);
  return (
    <Dialog hidden={props.hidden} dialogContentProps={dialogContentProps}>
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            props.yesConfirmation();
          }}
          text={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_Yes}
        />
        <DefaultButton
          onClick={() => {
            props.noConfirmation();
          }}
          text={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_No}
        />
      </DialogFooter>
    </Dialog>
  );
};
