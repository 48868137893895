import * as React from 'react';
import { createRoot } from 'react-dom/client'
import App from './App';
import './scss/app.scss';
import './scss/reactStepWizardAnimation.scss';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Verification from './Verification';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
    },
    {
        path: "/verify/:code",
        element: <Verification/>
    }
]);

const root = createRoot(document.getElementById('root')!);
root.render(<RouterProvider router={router} />);