import * as React from 'react';

export interface IInstructionsProps {
    instructions:string | TrustedHTML;
}

export interface IInstructionsState {
}

const Instructions: React.FC<IInstructionsProps> = (props: IInstructionsProps) => {
    return(
        <div dangerouslySetInnerHTML={{ __html: props.instructions}}/>
    );
}

export default Instructions;