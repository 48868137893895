// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GoogleLoginComponent__google-login-button__ea5Yp{width:100%}`, "",{"version":3,"sources":["webpack://./src/client/components/Login/GoogleLoginComponent.scss"],"names":[],"mappings":"AAAA,kDACI,UAAA","sourcesContent":[".google-login-button{\r\n    width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"google-login-button": `GoogleLoginComponent__google-login-button__ea5Yp`
};
export default ___CSS_LOADER_EXPORT___;
