// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner-module__wait__gBHCJ div[class*=ms-Dialog-main]{min-height:auto}`, "",{"version":3,"sources":["webpack://./src/client/components/Spinner/Spinner.module.scss"],"names":[],"mappings":"AACC,wDACC,eAAA","sourcesContent":[".wait {\r\n\tdiv[class*=\"ms-Dialog-main\"] {\r\n\t\tmin-height: auto;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wait": `Spinner-module__wait__gBHCJ`
};
export default ___CSS_LOADER_EXPORT___;
