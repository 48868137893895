import { ISpeakerLevelBIOTopicsState } from './ISpeakerLevelBIOTopicsState';
import React, { useState, useContext, useEffect,forwardRef, useImperativeHandle } from 'react';
import { SpeakerInfoFormContext } from '../../../components/context';
import { TextField, Stack, Checkbox, Label, ChoiceGroup } from '@fluentui/react';
import styles from './../../../scss/EventSpeakerForm.module.scss';
import { SpeakerLevelBIOTopicsService } from './SpeakerLevelBIOTopicsService';
import { Brand, StraumannProductPortfolio } from '../../../constant';
import { IDictionary } from '../../../interfaces';
import Instructions from '../FormText/Instructions';

export interface ISpeakerLevelBIOTopicsProps {
}

export type SpeakerLevelBIOTopicsRef = {
    getFormData: () => ISpeakerLevelBIOTopicsState;
    updateValidationErrors: (errorMessagesForFormFields: IDictionary<string>) => void;
};

const SpeakerLevelBIOTopics = forwardRef<SpeakerLevelBIOTopicsRef, ISpeakerLevelBIOTopicsProps>((props, ref) => {
    const speakerInfoFormContext: any = useContext(SpeakerInfoFormContext);
    const [speakerLevelBIOTopicsState, setSpeakerLevelBIOTopicsState] = useState<ISpeakerLevelBIOTopicsState>({
        errorMessagesForFormFields: speakerInfoFormContext.speakerInfoFormState.errorMessagesForFormFields,
        interestedInITIStudyClubs: speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState?.interestedInITIStudyClubs ?? speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.InterestedInITIStudyClubs,
        itiStudyClubInfoAgreement: speakerInfoFormContext.speakerInfoFormState.speakerLevelBIOTopicsState?.itiStudyClubInfoAgreement ?? speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.ITIStudyClubInfoAgreement
    });
    const speakerLevelBIOTopicsService = new SpeakerLevelBIOTopicsService(speakerLevelBIOTopicsState,setSpeakerLevelBIOTopicsState);

    useEffect(() => {
        speakerLevelBIOTopicsService.InitSpeakerLevelBIOTopics();
    }, []);

    const inputChangeHandler = (event: any): void => {
        setSpeakerLevelBIOTopicsState((prevState: ISpeakerLevelBIOTopicsState) => ({
            ...prevState,
            [event.target.id]: event.target.value
        }));
    }

    const choiceChangeHandler = (ev: any, option: any): void => {
        setSpeakerLevelBIOTopicsState((prevState: ISpeakerLevelBIOTopicsState) => ({
            ...prevState,
            [ev.target.name]: option.key
        }));
    }

    const handleCheckBoxChange = (ev: any, checked?: boolean, f?: any, fieldState?: any, fieldOptionsState?: any): void => {
        let fieldVal: string = "";
        let options: any = speakerLevelBIOTopicsState[fieldOptionsState as keyof ISpeakerLevelBIOTopicsState];
        options.forEach((option: any) => {
            if (option.key === f.key) {
                option.checked = checked;
            }
            if (option.checked) {
                if (fieldVal)
                    fieldVal = fieldVal + `\n${option.text}`;
                else
                    fieldVal = option.text;
            }
        });
        setSpeakerLevelBIOTopicsState((prevState: ISpeakerLevelBIOTopicsState) => ({
            ...prevState,
            [fieldOptionsState]: options,
            [fieldState]: fieldVal
        }));
    }

    // Pass the ref to the useImperativeHandle hook
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            return speakerLevelBIOTopicsState;
        },
        updateValidationErrors: (errorMessagesForFormFields: IDictionary<string>) => {
            setSpeakerLevelBIOTopicsState((prevState: ISpeakerLevelBIOTopicsState) => ({
                ...prevState,
                errorMessagesForFormFields: errorMessagesForFormFields
            }));
        }
    }));

    return (
        <div className={speakerInfoFormContext.speakerInfoFormState.disableForm ? styles.disableForm : ``}>
            <div className={styles.topInstrunction}>
                <Instructions instructions={speakerInfoFormContext.speakerInfoFormState.applicationText.SpeakerLevelBIOTopicsInstructions} />
            </div>
            {Brand.Straumann === speakerInfoFormContext.speakerInfoFormState.speakerInfoItem.Brand &&
                <>
                    <div className={`${styles.row} ${styles.itiStudyClubInfoAgreement}`}>
                        <div dangerouslySetInnerHTML={{ __html: speakerInfoFormContext.speakerInfoFormState.applicationText.ITIStudyClubInfoAgreement }} />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.column}>
                            <Stack className={styles.stack}>
                                <ChoiceGroup
                                    required
                                    options={speakerLevelBIOTopicsState.itiStudyClubInfoAgreementOptions}
                                    className={styles.speakerFormfield}
                                    selectedKey={speakerLevelBIOTopicsState.itiStudyClubInfoAgreement}
                                    name={"itiStudyClubInfoAgreement"}
                                    onChange={choiceChangeHandler}
                                    styles={{ flexContainer: { display: "flex" } }}
                                />
                                <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.itiStudyClubInfoAgreement}</span>
                            </Stack>
                        </div>
                    </div>
                </>
            }
            {Brand.ClearCorrect === speakerInfoFormContext.speakerInfoFormState.speakerInfoItem.Brand
                ? <div className={styles.row}>
                    <Stack className={styles.stack}>
                        <TextField
                            label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_SpeakerBIO}
                            required
                            multiline
                            className={styles.speakerFormfield}
                            value={speakerLevelBIOTopicsState.speakerBIO}
                            id={"speakerBIO"}
                            onChange={inputChangeHandler}
                            errorMessage={speakerLevelBIOTopicsState.errorMessagesForFormFields?.speakerBIO}
                        />
                    </Stack>
                </div>
                :
                <div className={styles.flexContainer}>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <TextField
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_SpeakerBIO}
                                required
                                multiline
                                className={styles.speakerFormfield}
                                value={speakerLevelBIOTopicsState.speakerBIO}
                                id={"speakerBIO"}
                                onChange={inputChangeHandler}
                                errorMessage={speakerLevelBIOTopicsState.errorMessagesForFormFields?.speakerBIO}
                            />
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <ChoiceGroup
                                label={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_InterestedInITIStudyClubs}
                                required
                                options={speakerLevelBIOTopicsState.interestedInITIStudyClubsOptions}
                                className={styles.speakerFormfield}
                                selectedKey={speakerLevelBIOTopicsState.interestedInITIStudyClubs}
                                name={"interestedInITIStudyClubs"}
                                onChange={choiceChangeHandler}
                                styles={{ flexContainer: { display: "flex" } }}
                            />
                            <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.interestedInITIStudyClubs}</span>
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_LevelAndForumOfSpeaking}</Label>
                            {speakerLevelBIOTopicsState.levelForumSpeakingOptions?.map((f, idx) => {
                                return (
                                    <Checkbox
                                        key={f.text}
                                        className={styles.field}
                                        label={f.text}
                                        onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "levelForumSpeaking", "levelForumSpeakingOptions")}
                                        checked={f.checked}
                                    />
                                );
                            }
                            )}
                            <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.levelForumSpeaking}</span>
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_FoundationImplantDentistry}</Label>
                            {speakerLevelBIOTopicsState.foundationImplantDentistryOptions?.map((f, idx) => {
                                return (
                                    <Checkbox
                                        key={f.text}
                                        className={styles.field}
                                        label={f.text}
                                        onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "foundationImplantDentistry", "foundationImplantDentistryOptions")}
                                        checked={f.checked}
                                    />
                                );
                            }
                            )}
                            <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.foundationImplantDentistry}</span>
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_ProstheticProcsImplantDentistry}</Label>
                            {speakerLevelBIOTopicsState.prostheticProcsImplantDentistryOptions?.map((f, idx) => {
                                return (
                                    <Checkbox
                                        key={f.text}
                                        className={styles.field}
                                        label={f.text}
                                        onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "prostheticProcsImplantDentistry", "prostheticProcsImplantDentistryOptions")}
                                        checked={f.checked}
                                    />
                                );
                            }
                            )}
                            <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.prostheticProcsImplantDentistry}</span>
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_SurgicalProcsImplantDentistry}</Label>
                            {speakerLevelBIOTopicsState.surgicalProcsImplantDentistryOptions?.map((f, idx) => {
                                return (
                                    <Checkbox
                                        key={f.text}
                                        className={styles.field}
                                        label={f.text}
                                        onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "surgicalProcsImplantDentistry", "surgicalProcsImplantDentistryOptions")}
                                        checked={f.checked}
                                    />
                                );
                            }
                            )}
                            <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.surgicalProcsImplantDentistry}</span>
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_TreatmentPlanning}</Label>
                            {speakerLevelBIOTopicsState.treatmentPlanningOptions?.map((f, idx) => {
                                return (
                                    <Checkbox
                                    key={f.text}
                                        className={styles.field}
                                        label={f.text}
                                        onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "treatmentPlanning", "treatmentPlanningOptions")}
                                        checked={f.checked}
                                    />
                                );
                            }
                            )}
                            <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.treatmentPlanning}</span>
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_LaboratoryProcedures}</Label>
                            {speakerLevelBIOTopicsState.laboratoryProceduresOptions?.map((f, idx) => {
                                return (
                                    <Checkbox
                                    key={f.text}
                                        className={styles.field}
                                        label={f.text}
                                        onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "laboratoryProcedures", "laboratoryProceduresOptions")}
                                        checked={f.checked}
                                    />
                                );
                            }
                            )}
                            <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.laboratoryProcedures}</span>
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_DigitalDentistry}</Label>
                            {speakerLevelBIOTopicsState.digitalDentistryOptions?.map((f, idx) => {
                                return (
                                    <Checkbox
                                    key={f.text}
                                        className={styles.field}
                                        label={f.text}
                                        onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "digitalDentistry", "digitalDentistryOptions")}
                                        checked={f.checked}
                                    />
                                );
                            }
                            )}
                            <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.digitalDentistry}</span>
                        </Stack>

                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_Regenerative}</Label>
                            {speakerLevelBIOTopicsState.regenerativeOptions?.map((f, idx) => {
                                return (
                                    <Checkbox
                                    key={f.text}
                                        className={styles.field}
                                        label={f.text}
                                        onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "regenerative", "regenerativeOptions")}
                                        checked={f.checked}
                                    />
                                );
                            }
                            )}
                            <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.regenerative}</span>
                        </Stack>
                    </div>
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_PracticeManagement}</Label>
                            {speakerLevelBIOTopicsState.practiceManagementOptions?.map((f, idx) => {
                                return (
                                    <Checkbox
                                    key={f.text}
                                        className={styles.field}
                                        label={f.text}
                                        onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "practiceManagement", "practiceManagementOptions")}
                                        checked={f.checked}
                                    />
                                );
                            }
                            )}
                            <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.practiceManagement}</span>
                        </Stack>

                    </div>
                    {Brand.Straumann === speakerInfoFormContext.speakerInfoFormState.speakerInfoItem.Brand && 
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_Hygiene}</Label>
                            {speakerLevelBIOTopicsState.hygieneOptions?.map((f, idx) => {
                                return (
                                    <Checkbox
                                    key={f.text}
                                        className={styles.field}
                                        label={f.text}
                                        onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "hygiene", "hygieneOptions")}
                                        checked={f.checked}
                                    />
                                );
                            }
                            )}
                            <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.hygiene}</span>
                        </Stack>
                    </div>
                    }
                    <div className={styles.column}>
                        <Stack className={styles.stack}>
                            <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_ProductPortfolio}</Label>
                            {speakerLevelBIOTopicsState.productPortfolioOptions?.map((f, idx) => {
                                return (
                                    <Checkbox
                                    key={f.text}
                                        className={styles.field}
                                        label={f.text}
                                        onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "productPortfolio", "productPortfolioOptions")}
                                        checked={f.checked}
                                    />
                                );
                            }
                            )}
                            <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.productPortfolio}</span>
                        </Stack>
                    </div>
                    {Brand.Straumann === speakerInfoFormContext.speakerInfoFormState.speakerInfoItem.Brand &&
                        <>
                            {speakerLevelBIOTopicsState.productPortfolio && speakerLevelBIOTopicsState.productPortfolio?.indexOf(StraumannProductPortfolio.Surgical) > -1 &&
                                <div className={styles.column}>
                                    <Stack className={styles.stack}>
                                        <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_SurgicalProductPortfolio}</Label>
                                        {speakerLevelBIOTopicsState.surgicalProductPortfolioOptions?.map((f, idx) => {
                                            return (
                                                <Checkbox
                                                key={f.text}
                                                    className={styles.field}
                                                    label={f.text}
                                                    onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "surgicalProductPortfolio", "surgicalProductPortfolioOptions")}
                                                    checked={f.checked}
                                                />
                                            );
                                        }
                                        )}
                                        <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.surgicalProductPortfolio}</span>
                                    </Stack>
                                </div>
                            }
                            {speakerLevelBIOTopicsState.productPortfolio && speakerLevelBIOTopicsState.productPortfolio?.indexOf(StraumannProductPortfolio.Restorative) > -1 &&
                                <div className={styles.column}>
                                    <Stack className={styles.stack}>
                                        <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_RestorativeProductPortfolio}</Label>
                                        {speakerLevelBIOTopicsState.restorativeProductPortfolioOptions?.map((f, idx) => {
                                            return (
                                                <Checkbox
                                                key={f.text}
                                                    className={styles.field}
                                                    label={f.text}
                                                    onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "restorativeProductPortfolio", "restorativeProductPortfolioOptions")}
                                                    checked={f.checked}
                                                />
                                            );
                                        }
                                        )}
                                        <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.restorativeProductPortfolio}</span>
                                    </Stack>
                                </div>
                            }
                            {speakerLevelBIOTopicsState.productPortfolio && speakerLevelBIOTopicsState.productPortfolio?.indexOf(StraumannProductPortfolio.Regenerative) > -1 &&
                                <div className={styles.column}>
                                    <Stack className={styles.stack}>
                                        <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_BiomaterialsProductPortfolio}</Label>
                                        {speakerLevelBIOTopicsState.biomaterialsProductPortfolioOptions?.map((f, idx) => {
                                            return (
                                                <Checkbox
                                                key={f.text}
                                                    className={styles.field}
                                                    label={f.text}
                                                    onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "biomaterialsProductPortfolio", "biomaterialsProductPortfolioOptions")}
                                                    checked={f.checked}
                                                />
                                            );
                                        }
                                        )}
                                        <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.biomaterialsProductPortfolio}</span>
                                    </Stack>
                                </div>
                            }
                            {speakerLevelBIOTopicsState.productPortfolio && speakerLevelBIOTopicsState.productPortfolio?.indexOf(StraumannProductPortfolio.CARES) > -1 &&
                                <div className={styles.column}>
                                    <Stack className={styles.stack}>
                                        <Label required>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_DigitalWorkflowPortfolio}</Label>
                                        {speakerLevelBIOTopicsState.digitalWorkflowPortfolioOptions?.map((f, idx) => {
                                            return (
                                                <Checkbox
                                                key={f.text}
                                                    className={styles.field}
                                                    label={f.text}
                                                    onChange={(ev, checked) => handleCheckBoxChange(ev, checked, f, "digitalWorkflowPortfolio", "digitalWorkflowPortfolioOptions")}
                                                    checked={f.checked}
                                                />
                                            );
                                        }
                                        )}
                                        <span className={styles.errorMessage}>{speakerLevelBIOTopicsState.errorMessagesForFormFields?.digitalWorkflowPortfolio}</span>
                                    </Stack>
                                </div>
                            }
                        </>
                    }
                </div>
            }
        </div>
    );
});

export default SpeakerLevelBIOTopics;