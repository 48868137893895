import * as React from "react";

export interface ErrorBoundaryProps{
    children: React.JSX.Element[] | React.JSX.Element;
}

export interface ErrorBoundayState{
    hasError: boolean;
    error: string | null;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps,ErrorBoundayState>{
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { 
            hasError: false,
            error: null
         };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { 
            hasError: true
         };
    }

    componentDidCatch(error: any, errorInfo: any) {        
        if(error){
            let strError = error?.message || error?.name || error;
            this.setState({
                error: strError
            });
        }
    }

    render() 
    {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div style={{margin:'10px'}}><h2>Error</h2>Something went wrong....<br/>{this.state?.error}</div>;
        }

        return this.props.children;
    }
}