import { CommonService } from '../../../services';
import { IQuestionsAndRequiredDocumentsState } from "./IQuestionsAndRequiredDocumentsState";
import { IChoiceGroupOption } from '@fluentui/react';
import { IApplicationLog, IDocuments } from '../../../interfaces';
import { SpeakerInfoFormContext } from '../../../components/context';
import { useContext } from 'react';
import { APIHelper } from '../../../helpers/APIHelper';
import { DocumentCategories } from '../../../constant';

export class QuestionsAndRequiredDocumentsService {
    private commonService: any;
    private setQuestionsAndRequiredDocumentsState: any;
    private speakerInfoFormContext: any;
    constructor(setQuestionsAndRequiredDocumentsState: any) {
       this.commonService = new CommonService();
       this.speakerInfoFormContext = useContext(SpeakerInfoFormContext);
       this.setQuestionsAndRequiredDocumentsState = setQuestionsAndRequiredDocumentsState;
    }

    public InitQuestionsAndRequiredDocuments(): void {
        try {
            let speakerNetworkAcknowledgmentOptions: IChoiceGroupOption[] = this.commonService.getChoiceFieldChoicesForAFormAndField("SpeakerInfoForm", "SpeakerNetworkAcknowledgment");
            let conductsWebinarPresentationsOptions: IChoiceGroupOption[] = this.commonService.getChoiceFieldChoicesForAFormAndField("SpeakerInfoForm", "ConductsWebinarPresentations");
            let permissionsToRecordWebinarOptions: IChoiceGroupOption[] = this.commonService.getChoiceFieldChoicesForAFormAndField("SpeakerInfoForm", "PermissionsToRecordWebinar");
                
            if(!this.speakerInfoFormContext.speakerInfoFormState.questionsAndRequiredDocumentsState){   
                this.setQuestionsAndRequiredDocumentsState((prevState: IQuestionsAndRequiredDocumentsState) => ({
                    ...prevState,
                    speakerNetworkAcknowledgmentOptions: speakerNetworkAcknowledgmentOptions,
                    conductsWebinarPresentationsOptions: conductsWebinarPresentationsOptions,
                    permissionsToRecordWebinarOptions: permissionsToRecordWebinarOptions,
                    errorMessagesForFormFields : {}
                }));
            }
            else{
                this.setQuestionsAndRequiredDocumentsState((prevState: IQuestionsAndRequiredDocumentsState) => ({
                    ...prevState,
                    speakerNetworkAcknowledgmentOptions: speakerNetworkAcknowledgmentOptions,
                    conductsWebinarPresentationsOptions: conductsWebinarPresentationsOptions,
                    permissionsToRecordWebinarOptions: permissionsToRecordWebinarOptions,
                    errorMessagesForFormFields : this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.errorMessagesForFormFields,
                    speakerNetworkAcknowledgment:this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.speakerNetworkAcknowledgment,
                    conductsWebinarPresentations:this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.conductsWebinarPresentations,
                    permissionsToRecordWebinar:this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.permissionsToRecordWebinar,
                    tcAgreed:this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.tcAgreed,
                    cv:this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.cv,
                    photo:this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.photo,
                    presentation:this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.presentation,
                    w9:this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.w9,            
                }));
            }
        }
        catch (error: any) {
            let applicationLog: IApplicationLog = {
                Title: "InitQuestionsAndRequiredDocuments",
                LogType: "Error",
                Location: "SPFx | InitQuestionsAndRequiredDocuments | QuestionsAndRequiredDocumentsService",
                LogDetails: error.error
            }
            APIHelper.createApplicationLog(applicationLog);
        }
    }

    public showUploadedFiles(documents:IDocuments[]):void{
        //Headshot
        let headshot:IDocuments[] | undefined = documents.filter((x:IDocuments)=>{
            return  x.category === DocumentCategories.Headshot});
        let photo: IDocuments[] = [];
        if(headshot){
            photo=[...headshot]
        }

        //CV
        let uploadedCV:IDocuments[] | undefined = documents.filter((x:IDocuments)=>{
            return  x.category === DocumentCategories.CV});
        let cv: IDocuments[] = [];
        if(uploadedCV){
            cv=[...uploadedCV];
        }

        //W9
        let uploadedW9TaxFile:IDocuments[] | undefined = documents.filter((x:IDocuments)=>{
            return  x.category === DocumentCategories.W9TaxFile});
        let w9TaxFile: IDocuments[] = [];
        if(uploadedW9TaxFile){
            w9TaxFile=[...uploadedW9TaxFile];
        }

        //VendorForm
        let uploadedVendorForm:IDocuments[] | undefined = documents.filter((x:IDocuments)=>{
            return  x.category === DocumentCategories.VendorForm});
        let vendorForm: IDocuments[] = [];
        if(uploadedVendorForm){
            vendorForm=[...uploadedVendorForm];
        }

        //SamplePresentationOthers
        let uploadedSamplePresentationOthers:IDocuments[] | undefined = documents.filter((x:IDocuments)=>{
            return  x.category === DocumentCategories.SamplePresentationOthers});
        let samplePresentationOthers: IDocuments[] = [];
        if(uploadedSamplePresentationOthers){
            samplePresentationOthers=[...uploadedSamplePresentationOthers];
        }

        this.setQuestionsAndRequiredDocumentsState((prevState: IQuestionsAndRequiredDocumentsState) => ({
            ...prevState,
            photo:this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.photo ?? photo,
            cv: this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.cv ?? cv,
            w9:this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.w9 ?? w9TaxFile,
            vendorForm: vendorForm ?? this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.vendorForm,
            presentation:this.speakerInfoFormContext.speakerInfoFormState?.questionsAndRequiredDocumentsState?.presentation ?? samplePresentationOthers,
            documents:documents
        }));
    }
}