export enum StraumannProductPortfolio {
    Surgical = "Surgical",
    Restorative = "Restorative",
    Regenerative = "Regenerative",
    CARES = "CARES"
}

export enum MembershipType {
    USSpeaker = "U.S. Speaker",
    CanadianSpeaker = "Canadian Speaker"
}