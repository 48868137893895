import { CommonService } from '../../../services';
import { IContactInformationState } from "./IContactInformationState";
import { IChoiceGroupOption, IDropdownOption } from '@fluentui/react';
import { SpeakerInfoFormContext } from '../../../components/context';
import { useContext } from 'react';
import { IApplicationLog } from '../../../interfaces';
import { APIHelper } from '../../../helpers/APIHelper';

export class ContactInformationService {
    private commonService: any;
    private setContactInformationState: any;
    private speakerInfoFormContext: any;
    constructor(setContactInformationState: any) {
        this.speakerInfoFormContext = useContext(SpeakerInfoFormContext);
        this.commonService = new CommonService();
        this.setContactInformationState = setContactInformationState;
    }

    public InitContactInformation(): void {
        try {
            if(!this.speakerInfoFormContext.speakerInfoFormState.contactInformationState){   
                let countryOptions: IDropdownOption[] = this.commonService.getChoiceFieldChoicesForAFormAndField("SpeakerInfoForm", "Country");
                let licensedInMAOrVTOptions: IChoiceGroupOption[] = this.commonService.getChoiceFieldChoicesForAFormAndField("SpeakerInfoForm", "LicensedMAOrVT");
                let usStateOptions: IDropdownOption[] = this.commonService.getChoiceFieldChoicesForAFormAndField("SpeakerInfoForm", "USState");
                this.setContactInformationState((prevState: IContactInformationState) => ({
                    ...prevState,
                    countryOptions: countryOptions,
                    licensedInMAOrVTOptions: licensedInMAOrVTOptions,
                    usStateOptions: usStateOptions,
                    country: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.Country,
                    addressLine1: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.AddressLine1,
                    addressLine2: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.AddressLine2,
                    city: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.City,
                    postalCode: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.ZIPCode,
                    usState: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.State,
                    workPhone: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.WorkPhone,
                    cellPhone: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.CellPhone,
                    PrimaryStateLicensure: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.PrimaryStateLicensure,
                    stateLicense: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.StateLicense,
                    nationalProviderId: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.NationalProviderId,
                    maorVTLicenseNumber: this.speakerInfoFormContext.speakerInfoFormState.speakerInfoItem?.MAorVTLicenseNumber
                }));
            }
            else{
                this.setContactInformationState((prevState: IContactInformationState) => ({
                    ...prevState,
                    countryOptions: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState.countryOptions,
                    licensedInMAOrVTOptions: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState.licensedInMAOrVTOptions,
                    usStateOptions: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState.usStateOptions,
                    country: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.country,
                    addressLine1: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.addressLine1,
                    addressLine2: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.addressLine2,
                    city: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.city,
                    postalCode: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.postalCode,
                    usState: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.usState,
                    workPhone: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.workPhone,
                    cellPhone: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.cellPhone,
                    PrimaryStateLicensure: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.PrimaryStateLicensure,
                    stateLicense: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.stateLicense,
                    nationalProviderId: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.nationalProviderId,
                    licensedMAOrVT: this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.licensedMAOrVT,
                    maorVTLicenseNumber : this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.maorVTLicenseNumber,
                    errorMessagesForFormFields : this.speakerInfoFormContext.speakerInfoFormState.contactInformationState?.errorMessagesForFormFields        
                }));
            }
        }
        catch (error: any) {
            let applicationLog: IApplicationLog = {
                Title: "InitContactInformation",
                LogType: "Error",
                Location: "SPFx | InitContactInformation | ContactInformationService",
                LogDetails: error.error
            }
            APIHelper.createApplicationLog(applicationLog);
        }
    }

}