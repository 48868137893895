import { Dialog, DialogFooter, PrimaryButton } from "@fluentui/react";
import * as React from 'react';
import { SpeakerInfoFormContext, SpeakerInfoFormContextType } from '../context';
import { useContext } from 'react';

interface IMessageDialogProps {
  title?: string;
  subtext?: string;
  open: boolean;
  closeModal: () => void;
};

export const MessageDialog: React.FC<IMessageDialogProps> = (props: IMessageDialogProps) => {
  const _dialogContentProps = {
    subText: props.subtext
  };
  const speakerInfoFormContext = useContext(SpeakerInfoFormContext);

  return (
    <Dialog hidden={props.open} dialogContentProps={_dialogContentProps}>
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            props.closeModal();
          }}
          text={speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_Ok}
        />
      </DialogFooter>
    </Dialog>
  );
};
