import FileUpload from '../../FileUpload/FileUpload';
import React, { forwardRef, useImperativeHandle, useState, useContext, useEffect } from 'react';
import { IDictionary, IDocuments } from '../../../interfaces';
import { SpeakerInfoFormContext } from '../../../components/context';
import styles from '../../../scss/EventSpeakerForm.module.scss';
import { TextField, Stack } from '@fluentui/react';
import { DocumentCategories } from '../../../constant';

export interface ISendBackProps {
}

export interface ISendBackState {
    errorMessagesForFormFields: IDictionary<string>;
    comments?: string;
    documents: IDocuments[];
}

export type SendBackRef = {
    getFormData: () => ISendBackState;
    updateValidationErrors: (errorMessagesForFormFields: IDictionary<string>) => void;
};
const SendBack = forwardRef<SendBackRef, ISendBackProps>((props, ref) => {
    const { speakerInfoFormState, downloadFile } = useContext(SpeakerInfoFormContext);
    const [sendBackState, setSendBackState] = useState<ISendBackState>({
        errorMessagesForFormFields: {},
        documents: speakerInfoFormState?.speakerInfoItem?.SpeakerDocuments ?? []
    });

    // Pass the ref to the useImperativeHandle hook
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            return sendBackState;
        },
        updateValidationErrors: (errorMessagesForFormFields: IDictionary<string>) => {
            setSendBackState((prevState: ISendBackState) => ({
                ...prevState,
                errorMessagesForFormFields: errorMessagesForFormFields
            }));
        }
    }));

    const addDocument = React.useCallback((files: IDocuments[]): void => {
        let documents = sendBackState.documents.slice() ?? [];
        documents = documents.concat(files);
        
        setSendBackState((prevState: ISendBackState) => ({
            ...prevState,
            documents: documents
        }));
    }, [sendBackState.documents, setSendBackState])

    const removeDocument = React.useCallback((file: IDocuments): void => {
        let documents = sendBackState.documents.slice() ?? [];
        documents = documents.filter(x => file.name && file.name !== x.name);

        setSendBackState((prevState: ISendBackState) => ({
            ...prevState,
            documents: documents
        }));
    }, [sendBackState.documents, setSendBackState])

    const inputChangeHandler = (event: any): void => {
        setSendBackState((prevState: ISendBackState) => ({
            ...prevState,
            [event.target.id]: event.target.value
        }));
    }

    return (
        <div className={styles.feedbackForm}>
            <h1 className={styles.feedbackFormHeading}>{speakerInfoFormState?.applicationLables?.Label_FeedbackForm}</h1>

            <div className={styles.row}>
                <Stack className={`${styles.stack} ${styles.feedbackFormComment}`}>
                    <TextField
                        label={speakerInfoFormState?.speakerInfoItem?.EducationTeamComment}
                        id={"comments"}
                        onChange={inputChangeHandler}
                        multiline
                        className={styles.speakerFormfield}
                        value={sendBackState.comments}
                        errorMessage={sendBackState?.errorMessagesForFormFields?.comments}
                    />
                </Stack>
            </div>
            <div className={styles.row}>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <FileUpload
                            elementId={`fileUploadPhoto`}
                            category={DocumentCategories.Headshot}
                            text={speakerInfoFormState.applicationLables.Label_FileUpload_Photo}
                            isRequired={false}
                            errorMessage={``}
                            addFiles={addDocument}
                            removeFile={removeDocument}
                            downloadFile={downloadFile}
                            allSpeakerDocuments={sendBackState.documents}
                        />
                    </Stack>
                </div>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <FileUpload
                            elementId={`fileUploadCV`}
                            category={DocumentCategories.CV}
                            text={speakerInfoFormState.applicationLables.Label_FileUpload_CV}
                            isRequired={false}
                            errorMessage={``}
                            addFiles={addDocument}
                            removeFile={removeDocument}
                            downloadFile={downloadFile}
                            allSpeakerDocuments={sendBackState.documents}
                        />
                    </Stack>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <FileUpload
                            elementId={`fileUploadW9`}
                            category={DocumentCategories.W9TaxFile}
                            text={speakerInfoFormState.applicationLables.Label_FileUpload_W9}
                            isRequired={false}
                            errorMessage={``}
                            addFiles={addDocument}
                            removeFile={removeDocument}
                            downloadFile={downloadFile}
                            allSpeakerDocuments={sendBackState.documents}
                        />
                    </Stack>
                </div>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <FileUpload
                            elementId={`fileUploadSamplePresentation`}
                            category={DocumentCategories.SamplePresentationOthers}
                            text={speakerInfoFormState.applicationLables.Label_FileUpload_SamplePresentation}
                            isRequired={false}
                            errorMessage={``}
                            addFiles={addDocument}
                            removeFile={removeDocument}
                            downloadFile={downloadFile}
                            allSpeakerDocuments={sendBackState.documents}
                        />
                    </Stack>
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.column}>
                    <Stack className={styles.stack}>
                        <FileUpload
                            elementId={`vendorForm`}
                            category={DocumentCategories.VendorForm}
                            text={speakerInfoFormState.applicationLables.Label_FileUpload_VendorForm}
                            isRequired={false}
                            errorMessage={``}
                            addFiles={addDocument}
                            removeFile={removeDocument}
                            downloadFile={downloadFile}
                            allSpeakerDocuments={sendBackState.documents}
                        />
                    </Stack>
                </div>
            </div>
        </div>
    );
});

export default SendBack;