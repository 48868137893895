import { createContext } from 'react';
import { ISpeakerInfoFormState } from './SpeakerInfoForm/ISpeakerInfoFormState';

export type SpeakerInfoFormContextType = {
    speakerInfoFormState: ISpeakerInfoFormState;
    setSpeakerInfoFormState: (value: React.SetStateAction<ISpeakerInfoFormState>) => void;
    downloadFile?: (id: string) => Promise<void>;
};

const DashboardContext = createContext({});
const SpeakerInfoFormContext = createContext<SpeakerInfoFormContextType>({
    speakerInfoFormState: {
        spinner: {
            show: false,
            text: ""
        },
        brand: "",
        steps: [],
        activeStep: {},
        isRequestSubmitted: false,
        isRequestSaved: false,
        showMessageDialog: false
    },
    setSpeakerInfoFormState: (value:React.SetStateAction<ISpeakerInfoFormState>) => {}
});
const AppContext = createContext({});

export { DashboardContext, AppContext, SpeakerInfoFormContext };

