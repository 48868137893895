const enStrings =  {
    Label_Send_Invitation: "Send Invitation",
    Label_SentToDoctor: "Sent to Doctor",
    Label_ToBeApprove: "To be Approve",
    Label_Active: "Active",
    Label_InActive: "In-Active",
    Title_Confirmation:"Confirmation",
    Confirmation_Message_Delete:"Are you sure you want to delete this Speaker Info?",
    Label_Yes: "Yes",
    Label_No: "No",
    SendInvitationForm_Header_Text:"Send Invitation",
    SpeakerInfoForm_Header_Text:"Speaker Info",
    Label_PersonalInformation: "Personal Information",
    Label_ContactInformation: "Contact Information",
    Label_LevelSpeakingBIOTopics: "Level of Speaking, Speaker BIO & Leture Topics",
    Label_QuestionsAndRequiredDocuments: "Questions & Required Documents",
    Label_FirstName:"First Name",
    Label_LastName:"Last Name",
    Label_Email:"E-mail Address",
    Label_Brand:"Brand",
    Label_SendInvitation:"Send",
    Label_ReSendInvitation:"Re-Send",
    Label_Cancel:"Cancel",
    Label_Gender:"Gender",
    Label_MembershipType:"Membership Type",
    Label_CustomerAccount:"Customer Account #",
    Label_Profession:"Profession",
    Label_LabName:"Practice/Lab Name",
    Label_TerritoryManager:"Who is your Territory Manager?",
    Label_Country:"Country",
    Label_AddressLine1:"Address Line1",
    Label_AddressLine2:"Address Line2",
    Label_City:"City",
    Label_PostalCode:"ZIP/Postal Code",
    Label_USState:"State/Province",
    Label_WorkPhone:"Work Phone",
    Label_CellPhone:"Cell Phone",
    Label_PrimaryStateLicense:"Primary State of Licensure for Clinicians",
    Label_StateLicense:"State License#",
    Label_LicensedInMAOrVT:"Are you licensed in MA or VT?",
    Label_MAorVTLicenseNumber:"MA or VT License Number",
    Label_ClickHere:"Click here",
    Label_NationalProviderId:"National Provider ID # (NPI#)",
    Label_LevelAndForumOfSpeaking:"Level & Forum of Speaking",
    Label_FoundationImplantDentistry:"Foundation in Implant Dentistry",
    Label_InterestedInITIStudyClubs:"Are you interested in speaking for ITI Study Clubs",
    Label_SpeakerBIO:"Speaker BIO (Please provide in 3rd person format)",
    Label_TreatmentPlanning:"Treatment Planning",
    Label_SurgicalProcsImplantDentistry:"Surgical Procedures in Implant Dentistry",
    Label_ProstheticProcsImplantDentistry:"Prosthetic Procedures in Implant Dentistry",
    Label_LaboratoryProcedures:"Laboratory Procedures",
    Label_DigitalDentistry:"Digital Dentistry",
    Label_Regenerative:"Regenerative",
    Label_PracticeManagement:"Practice Management",
    Label_Hygiene:"Hygiene",
    Label_ProductPortfolio:"Product Portfolio",
    Label_SurgicalProductPortfolio:"Surgical Product Portfolio",
    Label_RestorativeProductPortfolio:"Restorative Product Portfolio",
    Label_BiomaterialsProductPortfolio:"Biomaterials Product Portfolio",
    Label_DigitalWorkflowPortfolio:"Digital Workflow Portfolio",
    Label_SpeakerNetworkAcknowledgment:"Speaker Network Acknowledgment",
    Label_ConductsWebinarPresentations:"Do you conduct webinar presentations?",
    Label_PermissionsToRecordWebinar:"Do you give permission to record a webinar of your presentation for use in promoting you as a speaker?",
    Label_TCAgreed:"I have read and agree to the terms and conditions of the Image Use Agreement",
    Label_PresentsVirtualLectures:"Do you present virtual lectures (WebEx,Zoom,etc)?",
    Label_Documents:"Documents",
    Label_Instructions:"INSTRUCTIONS:",
    Label_ApplicationName:"NAM Event Speaker",
    Label_Straumann: "Straumann™",
    Label_CopyRights: `© ${new Date().getFullYear()} Institut Straumann AG`,
    Label_Loading:"Loading...",
    Label_Save:"Save",
    Label_Ok:"Ok",
    Label_PreviousStep:"Previous Step",
    Label_Next:"Next",
    Label_Submit:"Submit",
    Label_FileUpload : "Drop file here or click to upload",
    Label_FileUpload_Photo : "Please upload here your speaker Photo",
    Label_FileUpload_CV : "Please upload here your speaker CV",
    Label_FileUpload_SamplePresentation : "Please upload here your speaker Sample Presentation",
    Label_FileUpload_W9 : "Please upload here your speaker completed W9",
    Label_FileUpload_VendorForm : "Please upload vendor form",
    Label_IsVendorFormSubmitted : "I’ve already submitted vendor form",
    Label_NeedMoreInfo :"Need more information",
    Label_FeedbackForm : "Feedback Form",
    Label_UnableToSignYouIn : "Unable to sign you in",
    Label_PleaseEnterEmail : 'Please enter email!',
    Label_SignInWithYourEmailAddress : "Sign in with your email address",
    Labl_EmailAddress : "Email Address",
    Label_DecodingCookie:"Decoding cookie...",
    Label_AuthTokenCouldnotBeProcessed : "AuthToken couldn't be processed",
    Label_SignInWithYourSocialAccount : "Sign in with your social account",
    Instructions_Straumann:"Fill out the information below (required questions appear in red). A CV is required in order for membership to be approved.",
    Instructions_Neodent:"Fill out the information below (required questions appear in red),then click Next to proceed. A CV is required in order for membership to be approved.",
    Instructions_ClearCorrect:"Fill out the information below (required questions appear in red),then click Next to proceed. A CV is required in order for membership to be approved.",
    ErrorMsg_Required:"Required",
    ErrorMsg_ValidEmail:"Please enter valid email",
    ErrorMsg_ProfileAlreadyExist:"{{doctorname}} with {{email}} already has profile in the system.",
    ErrorMsg_SendInvitation:"something went wrong please try again or refresh the page!",
    Message_SpeakereInfoAlreadySubmitted:"Speaker information already submitted!",
    Message_DuplicateFiles:"File with same name {{filename}} already uploaded",
    Message_SpeakereInfoSaved:"Speaker information saved!",
    Message_SpeakereInfoSubmitted:"Speaker information submitted!",
    Message_SpeakereInfoApproved : "Speaker profile approved!",
    Message_SpeakereInfoRejected : "Speaker profile rejected!",
    Confirmation_Message_DeleteFile: "Are you sure you want to delete this file?",
    PropertyPaneDescription: "Description",
    BasicGroupName: "Group Name",
    DescriptionFieldLabel: "Description Field",
    AppLocalEnvironmentSharePoint: "The app is running on your local environment as SharePoint web part",
    AppLocalEnvironmentTeams: "The app is running on your local environment as Microsoft Teams app",
    AppLocalEnvironmentOffice: "The app is running on your local environment in office.com",
    AppLocalEnvironmentOutlook: "The app is running on your local environment in Outlook",
    AppSharePointEnvironment: "The app is running on SharePoint page",
    AppTeamsTabEnvironment: "The app is running in Microsoft Teams",
    AppOfficeEnvironment: "The app is running in office.com",
    AppOutlookEnvironment: "The app is running in Outlook",
    UnknownEnvironment: "The app is running in an unknown environment"
}

export default enStrings;