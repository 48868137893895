import * as React from 'react';
import { useMsal } from "@azure/msal-react";
import { AuthenticationProvider } from './AuthenticationProvider.';
import { APIHelper } from '../../helpers/APIHelper';
import EmailAddressLoginComponentsrtyles from "./EmailAddressLoginComponent.scss";
import styles from '../../../client/scss/EventSpeakerForm.module.scss';
import { PrimaryButton, TextField, Stack, Label } from '@fluentui/react';
import { useState, useContext } from 'react';
import { AppContext } from '../../components/context';

export interface EmailAddressLoginComponentProps {
    onSuccessfulLogin: (provider: AuthenticationProvider, token: string, providerData?: any) => void;
    onError: (message: string) => void;
    showSpinner: (value: boolean) => void;
    invCode: string;
    reCaptchaToken: string;
}

const EmailAddressLoginComponent = (props: EmailAddressLoginComponentProps): React.JSX.Element => {
    const { instance, accounts, inProgress } = useMsal();
    const [email, setEmail] = useState<string>('');
    const [errorMSG, setErrorMSG] = useState<string>('');
    const appContext: any = useContext(AppContext);
    const onLogin = async (): Promise<void> => {
        if (email) {
            props.showSpinner(true);
            const appJWT = await APIHelper.translateToken("", AuthenticationProvider.Email, props.invCode, props.reCaptchaToken, email);
            if (appJWT?.newToken) {
                props.onSuccessfulLogin(AuthenticationProvider.Microsoft, appJWT.newToken, instance as any);
            } else {
                props.showSpinner(false);
                props.onError(appJWT?.error ?? appContext.state.applicationLables.Label_UnableToSignYouIn);
            }
            props.showSpinner(false);
        }
        else {
            setErrorMSG(appContext.state.applicationLables.Label_PleaseEnterEmail);
        }
    };

    const inputChangeHandler = (event: any): void => {
        setEmail(event.target.value);
    }

    return (
        <>
            <div className={EmailAddressLoginComponentsrtyles.row}>
                <div className={EmailAddressLoginComponentsrtyles.column}>
                    <Stack className={EmailAddressLoginComponentsrtyles.emailField}>
                        <TextField styles={{ fieldGroup: { borderRadius: '3px', minHeight: '40px' }, wrapper: { "&>label": { fontSize: '16px' } } }}
                            placeholder={appContext.state.applicationLables.Labl_EmailAddress}
                            id={"email"}
                            onChange={inputChangeHandler}
                            className={styles.speakerFormfield}
                            value={email}
                            errorMessage={errorMSG}
                            label={appContext.state.applicationLables.Label_SignInWithYourEmailAddress}
                        />
                    </Stack>
                </div>
            </div>
            <div className={EmailAddressLoginComponentsrtyles.row}>
                <div className={EmailAddressLoginComponentsrtyles.column}>
                    <Stack className={EmailAddressLoginComponentsrtyles.loginBtn}>
                        <PrimaryButton styles={{ root: { height: '40px', borderRadius: '3px' } }} onClick={onLogin}>{`Sign in`}</PrimaryButton>
                    </Stack>
                </div>
            </div>

        </>
    );
};

export default EmailAddressLoginComponent;