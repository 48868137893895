import React from "react";
import styles from "./Layout.module.scss";
import logo from "../../images/str-group-white.svg";
import { css, Stack } from "@fluentui/react";
import { AppContext } from '../../components/context';
import { useContext } from 'react';

export const Layout: React.FunctionComponent<any> = (props: any) => {
  const appContext:any = useContext(AppContext);
  return (
    <>
    {appContext.state.applicationLables && (
      <>
      <header className={styles.header}>
        <div className={styles.appNameContainer}>
          <span>{appContext.state.applicationLables.Label_ApplicationName}</span>
          <img className={styles.companyLogo} src={logo} alt={appContext.state.applicationLables.Label_Straumann}></img>
        </div>        
      </header>
      <main className={css("main", styles.mainContent)} data-is-scrollable="true">    
        {props.children}
      </main>
      <footer className={styles.footer}>
        <Stack horizontal horizontalAlign="start" verticalAlign="center">
          {appContext.state.applicationLables.Label_CopyRights}
        </Stack>
      </footer>
      </>
    )}
    </>
  );
};
