import * as React from 'react';
import { CredentialResponse, GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { AuthenticationProvider } from './AuthenticationProvider.';
import { APIHelper } from '../../helpers/APIHelper';
import { AppContext } from '../../components/context';
import { useContext } from 'react';

import GoogleLoginComponentStyles from "./GoogleLoginComponent.scss";

export interface GoogleLoginComponentProps {
    onSuccessfulLogin: (provider: AuthenticationProvider, token: string, providerData?: any) => void;
    onError: (message: string) => void;
    showSpinner: (value: boolean) => void;
    invCode: string;
    reCaptchaToken: string;
}

//const LOGOUT_URL: string = "https://mail.google.com/mail/u/0/?logout&hl=en";

const GoogleLoginComponent = (props: GoogleLoginComponentProps): React.JSX.Element => {
    const appContext: any = useContext(AppContext);
    return (
        <div className={GoogleLoginComponentStyles['google-login-button']}>
            <GoogleLogin
                locale='en-US'
                width={window.innerWidth <= 400 ? (window.innerWidth - 20) : 400}
                logo_alignment='center'
                size='large'
                //ux_mode={"redirect"}
                // click_listener={()=>{props.showSpinner(true);}}
                onSuccess={async (credentialResponse: CredentialResponse) => {
                    console.log("Google token: " + credentialResponse.credential);
                    props.showSpinner(true);
                    const appJWT = await APIHelper.translateToken(credentialResponse.credential as string, AuthenticationProvider.Google, props.invCode, props.reCaptchaToken);
                    if (appJWT?.newToken) {
                        props.onSuccessfulLogin(AuthenticationProvider.Google, appJWT.newToken);
                    } else {
                        props.showSpinner(false);
                        props.onError(appJWT?.error ?? appContext.state.applicationLables.Label_UnableToSignYouIn);
                    }
                    props.showSpinner(false);
                }}
                onError={() => {
                    console.log('Login Failed');
                    props.showSpinner(false);
                }}


            />
        </div>

    );
};

export default GoogleLoginComponent;