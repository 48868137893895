// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MicrosoftLoginComponent__ms-login-button__CnYfD{margin-top:20px;min-width:225px;display:flex;align-items:center;height:40px;font-size:14.5px;font-weight:600;cursor:pointer;border-radius:4px;justify-content:center;color:#5e5e5e;background-color:#fff;border:1px solid #e8e2e2}.MicrosoftLoginComponent__ms-login-button__CnYfD:hover{background-color:rgba(78,144,211,.0509803922)}.MicrosoftLoginComponent__ms-login-button__CnYfD.MicrosoftLoginComponent__dark__G4edj{color:#fff;background-color:#2f2f2f;border:1px solid #2f2f2f}.MicrosoftLoginComponent__ms-login-button__CnYfD img{margin-left:12px;margin-right:12px}`, "",{"version":3,"sources":["webpack://./src/client/components/Login/MicrosoftLoginComponent.scss"],"names":[],"mappings":"AACA,iDACI,eAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,WAAA,CAEA,gBAAA,CACA,eAAA,CACA,cAAA,CACA,iBAAA,CACJ,sBAAA,CACI,aAAA,CACA,qBAAA,CACA,wBAAA,CACA,uDACI,6CAAA,CAGJ,sFACI,UAAA,CACA,wBAAA,CACA,wBAAA,CAGJ,qDACI,gBAAA,CACA,iBAAA","sourcesContent":["\r\n.ms-login-button{\r\n    margin-top: 20px;\r\n    min-width: 225px;\r\n    display: flex;\r\n    align-items: center;\r\n    height: 40px;\r\n    //font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n    font-size: 14.5px;\r\n    font-weight: 600;\r\n    cursor: pointer;\r\n    border-radius: 4px;\r\njustify-content: center;\r\n    color: #5e5e5e;\r\n    background-color: white;\r\n    border: 1px solid #e8e2e2;\r\n    &:hover{\r\n        background-color: #4e90d30d;\r\n    }\r\n\r\n    &.dark{\r\n        color: white;\r\n        background-color: #2f2f2f;\r\n        border: 1px solid #2f2f2f;\r\n    }\r\n    \r\n    img{\r\n        margin-left: 12px;\r\n        margin-right: 12px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ms-login-button": `MicrosoftLoginComponent__ms-login-button__CnYfD`,
	"dark": `MicrosoftLoginComponent__dark__G4edj`
};
export default ___CSS_LOADER_EXPORT___;
