import * as React from 'react';
import { IQuestionsAndRequiredDocumentsState } from './IQuestionsAndRequiredDocumentsState';
import { useState, useContext, useEffect } from 'react';
import { SpeakerInfoFormContext } from '../../../components/context';
import { Stack, ChoiceGroup, Checkbox } from '@fluentui/react';
import styles from '../../../scss/EventSpeakerForm.module.scss';
import { QuestionsAndRequiredDocumentsService } from './QuestionsAndRequiredDocumentsService';
import { IDocuments, IDictionary } from '../../../interfaces';
import Instructions from '../FormText/Instructions';
import { CommonService } from '../../../services';
import FileUpload from '../../FileUpload/FileUpload';
import { forwardRef, useImperativeHandle } from 'react';
import { DocumentCategories, Files, MembershipType } from '../../../constant';

export interface IQuestionsAndRequiredDocumentsProps {
}

export type QuestionsAndRequiredDocumentsRef = {
    getFormData: () => IQuestionsAndRequiredDocumentsState;
    updateValidationErrors: (errorMessagesForFormFields: IDictionary<string>) => void;
};

const QuestionsAndRequiredDocuments = forwardRef<QuestionsAndRequiredDocumentsRef, IQuestionsAndRequiredDocumentsProps>((props, ref) => {
    const commonService = new CommonService();
    const { speakerInfoFormState, downloadFile } = useContext(SpeakerInfoFormContext);

    const {
        questionsAndRequiredDocumentsState: questionsAndRequiredDocumentsStateParent, speakerInfoItem, applicationText, contactInformationState
    } = speakerInfoFormState;

    const [questionsAndRequiredDocumentsState, setQuestionsAndRequiredDocumentsState] = useState<IQuestionsAndRequiredDocumentsState>({
        speakerNetworkAcknowledgment: questionsAndRequiredDocumentsStateParent?.speakerNetworkAcknowledgment ?? speakerInfoItem?.SpeakerNetworkAcknowledgment ?? "",
        conductsWebinarPresentations: questionsAndRequiredDocumentsStateParent?.conductsWebinarPresentations ?? speakerInfoItem?.ConductsWebinarPresentations ?? "",
        permissionsToRecordWebinar: questionsAndRequiredDocumentsStateParent?.permissionsToRecordWebinar ?? speakerInfoItem?.PermissionsToRecordWebinar ?? "",
        tcAgreed: questionsAndRequiredDocumentsStateParent?.tcAgreed ?? speakerInfoFormState.speakerInfoItem?.TCAgreed ?? false,
        documents: questionsAndRequiredDocumentsStateParent?.documents ?? [],
        isVendorFormSubmitted: false
    });

    const questionsAndRequiredDocumentsService = new QuestionsAndRequiredDocumentsService(setQuestionsAndRequiredDocumentsState);

    useEffect(() => {
        questionsAndRequiredDocumentsService.InitQuestionsAndRequiredDocuments();
    }, []);

    useEffect(() => {
        if (speakerInfoFormState.speakerInfoItem?.SpeakerDocuments?.length) {
            questionsAndRequiredDocumentsService.showUploadedFiles(speakerInfoFormState.speakerInfoItem?.SpeakerDocuments);
        }
    }, [speakerInfoFormState.speakerInfoItem]);

    const choiceChangeHandler = (ev: any, option: any): void => {
        setQuestionsAndRequiredDocumentsState((prevState: IQuestionsAndRequiredDocumentsState) => ({
            ...prevState,
            [ev.target.name]: option.key
        }));
    }

    const handleTCAgreedCheckBoxChange = (ev: any, checked?: boolean, fieldState?: any): void => {
        setQuestionsAndRequiredDocumentsState((prevState: IQuestionsAndRequiredDocumentsState) => ({
            ...prevState,
            [fieldState]: checked
        }));
    }

    // Pass the ref to the useImperativeHandle hook
    useImperativeHandle(ref, () => ({
        getFormData: () => {
            return questionsAndRequiredDocumentsState;
        },
        updateValidationErrors: (errorMessagesForFormFields: IDictionary<string>) => {
            setQuestionsAndRequiredDocumentsState((prevState: IQuestionsAndRequiredDocumentsState) => ({
                ...prevState,
                errorMessagesForFormFields: errorMessagesForFormFields
            }));
        }
    }));

    const addDocument = React.useCallback((files: IDocuments[]): void => {
        let documents = questionsAndRequiredDocumentsState?.documents?.slice() ?? [];
        documents = documents.concat(files);
        setQuestionsAndRequiredDocumentsState((prevState: IQuestionsAndRequiredDocumentsState) => ({
            ...prevState,
            documents: documents
        }));
    }, [questionsAndRequiredDocumentsState?.documents, setQuestionsAndRequiredDocumentsState])

    const removeDocument = React.useCallback((file: IDocuments): void => {
        let documents = questionsAndRequiredDocumentsState?.documents?.slice() ?? [];
        documents = documents.filter(x => file.name && file.name !== x.name);

        setQuestionsAndRequiredDocumentsState((prevState: IQuestionsAndRequiredDocumentsState) => ({
            ...prevState,
            documents: documents
        }));
    }, [questionsAndRequiredDocumentsState?.documents, setQuestionsAndRequiredDocumentsState])

    return (<>
        <div className={styles.topInstrunction}>
            <Instructions instructions={applicationText?.QuestionsAndRequiredDocumentsInstructions ?? ""} />
            <div className={styles.supplierFormLink}>
                {
                    contactInformationState?.country?.toLowerCase() == "united states of america" ?
                        <a href={`${Files.USSupplierInformationFormPath}`} target={`_blank`} download>{`${Files.USSupplierInformationFormTitle}`}</a>
                        :
                        <a href={`${Files.ForeignSupplierInformationFormPath}`} target={`_blank`} download>{`${Files.ForeignSupplierInformationFormTitle}`}</a>
                }
            </div>
        </div>

        <div className={styles.flexContainer}>
            <div className={speakerInfoFormState.disableForm ? `${styles.disableForm} ${styles.column}` : `${styles.column}`}>
                <Stack className={styles.stack}>
                    <ChoiceGroup
                        label={speakerInfoFormState.applicationLables.Label_SpeakerNetworkAcknowledgment}
                        required
                        options={questionsAndRequiredDocumentsState.speakerNetworkAcknowledgmentOptions}
                        className={styles.speakerFormfield}
                        selectedKey={questionsAndRequiredDocumentsState.speakerNetworkAcknowledgment}
                        name={"speakerNetworkAcknowledgment"}
                        onChange={choiceChangeHandler}
                        styles={{ flexContainer: { display: "flex" } }}
                    />
                    <span>{commonService.getPlainTextFromHTML([applicationText?.SpeakerNetworkAcknowledgment ?? ``])}</span>
                    <span className={styles.errorMessage}>{questionsAndRequiredDocumentsState.errorMessagesForFormFields?.speakerNetworkAcknowledgment}</span>
                </Stack>
            </div>
            <div className={speakerInfoFormState.disableForm ? `${styles.disableForm} ${styles.column}` : `${styles.column}`}>
                <Stack className={styles.stack}>
                    <ChoiceGroup
                        label={speakerInfoFormState.applicationLables.Label_PermissionsToRecordWebinar}
                        required
                        options={questionsAndRequiredDocumentsState.permissionsToRecordWebinarOptions}
                        className={styles.speakerFormfield}
                        selectedKey={questionsAndRequiredDocumentsState.permissionsToRecordWebinar}
                        name={"permissionsToRecordWebinar"}
                        onChange={choiceChangeHandler}
                        styles={{ flexContainer: { display: "flex" } }}
                    />
                    <span className={styles.errorMessage}>{questionsAndRequiredDocumentsState.errorMessagesForFormFields?.permissionsToRecordWebinar}</span>
                </Stack>
            </div>
            <div className={speakerInfoFormState.disableForm ? `${styles.disableForm} ${styles.column}` : `${styles.column}`}>
                <Stack className={styles.stack}>
                    <ChoiceGroup
                        label={speakerInfoFormState.applicationLables.Label_ConductsWebinarPresentations}
                        required
                        options={questionsAndRequiredDocumentsState.conductsWebinarPresentationsOptions}
                        className={styles.speakerFormfield}
                        selectedKey={questionsAndRequiredDocumentsState.conductsWebinarPresentations}
                        name={"conductsWebinarPresentations"}
                        onChange={choiceChangeHandler}
                        styles={{ flexContainer: { display: "flex" } }}
                    />
                    <span className={styles.errorMessage}>{questionsAndRequiredDocumentsState.errorMessagesForFormFields?.conductsWebinarPresentations}</span>
                </Stack>
            </div>
            <div className={styles.column}>
            </div>
            <div className={styles.column}>
                <FileUpload
                    elementId={`fileUploadPhoto`}
                    category={DocumentCategories.Headshot}
                    text={speakerInfoFormState.applicationLables.Label_FileUpload_Photo}
                    isRequired={true}
                    errorMessage={questionsAndRequiredDocumentsState?.errorMessagesForFormFields?.photo ?? ''}
                    addFiles={addDocument}
                    removeFile={removeDocument}
                    downloadFile={downloadFile}
                    allSpeakerDocuments={questionsAndRequiredDocumentsState.documents}
                />
            </div>
            <div className={styles.column}>
                <FileUpload
                    elementId={`fileUploadCV`}
                    category={DocumentCategories.CV}
                    text={speakerInfoFormState.applicationLables.Label_FileUpload_CV}
                    isRequired={true}
                    errorMessage={questionsAndRequiredDocumentsState.errorMessagesForFormFields?.cv ?? ''}
                    addFiles={addDocument}
                    removeFile={removeDocument}
                    downloadFile={downloadFile}
                    allSpeakerDocuments={questionsAndRequiredDocumentsState.documents}
                />
            </div>
            <div className={styles.column}>
                <FileUpload
                    elementId={`fileUploadW9`}
                    category={DocumentCategories.W9TaxFile}
                    text={speakerInfoFormState.applicationLables.Label_FileUpload_W9}
                    isRequired={speakerInfoFormState.personalInformationState?.membershipType === MembershipType.USSpeaker}
                    errorMessage={questionsAndRequiredDocumentsState.errorMessagesForFormFields?.w9 ?? ''}
                    addFiles={addDocument}
                    removeFile={removeDocument}
                    downloadFile={downloadFile}
                    allSpeakerDocuments={questionsAndRequiredDocumentsState.documents}
                />
            </div>
            <div className={styles.column}>
                <FileUpload
                    elementId={`fileUploadSamplePresentation`}
                    category={DocumentCategories.SamplePresentationOthers}
                    text={speakerInfoFormState.applicationLables.Label_FileUpload_SamplePresentation}
                    isRequired={false}
                    errorMessage={questionsAndRequiredDocumentsState.errorMessagesForFormFields?.presentation ?? ''}
                    addFiles={addDocument}
                    removeFile={removeDocument}
                    downloadFile={downloadFile}
                    allSpeakerDocuments={questionsAndRequiredDocumentsState.documents}
                />
            </div>
            <div className={styles.column}>
                <Checkbox
                    styles={{ label: { fontWeight: 600 } }}
                    className={`${styles.speakerFormfield} ${styles.checkBoxVendorForm}`}
                    label={speakerInfoFormState.applicationLables.Label_IsVendorFormSubmitted}
                    onChange={(ev, checked) => handleTCAgreedCheckBoxChange(ev, checked, "isVendorFormSubmitted")}
                    checked={questionsAndRequiredDocumentsState.isVendorFormSubmitted}
                    required
                />
            </div>
            {!questionsAndRequiredDocumentsState.isVendorFormSubmitted &&
                <div className={styles.column}>
                    <FileUpload
                        elementId={`vendorForm`}
                        category={DocumentCategories.VendorForm}
                        text={speakerInfoFormState.applicationLables.Label_FileUpload_VendorForm}
                        isRequired={true}
                        errorMessage={questionsAndRequiredDocumentsState.errorMessagesForFormFields?.vendorForm ?? ''}
                        addFiles={addDocument}
                        removeFile={removeDocument}
                        downloadFile={downloadFile}
                        allSpeakerDocuments={questionsAndRequiredDocumentsState.documents}
                    />
                </div>
            }

        </div>
        <div className={styles.acknowledgment}>
            <div className={styles.topInstrunction}>
                <Instructions instructions={applicationText?.Agreement ?? ''} />
            </div>
        </div>
        <div className={`${styles.row} ${styles.acknowledgment}`}>
            <div className={speakerInfoFormState.disableForm ? `${styles.disableForm} ${styles.column}` : `${styles.column}`}>
                <Stack className={styles.stack}>
                    <Checkbox
                        styles={{ label: { fontWeight: 600 } }}
                        className={styles.field}
                        label={speakerInfoFormState.applicationLables.Label_TCAgreed}
                        onChange={(ev, checked) => handleTCAgreedCheckBoxChange(ev, checked, "tcAgreed")}
                        checked={questionsAndRequiredDocumentsState.tcAgreed}
                        required
                    />
                    <span className={styles.errorMessage}>{questionsAndRequiredDocumentsState.errorMessagesForFormFields?.tcAgreed}</span>
                </Stack>
            </div>
        </div>
    </>);
});

export default QuestionsAndRequiredDocuments;