export enum ApplicationSettingKeys{
    SentToDoctorViewColumns="SentToDoctorViewColumns",
    DashboardColumns="DashboardColumns",
    DisableSpeakerInfoFormForInternalTeam="DisableSpeakerInfoFormForInternalTeam",
    ProfileRenewalInDays="ProfileRenewalInDays",
    SendEmailAPIUserId="SendEmailAPIUserId",
    Base64StraumannGroup="Base64StraumannGroup",
    SpeakerFormUrl="SpeakerFormUrl",
    DashboardUrl="DashboardUrl",
    OneMonthPriorReminderDays="OneMonthPriorReminderDays",
    OneWeekPriorReminderDays="OneWeekPriorReminderDays",
    UnResponsiveDays="UnResponsiveDays",
    StraumannBrandColor="StraumannBrandColor",
    NeodentBrandColor="NeodentBrandColor",
    ClearCorrectBrandColor="ClearCorrectBrandColor",
    Base64StraumannLogo="Base64StraumannLogo",
    Base64NeodentLogo="Base64NeodentLogo",
    Base64ClearCorrectLogo="Base64ClearCorrectLogo"
    
}