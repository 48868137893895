// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app__container__K5w2T{position:relative}.app__form__p5ZUm{width:90%;margin:5px auto;display:flex;flex:1;flex-direction:column;justify-content:space-between;background-color:#fff;box-shadow:0 1.6px 3.6px 0 rgba(0,0,0,.1294117647),0 .3px .9px 0 rgba(0,0,0,.1098039216);overflow-x:clip}@media screen and (max-width: 768px){.app__form__p5ZUm{width:100%}}`, "",{"version":3,"sources":["webpack://./src/client/scss/app.scss"],"names":[],"mappings":"AAGA,uBACC,iBAAA,CA0BD,kBACC,SAAA,CACG,eAAA,CACA,YAAA,CACH,MAAA,CACG,qBAAA,CACA,6BAAA,CACH,qBAAA,CACA,wFAAA,CACA,eAAA,CACA,qCAVD,kBAWE,UAAA,CAAA","sourcesContent":["//@import '~bootstrap/scss/bootstrap';\r\n\r\n\r\n.container{\r\n\tposition: relative;\r\n}\r\n\r\n// First override some or all individual color variables\r\n$primary: #25408f;\r\n$secondary: #8f5325;\r\n$success: #3e8d63;\r\n$info: #13101c;\r\n$warning: #945707;\r\n$danger: #d62518;\r\n$light: #f8f9fa;\r\n$dark: #343a40;\r\n\r\n// Then add them to your custom theme-colors map, together with any additional colors you might need\r\n$theme-colors: (\r\n\tprimary: $primary,\r\n\tsecondary: $secondary,\r\n\tsuccess: $success,\r\n\tinfo: $info,\r\n\twarning: $warning,\r\n\tdanger: $danger,\r\n\tlight: $light,\r\n\tdark: $dark,\r\n\t// add any additional color below\r\n);\r\n\r\n.form{\r\n\twidth: 90%;\r\n    margin: 5px auto;\r\n    display: flex;\r\n\tflex: 1;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n\tbackground-color: #fff;\r\n\tbox-shadow: 0 1.6px 3.6px 0 #00000021,0 .3px .9px 0 #0000001c;\r\n\toverflow-x: clip;\r\n\t@media screen and (max-width : 768px) {\r\n\t\twidth: 100%;\r\n\t}\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `app__container__K5w2T`,
	"form": `app__form__p5ZUm`
};
export default ___CSS_LOADER_EXPORT___;
