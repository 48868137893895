export enum SpeakerInfosViewFields{
    ViewFields = `<ViewFields>
    <FieldRef Name="FirstName"/>
    <FieldRef Name="LastName"/>
    <FieldRef Name="Gender"/>
    <FieldRef Name="Email"/>
    <FieldRef Name="MembershipType"/>
    <FieldRef Name="CustomerAccount"/>
    <FieldRef Name="Profession"/>
    <FieldRef Name="LabName"/>
    <FieldRef Name="TerritoryManager"/>
    <FieldRef Name="Country"/>
    <FieldRef Name="AddressLine1"/>
    <FieldRef Name="AddressLine2"/>
    <FieldRef Name="City"/>
    <FieldRef Name="ZIPCode"/>
    <FieldRef Name="WorkPhone"/>
    <FieldRef Name="CellPhone"/>
    <FieldRef Name="PrimaryStateLicensure"/>
    <FieldRef Name="StateLicense"/>
    <FieldRef Name="LicensedMAOrVT"/>
    <FieldRef Name="NationalProviderId"/>
    <FieldRef Name="LevelForumSpeaking"/>
    <FieldRef Name="InterestedInITIStudyClubs"/>
    <FieldRef Name="SpeakerBIO"/>
    <FieldRef Name="TreatmentPlanning"/>
    <FieldRef Name="FoundationImplantDentistry"/>
    <FieldRef Name="SurgicalProcsImplantDentistry"/>
    <FieldRef Name="ProstheticProcsImplantDentistry"/>
    <FieldRef Name="LaboratoryProcedures"/>
    <FieldRef Name="DigitalDentistry"/>
    <FieldRef Name="Regenerative"/>
    <FieldRef Name="PracticeManagement"/>
    <FieldRef Name="Hygiene"/>
    <FieldRef Name="ProductPortfolio"/>
    <FieldRef Name="SpeakerNetworkAcknowledgment"/>
    <FieldRef Name="ConductsWebinarPresentations"/>
    <FieldRef Name="PermissionsToRecordWebinar"/>
    <FieldRef Name="TCAgreed"/>
    <FieldRef Name="State"/>
    <FieldRef Name="Id"/>
    <FieldRef Name="Status"/>
    <FieldRef Name="Created"/>
    <FieldRef Name="Brand"/>
    <FieldRef Name="SentTime"/>
    <FieldRef Name="EducationTeamDelegation"/>
    <FieldRef Name="EducationTeamComment"/>
    <FieldRef Name="IsOneMonthPriorReminderSent"/>
    <FieldRef Name="IsOneWeekPriorReminderSent"/>
    <FieldRef Name="IsOverdueReminderSent"/>
    <FieldRef Name="IsNonResponsiveReminderSent"/>
    <FieldRef Name="IsRecertificationInProgress"/>
    <FieldRef Name="ITIStudyClubInfoAgreement"/>
    <FieldRef Name="MAorVTLicenseNumber"/>
    <FieldRef Name="SurgicalProductPortfolio"/>
    <FieldRef Name="RestorativeProductPortfolio"/>
    <FieldRef Name="BiomaterialsProductPortfolio"/>
    <FieldRef Name="DigitalWorkflowPortfolio"/>
    </ViewFields>`
}