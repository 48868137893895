import * as React from 'react';
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult } from '@azure/msal-common';
import { AuthenticationProvider } from './AuthenticationProvider.';
import { APIHelper } from '../../helpers/APIHelper';
import styles from "./MicrosoftLoginComponent.scss";
import { AssetManager } from '../../assets/AssetManager';

export interface MicrosoftLoginComponentProps {
    onSuccessfulLogin: (provider: AuthenticationProvider, token: string, providerData?: any) => void;
    onError: (message: string) => void;
    showSpinner: (value: boolean) => void;
    invCode: string;
    reCaptchaToken: string;
}

const MicrosoftLoginComponent = (props: MicrosoftLoginComponentProps): React.JSX.Element => {
    const { instance, accounts, inProgress } = useMsal();

    const onLogin = async (): Promise<void> => {
        const result: AuthenticationResult = await instance.loginPopup();
        props.showSpinner(true);
        const appJWT = await APIHelper.translateToken(result.idToken as string, AuthenticationProvider.Microsoft, props.invCode, props.reCaptchaToken);
        if (appJWT?.newToken) {
            props.onSuccessfulLogin(AuthenticationProvider.Microsoft, appJWT.newToken, instance as any);
        } else {
            props.showSpinner(false);
            props.onError(appJWT?.error?? "Unable to sign you in");
        }
        props.showSpinner(false);
    };

    return (
        <div className={styles['ms-login-button']} onClick={onLogin}>
            <img src={AssetManager.msLogo} alt="Microsoft Login Button" />
            Sign in with Microsoft
        </div>
    );
};

export default MicrosoftLoginComponent;