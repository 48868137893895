import React from "react";
import { useTheme } from "@fluentui/react";
import { SpeakerInfoFormContext } from '../context';
import { useEffect, useContext } from 'react';
import styles from "./Message.module.scss";
import { ISpeakerInfoFormState } from "../SpeakerInfoForm/ISpeakerInfoFormState";
export interface IMessageProps {
    message?: string;
}

export const Message: React.FunctionComponent<IMessageProps> = (props: IMessageProps) => {
    const theme = useTheme();
    const speakerInfoFormContext:any = useContext(SpeakerInfoFormContext);

    useEffect(() => {
        speakerInfoFormContext.setSpeakerInfoFormState((prevState: ISpeakerInfoFormState) => ({
            ...prevState,
            spinner: {
                show: false,
                text: "",
            }
        })); 
    },[]);
    
    return (
        <>
        {speakerInfoFormContext.speakerInfoFormState.applicationLables && (
        <div className={styles.message}>
            <h1 style={{ color: theme.semanticColors.messageText }}>{props.message}</h1>
        </div>
        )}
        </>
    );
};