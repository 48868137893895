export enum EmailStages {
	InvitationSent = "Invitation Sent",
    CustomerProfileSubmitted = "Customer Profile Submitted",
    CustomerProfileSentBack = "Customer Profile Sent Back",
    CustomerFeedbackReceived = "Customer Feedback Received",
    CustomerProfileApproved = "Customer Profile Approved",
    CustomerProfileRejected = "Customer Profile Rejected",
    OneMonthPriorReminderEmail = "One Month Prior Reminder",
    OneWeekPriorReminderEmail = "One Week Prior Reminder",
    OverdueReminderEmail = "Overdue Reminder",
    NonResponsiveReminderEmail = "Non Responsive",
    DelegateRequest = "Delegate Request"
}