import * as React from 'react';
import {
    Label, Stack, IconButton, DefaultButton,
    css, MessageBar, MessageBarType
} from "@fluentui/react";
import styles from "./FileUpload.module.scss";
import { useEffect, useState, useContext } from 'react';
import { SpeakerInfoFormContext, SpeakerInfoFormContextType } from '../context';
import { useBoolean } from '@fluentui/react-hooks';
import { IDeletedDocumentInfo, IDocuments } from '../../interfaces';
import { Icon } from '@fluentui/react/lib/Icon';
import { ConfirmBox } from '../ConfirmationBox/ConfirmBox';
import { APIHelper } from '../../helpers/APIHelper';
import SpinnerComponent, { ISpinnerProps } from "../../components/Spinner/Spinner";

export interface IFileUploadProps {
    elementId: string;
    text: string;
    errorMessage: string;
    isRequired: boolean;
    removeFile: (file: IDocuments) => void;
    addFiles: (files: IDocuments[]) => void;
    downloadFile?: (id: string) => Promise<void>;
    className?: string;
    category: string;
    itemId?: string;
    allSpeakerDocuments: IDocuments[]
}

const stackTokens = { childrenGap: 50 };
const FileUpload: React.FC<IFileUploadProps> = (props: IFileUploadProps) => {

    // const [uploadedFileName, setUploadedFileName] = useState<string | undefined>(undefined);
    // const [uploadedFiles, setUploadedFiles] = useState<any | undefined>(undefined);
    const [isHideConfirmBox, { setTrue: hideConfirmBox, setFalse: showConfirmBox }] = useBoolean(true);
    const speakerInfoFormContext: SpeakerInfoFormContextType = useContext(SpeakerInfoFormContext);
    //const [filesToUpload, setFilesToUpload] = useState<IDocuments[]>([]);
    const [spinner, setSpinner] = useState<ISpinnerProps>({ show: false, text: "" })

    const [deletedFile, setDeletedFile] = useState<IDocuments | undefined>(undefined);
    const [duplicateFilesErrorMessage, setDuplicateFilesErrorMessage] = useState<string>('');

    const getIconName = React.useCallback((filename: string) => {
        const fileExtension = filename?.split('.').pop() ?? "";
        switch (fileExtension.toLowerCase()) {
            case "pdf":
                return "PDF";

            case "doc":
            case "docx":
                return "WordDocument";

            case "xls":
            case "xlsx":
            case "csv":
                return "ExcelDocument";

            case "jpg":
            case "jpeg":
            case "png":
            case "bmp":
            case "svg":
            case "tiff":
            case "heic":
                return "Photo2";

            case "zip":
                return "ZipFolder";

            default:
                return "TextDocument";
        }
    }, []);


    const allFiles: IDocuments[] = React.useMemo(() => {
        let files = props.allSpeakerDocuments.filter(x => x.category === props.category);

        files.forEach((file: IDocuments) => {
            file.iconName = getIconName(file.name);
        });

        return files;

    }, [props.allSpeakerDocuments, props.category])

    //Associate click and drag drop event to the file element
    useEffect(() => {
        //Add click event
        const inputElement: any = document.getElementById(props.elementId);
        const dropZoneElement = inputElement.closest(".fileuploaddropzone");
        dropZoneElement.addEventListener("click", (e: any) => {
            inputElement.click();
        });

        //Apply css to drag drop area
        dropZoneElement.addEventListener("dragover", (e: any) => {
            e.preventDefault();
            dropZoneElement.classList.add(`${styles.drop_zone_over}`);
        });

        ["dragleave", "dragend"].forEach((type) => {
            dropZoneElement.addEventListener(type, (e: any) => {
                dropZoneElement.classList.remove(`${styles.drop_zone_over}`);
            });
        });

        //Add drag drop event
        dropZoneElement.addEventListener("drop", (e: any) => {
            e.preventDefault();
            if (e.dataTransfer.files.length) {
                onFileUpload(e.dataTransfer.files);
            }
            dropZoneElement.classList.remove(`${styles.drop_zone_over}`);
        });
    }, []);

    //Delete uploaded file
    const deleteDocument = (file: IDocuments): void => {
        setDeletedFile(file);
        showConfirmBox();
    }

    const onConfirmDeleteDocument = async (): Promise<void> => {
        setSpinner({ show: true, text: speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_Loading });

        if (deletedFile?.docId) {
            await APIHelper.deleteSpeakerDocument(deletedFile.docId);
        }

        if (deletedFile) {
            props.removeFile(deletedFile);

            setSpinner({ show: false, text: "" });
            setDeletedFile(undefined);
            const fileUploadElement: any = document.getElementById(props.elementId);
            fileUploadElement.value = null;
            setDuplicateFilesErrorMessage('');
        }
    };


    const downloadFile = React.useCallback((id: string | undefined) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (id && props.downloadFile)
            props.downloadFile(id);
    }, [])

    const getDuplicateAndNonDuplicateFiles = (files: FileList): { duplicateFiles: File[]; uniqueFiles: File[] } => {
        let duplicateFiles: File[] = [];
        let uniqueFiles: File[] = [];

        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            if (allFiles?.some((uploadedItem: any) => uploadedItem.name === file.name)) {
                duplicateFiles.push(file);
            }
            else {
                uniqueFiles.push(file);
            }
            
        }

        return { duplicateFiles, uniqueFiles };
    }

    const readFileDataAsBase64 = (file: any): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    }

    const onFileUpload = async (files: FileList): Promise<void> => {
        const filesToAdd: IDocuments[] = [];
        let { duplicateFiles, uniqueFiles } = getDuplicateAndNonDuplicateFiles(files);

        for (const uniqueFile of uniqueFiles) {
            const fileBase64String: string = await readFileDataAsBase64(uniqueFile);
            filesToAdd.push({
                base64: fileBase64String,
                category: props.category,
                name: uniqueFile.name,
            });
        }

        if (filesToAdd?.length)
            props.addFiles(filesToAdd);

        if (duplicateFiles.length > 0) {
            let duplicateFileNames: string[] = [];

            duplicateFiles.forEach(item => {
                duplicateFileNames.push(item.name);
            });

            setDuplicateFilesErrorMessage(speakerInfoFormContext.speakerInfoFormState.applicationLables.Message_DuplicateFiles.replace(`{{filename}}`, duplicateFileNames.join(',')));
        }
        else {
            setDuplicateFilesErrorMessage('');
        }

        const fileUploadElement: HTMLInputElement | null = document.getElementById(props.elementId) as HTMLInputElement;
        if (fileUploadElement)
            (fileUploadElement as any).value = null;
    }

    return (
        <div className={props.className ?? styles.container}>
            <SpinnerComponent show={spinner.show} text={spinner.text} />

            <Stack horizontal tokens={stackTokens}>
                <Stack className={styles.stackCSS}>
                    <Label required={props.isRequired}>{props.text}</Label>
                    <div className={`fileuploaddropzone ${styles.drop_zone}`}>
                        <span>{speakerInfoFormContext.speakerInfoFormState.applicationLables.Label_FileUpload}</span>
                        <input type="file" id={props.elementId} className={styles.drop_zone__input} multiple={true} onChange={(obj: any) => {
                            onFileUpload(obj.target.files);
                        }} />
                    </div>
                    {props.errorMessage && <span className={styles.errorMessage}>{props.errorMessage}</span>}
                </Stack>
            </Stack>
            <Stack horizontal tokens={stackTokens}>
                <Stack className={styles.stackCSS}>
                    {allFiles.map((file: IDocuments, idx: number) => {
                        return (<div className={styles.fileDetails} key={file.name ?? file.name}>
                            <button
                                className={css({ [styles.downloadButton]: true, [styles.downloadButtonLink]: Boolean(file.docId) })}
                                onClick={downloadFile(file.docId)}>
                                <Icon iconName={file.iconName} />
                                <span>{file.name}</span>
                            </button>
                            <IconButton
                                className={styles.deleteButton}
                                iconProps={{ iconName: "Delete", className: styles.deleteButton }}
                                onClick={() => deleteDocument(file)}
                            />
                        </div>
                        )
                    })
                    }
                    {duplicateFilesErrorMessage && <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={true}
                        dismissButtonAriaLabel="Close"
                        onDismiss={() => {
                            setDuplicateFilesErrorMessage('');
                        }}
                    >
                        {duplicateFilesErrorMessage}
                    </MessageBar>
                    }
                </Stack>
            </Stack>
            <ConfirmBox
                title={speakerInfoFormContext.speakerInfoFormState.applicationLables.Title_Confirmation}
                subtext={speakerInfoFormContext.speakerInfoFormState.applicationLables.Confirmation_Message_DeleteFile}
                hidden={isHideConfirmBox}
                yesConfirmation={() => {
                    onConfirmDeleteDocument();
                    hideConfirmBox()
                }}
                noConfirmation={() => {
                    hideConfirmBox()
                }}
            />
        </div>
    );
};

export default FileUpload;